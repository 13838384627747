import { PainAlert } from 'domain/entities/pain-alert-model'

export const getPainLocal = (painAlert: PainAlert): string => {
  if (painAlert.frontLocals && painAlert.frontLocals.length > 0) {
    return 'Frontal ' + painAlert.frontLocals[0]
  } else if (painAlert.backLocals && painAlert.backLocals.length > 0) {
    return 'Costas ' + painAlert.backLocals[0]
  } else {
    return '-'
  }
}
