import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
`
export const Container = styled.div`
  margin-top: 50px;
`
export const BgContainer = styled.div`
  background-color: #e7e7e7;
  margin-top: 20px;
  padding: 20px 40px 20px 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  margin-top: 12px;

  @media (max-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Responsible = styled.p`
  flex: 1;
`

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div + div {
    margin-top: 12px;
  }
`

export const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Justification = styled.div`
  margin-top: 20px;
  padding: 20px 40px 20px 40px;
  background-color: #e7e7e7;
  height: 200px;
  width: 100%;
  border-radius: 5px;
`
