import styled from 'styled-components'

export const Wrapper = styled.div``

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.5fr;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 50px 30px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  place-content: center;

  div:first-child {
    grid-column: span 2;
  }
`

export const FiltersButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 0 1rem;

  button {
    width: 50%;
    margin-left: 12px;
  }

  @media (max-width: 1400px) {
    padding: 0;

    button {
      width: 50%;
      margin-left: 0;
    }
  }
`

export const CollaboratorsTable = styled.div``
