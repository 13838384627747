import React from 'react'

import Container from 'presentation/hospital/components/Container'
import PatientsTable from 'presentation/hospital/components/PatientsTable'
import { LoadPatients } from 'domain/usecases/patient/load-patients'

type Props = {
  loadPatients: LoadPatients
}

export default function PatientsLayout({ loadPatients }: Props) {
  return (
    <Container>
      <PatientsTable loadPatients={loadPatients} />
    </Container>
  )
}
