import React, { useState, useEffect } from 'react'

import moment from 'moment-timezone'

import { LoadPainAlerts } from 'domain/usecases/surgery/load-pain-alerts'
import PainAlertsLayout from 'presentation/hospital/layouts/PainAlerts'
import { PainAlert } from 'domain/entities/pain-alert-model'
import { getPainIntensity } from 'presentation/utils/get-pain-intensity'
import { getPainLocal } from 'presentation/utils/get-pain-local'

type Props = {
  loadPainAlert: LoadPainAlerts
}

export default function PainAlerts({ loadPainAlert }: Props) {
  const [painAlerts, setPainAlerts] = useState<PainAlert[]>()

  useEffect(() => {
    async function loadData() {
      const alerts = await loadPainAlert.load([
        'intensity',
        'backLocals',
        'frontLocals',
        'createdAt',
        'description',
        'surgicalOrder {patient{name}, hospital{name}}'
      ])
      setPainAlerts(alerts)
    }
    loadData()
  }, [])

  return (
    <PainAlertsLayout
      painAlerts={
        painAlerts?.map((painAlert) => ({
          patient: painAlert.surgicalOrder?.patient?.name,
          createdAt: moment(painAlert.createdAt).toDate(),
          painIntensity: getPainIntensity(painAlert.intensity)?.toString(),
          painLocal: getPainLocal(painAlert),
          painAlert: painAlert
        })) ?? []
      }
    />
  )
}
