import React from 'react'
import { useFormik } from 'formik'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import * as S from './styles'
import { fields } from './fields'
import Heading from 'presentation/shared/components/Heading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { useServices } from 'presentation/hooks/use-services'
import { Collaborator } from 'domain/entities/collaborator-model'

export type Props = {
  data: Collaborator
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdateHospitalProfileForm({
  data,
  setOpenSuccessModal
}: Props) {
  const [blockEdit, setBlockEdit] = React.useState<boolean>(true)

  const history = useHistory()
  const collaboratorService = useServices().collaborator

  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      landlinePhone: data.landlinePhone,
      phone: data.phone,
      cpf: data.cpf,
      user_id: data.user_id,
      sector: data.sector,
      register: data.register,
      phoneExtension: data.phoneExtension
    } as unknown as HospitalFormValues,
    validationSchema: validationSchema,
    onSubmit: async ({ register, sector, email }) => {
      try {
        await collaboratorService.updateCollaborator({
          register,
          sector,
          email
        })
        setTimeout(() => {
          history.push('/home')
        }, 2000)
        setOpenSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  const changeEnableForm = () => {
    if (blockEdit === false) {
      formik.resetForm()
    }
    setBlockEdit(!blockEdit)
  }

  const disabledFields = (name: string) => {
    return (
      name === 'name' ||
      name === 'nickname' ||
      name === 'lastName' ||
      name === 'phoneExtension'
    )
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        Dados pessoais
      </Heading>
      <S.Wrapper>
        {fields.map(({ name, placeholder, label, mask, type }) => (
          <TextField
            disabled={blockEdit || disabledFields(name)}
            key={name}
            bgColor="mainBg"
            label={label}
            mask={mask || undefined}
            // @ts-ignore: Unreachable code error
            value={formik.values[name]}
            placeholder={placeholder}
            name={name}
            onInputChange={formik.handleChange(name)}
            onBlur={formik.handleBlur(name)}
            type={type || undefined}
            // @ts-ignore: Unreachable code error
            error={formik.touched[name] && formik.errors[name]}
          />
        ))}
      </S.Wrapper>

      <S.Actions>
        <Button
          type="button"
          variant={!blockEdit ? 'outlined' : 'white'}
          style={{ marginRight: '6px', maxWidth: '150px' }}
          onClick={() => changeEnableForm()}
        >
          {blockEdit ? 'Editar' : 'Descartar'}
        </Button>
        <Button
          disabled={blockEdit}
          onClick={() => formik.handleSubmit}
          style={{ maxWidth: '150px' }}
        >
          Salvar
        </Button>
      </S.Actions>
    </form>
  )
}

export type HospitalFormValues = {
  name?: string
  email: string
  landlinePhone?: string | undefined
  phone?: string
  cpf?: string
  user_id?: string
  lastName?: string
  sector: string
  register: string
  phoneExtension?: string | undefined
}

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Este campo precisa ser preenchido'),
  sector: yup.string().required('Este campo precisa ser preenchido'),
  register: yup
    .string()
    .max(10, 'Máximo 10 caracteres')
    .required('Este campo precisa ser preenchido')
})
