import React, { useEffect, useMemo, useState } from 'react'

import { useFormik } from 'formik'
import moment from 'moment-timezone'

import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { Doctor } from 'domain/entities/doctor-model'
import { useServices } from 'presentation/hooks/use-services'
import { Pagination } from 'service/protocols/api/api-client'
import { PaginationModel } from 'domain/entities/pagination-model'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { Tooltip } from 'presentation/shared/components/Tooltip'
import { UserStatusEnum } from 'common/enum/user-status'
import { ResendDoctorInvite } from 'presentation/hospital/components/ResendDoctorInvite'

const DoctorsTable = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>()
  const [pagination, setPagination] = useState<PaginationModel>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 10,
    totalPages: 1
  })
  const [doctors, setDoctors] = useState<Doctor[]>([])
  const service = useServices().doctor
  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => {
      findDoctors(values.name ? values.name : '', {
        pageNumber: 1,
        pageSize: pagination.itemsPerPage ?? 10
      })
    }
  })

  const findAllDoctors = (): any => findDoctors('')

  useEffect(() => {
    ;(async () => await findAllDoctors())()
  }, [])

  const findDoctors = async (name: string, pagination?: Pagination) => {
    setIsLoading(true)
    const doctors = await service.searchDoctors({
      doctorName: name,
      params: [
        'data{',
        'cpf',
        'crm',
        'crmUf',
        'doctor_id',
        'email',
        'phone',
        'status',
        'name',
        'gender',
        'createdAt',
        'hospitals {name}',
        '}',
        'pageInfo {totalItems, itemsPerPage, currentPage, totalItems}'
      ],
      pagination: {
        pageNumber: pagination?.pageNumber ?? 1,
        pageSize: pagination?.pageSize ?? 10
      }
    })
    setPagination(doctors.pageInfo)
    setDoctors(doctors.data)
    setIsLoading(false)
    return doctors.pageInfo
  }

  const renderTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={doctors?.map((doctor) => ({
          cpf: doctor.cpf,
          crm: doctor.crm,
          name: doctor.name,
          email: doctor.email,
          phone: doctor.phone,
          unit:
            doctor.hospitals?.length > 0
              ? doctor.hospitals
                  ?.map(
                    (hospital) =>
                      new Hospital(
                        hospital?.hospital_id || 0,
                        '',
                        hospital?.name || ''
                      ).name
                  )
                  .join(', ')
              : 'Não vinculado',
          status: (
            <Tooltip
              text={
                doctor.status === UserStatusEnum.ACTIVE
                  ? 'Médico cadastrado com sucesso'
                  : 'Cadastro pendente! Clique aqui para reenviar o convite'
              }
            >
              {doctor.status === UserStatusEnum.ACTIVE ? (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="16" r="16" fill="#1BD15D" />
                  <path
                    d="M24.9123 9.56723C25.4749 10.1311 25.4749 11.0452 24.9123 11.6088L15.25 21.2933C14.6873 21.8569 13.7756 21.8569 13.213 21.2933L8.61337 16.6829C8.05075 16.1193 8.05075 15.2053 8.61337 14.6416C9.1757 14.0778 10.0877 14.0778 10.65 14.6416L14.2314 18.2311L22.8754 9.56723C23.438 9.00363 24.35 9.00363 24.9123 9.56723Z"
                    fill="white"
                  />
                </svg>
              ) : doctor.status === UserStatusEnum.PENDING ? (
                <svg
                  onClick={() => setSelectedDoctor(doctor)}
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0C24.8223 0 32 7.17769 32 16C32 24.8223 24.8223 32 16 32C7.17769 32 0 24.8223 0 16C0 7.17769 7.17769 0 16 0ZM14.4615 24.6085H17.5385V21.5315H14.4615V24.6085ZM14.7692 19.6923H17.2308L17.6923 7.38462H14.3077L14.7692 19.6923Z"
                    fill="#FF8F39"
                  />
                </svg>
              ) : (
                <></>
              )}
            </Tooltip>
          ),
          startDate: moment(doctor.createdAt)
            .tz('America/Sao_Paulo')
            .format('DD/MM/YYYY')
        }))}
        count={pagination?.totalItems}
        rowsPerPage={pagination?.itemsPerPage}
        page={pagination?.currentPage}
        onPaginate={async (currentPage, itemsPerPage) => {
          findDoctors(formik.values.name || '', {
            pageNumber: currentPage,
            pageSize: itemsPerPage
          })
          return undefined
        }}
      />
    )
  }, [doctors])

  return (
    <S.Wrapper>
      <Heading
        as="h1"
        size="huge"
        color="primary"
        style={{ marginBottom: '1.5rem' }}
      >
        Médicos
      </Heading>
      <S.SearchWrapper onSubmit={formik.handleSubmit}>
        <TextField
          onResetTableValue={findAllDoctors}
          iconPosition="left"
          icon={<SearchIcon />}
          iconLocale="inside"
          bgColor="mainBg"
          placeholder="Digite o nome do médico"
          onInputChange={formik.handleChange('name')}
        />
        <Button fullWidth type="submit">
          Buscar
        </Button>
      </S.SearchWrapper>
      {renderTable}
      {selectedDoctor && (
        <ResendDoctorInvite
          doctor={selectedDoctor}
          close={() => setSelectedDoctor(undefined)}
        />
      )}
    </S.Wrapper>
  )
})

const columns = [
  {
    name: 'status',
    label: ' ',
    options: {
      sort: false
    }
  },
  {
    name: 'cpf',
    label: 'CPF',
    options: {
      sort: false
    }
  },
  {
    name: 'crm',
    label: 'CRM',
    options: {
      sort: false
    }
  },
  {
    name: 'name',
    label: 'Nome',
    options: {
      sort: false
    }
  },
  {
    name: 'startDate',
    label: 'Data de início',
    options: {
      sort: false
    }
  },
  {
    name: 'unit',
    label: 'Unidades',
    options: {
      sort: false
    }
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      sort: false
    }
  },
  {
    name: 'phone',
    label: 'Telefone',
    options: {
      sort: false
    }
  }
]

export default DoctorsTable
