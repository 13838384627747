import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 14px 36px 36px 36px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding-top: 39px;

  h2 {
    padding-top: 36px;
    padding-bottom: 20px;
  }

  @media (max-width: 1024px) {
    padding: 14px 14px 36px 14px;
  }
`

export const DocumentButton = styled.div`
  height: 108px;
  width: 100%;
  background: #fdfdfd;
  box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 40px;
  display: flex;
  padding-left: 20px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.sizes.large};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.primary};

  p {
    display: block;
    margin: auto 0;
  }
`

export const Buttons = styled.div`
  margin-top: 78px;
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;

  button {
    width: 220px;

    &:last-child {
      margin-left: 20px;
    }
  }
`

export const DownloadButton = styled.div`
  height: 181px;
  width: 320px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.mainBg};
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.normal};

  & + & {
    margin-left: 40px;
  }

  p {
    display: block;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;

    & + p {
      color: ${({ theme }) => theme.colors.lightGray};
    }
  }
`
