import styled from 'styled-components'

export const SurgicalOrderCard = styled.div`
  margin-top: 24px;
  padding: 20px 30px;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  color: ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.border.radius.large};

  & > h4 {
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.normal};
  }

  & > span {
    font-weight: ${({ theme }) => theme.font.black};
    color: #fa7676;
  }
`

export const SurgicalOrderPersons = styled.div`
  display: grid;
  margin-top: 12px;
  grid-template-columns: repeat(3, 1fr);
  font-size: 14px !important;

  h4 {
    color: ${({ theme }) => theme.colors.primary};
  }
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`
