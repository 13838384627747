import styled from 'styled-components'

export const Wrapper = styled.form`
  height: 90vh;
  width: 720px;
  max-width: 80vw;
  padding: 70px;
  display: flex;
  flex-direction: column;
`

export const CheckboxLayout = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;

  div + div {
    margin-top: 32px;
  }

  input {
    width: 24px;
    height: 24px;

    &:before {
      width: 6px;
      height: 13px;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
    }
  }
`

export const Buttons = styled.div`
  margin-top: auto;
  margin-left: auto;

  button {
    width: 200px;

    &:first-child {
      margin-right: 20px;
    }
  }
`
