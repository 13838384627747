import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 2fr;
  height: 100%;
`

export const PainAlertWrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 120px auto;
  grid-row: span 2;
  padding: 20px 24px;
  border-radius: 8px;
  max-height: 100%;

  & > span {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.xxxlarge};
      text-align: right;
      font-weight: 700;
      color: ${theme.colors.lightGray};
    `}
  }

  div:last-child {
    grid-column: span 2;
  }

  div {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    div + div {
      margin-top: 10px;
    }
  }
`
