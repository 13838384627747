import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteCountSurgicalOrdersByStatus
  implements CountSurgicalOrdersByStatus
{
  constructor(private surgicalOrderRepository: SurgicalOrderRepository) {}

  count(
    params: CountSurgicalOrdersByStatus.Params
  ): Promise<CountSurgicalOrdersByStatus.Model> {
    return this.surgicalOrderRepository.countSurgicalOrdersByStatus(params)
  }
}
