import styled from 'styled-components'
import { CardWrapper } from 'presentation/shared/components/Card'

export const Wrapper = styled.form`
  height: 90vh;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  width: 70vh;
  margin-top: 20px;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Justify = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  div {
    flex: 1;
    display: flex;
  }
  textarea {
    height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    display: block;
  }
`

export const Card = styled(CardWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 30px;
  margin: 12px 0;

  p {
    margin-left: 4px;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 51px;
  button {
    flex-shrink: 0;
    width: 40%;
    height: 57px;
  }
`
