import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { RemoteLoadPatients } from 'service/usecases/load-patients/remote-load-patiens'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import Patients from 'presentation/hospital/pages/Patients'

export default function makeHospitalPatients() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)

  const loadPatients = new RemoteLoadPatients(patientRepository)

  return <Patients loadPatients={loadPatients} />
}
