import React, { useEffect, useState } from 'react'

import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import Checkbox from 'presentation/shared/components/Checkbox'
import Modal from 'presentation/hospital/components/Modal'
import Button from 'presentation/shared/components/Button'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from '../../../shared/components/HOCs/WithLoading'
import SupportText from 'presentation/shared/components/SupportText'

export type Props = {
  close: () => void
  surgicalOrderId: number
  loadSurgicalOrder?: LoadSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
} & WithLoadingProps

export const GuidesModal = WithLoading(
  ({
    close,
    surgicalOrderId,
    loadSurgicalOrder,
    loadSurgicalOrderDocument,
    setIsLoading
  }: Props) => {
    const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>()
    const [selectedDocuments, setSelectedDocuments] = useState([])

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)
          setSurgicalOrder(
            await loadSurgicalOrder?.load(surgicalOrderId, [
              'documents {document_id, type, name}',
              'hospitalizationMode'
            ])
          )
        } catch (e) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    function handleSelectDocument(documentId: number) {
      if (selectedDocuments.includes(documentId)) {
        const documents = selectedDocuments.filter((doc) => doc !== documentId)
        setSelectedDocuments(documents)
      } else {
        setSelectedDocuments([...selectedDocuments, documentId])
      }
    }

    async function downloadDocuments() {
      for (const documentId of selectedDocuments) {
        const document = surgicalOrder?.documents.find(
          (doc) => doc.document_id === documentId
        )
        const file = await loadSurgicalOrderDocument?.load(
          surgicalOrderId,
          documentId
        )
        if (file.contentType === 'application/pdf') {
          downloadFileFromBlob(file?.data, file.contentType, document.name)
        } else {
          toast.error(
            'Não foi possível baixar o arquivo, tente novamente ou contate o administrador do sistema'
          )
        }
      }
      return
    }

    return (
      <Modal close={close}>
        <S.Wrapper>
          <Heading size="huge" as="h1">
            Visualizar guias
          </Heading>
          <SupportText style={{ marginBottom: '2rem' }}>
            Selecione abaixo as guias que deseja baixar
          </SupportText>
          <S.CheckboxLayout>
            {surgicalOrder?.documents
              .filter((doc) => doc.type === 'GUIA')
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              })
              .map((doc) => (
                <Checkbox
                  key={doc.document_id}
                  label={doc.name}
                  labelFor={doc.name}
                  id={doc.name}
                  name={doc.name}
                  onChange={() => handleSelectDocument(doc.document_id)}
                  checked={selectedDocuments.includes(doc.document_id)}
                />
              ))}
          </S.CheckboxLayout>
          <S.Buttons>
            <Button variant="outlined" type="button" onClick={close}>
              Cancelar
            </Button>
            <Button
              type="button"
              disabled={!selectedDocuments.length}
              onClick={downloadDocuments}
            >
              Baixar
            </Button>
          </S.Buttons>
        </S.Wrapper>
      </Modal>
    )
  }
)
