import Calendar from 'presentation/shared/components/Calendar'
import styled from 'styled-components'

export const Wrapper = styled.div``

type HeadStatusCardProps = {
  isPendency?: boolean
}

export const HeadStatusCard = styled.div<HeadStatusCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;

  h5 {
    font-weight: ${({ theme }) => theme.font.semiBold};
    text-align: center;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    span {
      font-size: ${({ theme }) => theme.font.sizes.xlarge};
      align-self: flex-end;
      text-align: center;

      color: ${({ theme, isPendency }) =>
        isPendency ? theme.colors.lightOrange : theme.colors.secondary};
    }
  }
`

export const HeadCountersWrapper = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 20px 3%;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
`

export const StatusCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.lightGray};

  h5 {
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.font.semiBold};
  }

  & > div {
    display: flex;
    justify-content: space-between;
    span {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: ${({ theme }) => theme.font.sizes.xlarge};
      align-self: flex-end;
    }
    & > div {
      p {
        color: ${({ theme }) => theme.colors.greyishBlue};
        font-weight: ${({ theme }) => theme.font.semiBold};
        font-size: ${({ theme }) => theme.font.sizes.small};
      }
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3px;
      }
    }
  }
`

export const CountersWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
  gap: 20px 3%;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
`

export const AttendancesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 40px 0;
  margin-bottom: 20px;
  gap: 0 7%;
`

export const AttendancesOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 183px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ScheduledOrders = styled.div<{ color: 'blue' | 'orange' }>`
  color: ${({ theme, color }) =>
    color === 'blue' ? '#5d86da' : theme.colors.lightOrange};
  height: 102px;
  grid-column: 1/3;
  font-weight: 700;
  border-bottom: 2px solid #f3f3f3;
  text-transform: uppercase;
  span {
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    margin-left: 9px;
  }
`

export const ApprovedOrders = styled.div`
  height: 79px;
  border-right: 1px solid #f3f3f3;
  display: flex;
  text-transform: uppercase;
  label {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }
`

export const RejectedOrders = styled.div`
  height: 79px;
  border-left: 1px solid #f3f3f3;
  display: flex;
  text-transform: uppercase;
  label {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }
`

export const OrdersHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;

  @media (max-width: 1440px) {
    h2 {
      font-size: 2.3rem;
    }
  }
`

export const InputContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    max-width: 200px;
  }

  select {
    max-width: 210px;
  }

  div {
    &:nth-child(2) {
      margin-left: 3%;
      margin-right: 3%;
    }
    &:nth-child(3) {
      width: 210px;
    }
  }
`

export const StyledCalendar = styled(Calendar)<{ show: boolean }>`
  position: absolute;
  left: -6px;
  top: 34px;
  width: 300px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const InputCalendar = styled.div`
  position: relative;
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`
