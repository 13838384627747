import { FinishSurgicalPendency } from 'domain/usecases/surgical-pendency/finish-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteFinishSurgicalPendency implements FinishSurgicalPendency {
  constructor(
    private readonly SecretaryRepository: SurgicalPendencyRepository
  ) {}

  answer(
    params: FinishSurgicalPendency.Params
  ): Promise<FinishSurgicalPendency.Model> {
    return this.SecretaryRepository.finishSurgicalPendency(params)
  }
}
