import React, { useState, useEffect } from 'react'

import AddCollaboratorLayout from 'presentation/hospital/layouts/AddCollaborator'
import { AddCollaborator as AddCollaboratorUseCase } from 'domain/usecases/collaborator/add-collaborator'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { Hospital } from 'domain/entities/hospital-model'

type AddCollaboratorProps = {
  addCollaborator: AddCollaboratorUseCase
  loadHospitals: LoadHospitals
}

export default function AddCollaborator({
  addCollaborator,
  loadHospitals
}: AddCollaboratorProps) {
  const [hospitals, setHospitals] = useState([] as Hospital[])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const data = await loadHospitals.load(['hospital_id', 'name', 'nickname'])
      setHospitals(data)
    }
    loadData()
    setIsLoading(false)
  }, [])

  if (isLoading) return <></>

  return (
    <AddCollaboratorLayout
      hospitals={hospitals}
      addCollaborator={addCollaborator}
    />
  )
}
