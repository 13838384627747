import React, { useEffect, useState } from 'react'

import { useServices } from 'presentation/hooks/use-services'
import SolicitationLayout from 'presentation/hospital/layouts/Solicitation'
import { useLocation } from 'react-router-dom'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { toast } from 'react-toastify'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { PatientDocument } from 'common/enum/patient-document'

export default WithLoading(function Solicitation({
  setIsLoading
}: WithLoadingProps) {
  const location = useLocation<{ id: string }>()
  const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>()
  const [healthCardDocument, setHealthCardDocument] = useState<ListFile>()
  const service = useServices().surgicalOrder

  const loadHealthCard = async (documents: SurgicalOrderModel['documents']) => {
    if (!documents) return
    try {
      setIsLoading(true)
      const document = documents?.find(
        (document) => document.type === PatientDocument.HEALTH_CARD
      )
      if (!document?.group_id || !document?.document_id) return
      const response = await service.loadSurgicalOrderDocument({
        surgical_order_id: document?.group_id,
        document_id: document?.document_id
      })
      const file = new File([response.data], document.type, {
        type: response.contentType
      })
      setHealthCardDocument({
        file,
        thumbnail: await getThumbnail(file)
      })
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar carregar a carteira do convênio')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      const gettedSurgicalOrder = await service.loadSurgicalOrder(
        Number(location.state.id),
        [
          'surgical_order_id',
          'patient{gender',
          'name',
          'cpf',
          'phone',
          'landlinePhone',
          'email',
          'birthday',
          'patient_id',
          'company',
          'maritalStatus',
          'healthInsurance {healthInsuranceCode, healthInsuranceName, health_insurance_id, healthCard',
          'healthPlanCode, healthPlanName, validThru, company, ansRegister}}',
          'documents{type, name, document_id, isMerged, group_id}'
        ]
      )
      setSurgicalOrder(gettedSurgicalOrder)
      await loadHealthCard(gettedSurgicalOrder.documents)
    })()
  }, [])

  return (
    <SolicitationLayout
      surgicalOrder={surgicalOrder}
      healthCardDocument={healthCardDocument}
    />
  )
})
