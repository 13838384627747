import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import HospitalPasswordRecovery from 'presentation/hospital/pages/PasswordRecovery'
import { RemoteGetPasswordRecoveryCode } from 'service/usecases/get-password-recovery-code/remote-get-password-recovery-code'
import { RemoteChangePassword } from 'service/usecases/change-password/remote-change-password'

export default function makeHospitalPasswordRecovery() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const getPasswordRecoveryCode = new RemoteGetPasswordRecoveryCode(
    authenticationRepository
  )
  const changePassword = new RemoteChangePassword(authenticationRepository)
  return (
    <HospitalPasswordRecovery
      changePasswordUseCase={changePassword}
      getCodeUseCase={getPasswordRecoveryCode}
    />
  )
}
