import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.semiBold} !important;
  flex: 1;
  margin-top: 20px;

  p {
    font-weight: ${({ theme }) => theme.font.semiBold} !important;
    margin-bottom: 20px;
  }

  textarea {
    margin-top: 0;
  }
`

export const CheckboxList = styled.div`
  margin-bottom: 20px;
  & > div {
    margin-right: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 400px;
    align-items: center;
  }

  & h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-weight: ${({ theme }) => theme.font.semiBold} !important;
    margin-bottom: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
  }

  & > div {
    display: grid;
    grid-template-columns: 100px repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 12px;
    align-items: center;
    margin: 10px 0;
    & > div {
      p {
        font-size: 12px;
      }
    }
    p,
    input {
      margin: 0;
    }
    input {
      height: 40px;
      font-size: ${({ theme }) => theme.font.sizes.small};
    }
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall} !important;
  margin: 0 !important;
`
