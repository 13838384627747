import { Hospital } from 'domain/entities/hospital-model'
import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import Container from 'presentation/hospital/components/Container'
import AddDoctorForm from 'presentation/hospital/components/Forms/AddDoctor'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import React from 'react'

import * as S from './styles'

type AddDoctorLayoutProps = {
  addDoctor?: AddDoctor
  hospitals?: Hospital[]
}

export default function AddDoctorLayout({
  addDoctor,
  hospitals
}: AddDoctorLayoutProps) {
  return (
    <Container>
      <S.Paper>
        <S.Wrapper>
          <Heading color="primary" size="huge">
            Novo Médico
          </Heading>
          <SupportText>Envio de convite para novos médicos</SupportText>
          <AddDoctorForm hospitals={hospitals} addDoctor={addDoctor} />
        </S.Wrapper>
      </S.Paper>
    </Container>
  )
}
