import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 90vh;
  max-height: 90vh;
  width: 720px;
  max-width: 80vw;
  background-color: ${({ theme }) => theme.colors.mainBg};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
  }
`

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`

export const DocumentList = styled.ul`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;

  li {
    height: 80px;
    padding: 20px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    p {
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray};
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }

      button {
        margin-left: 40px;
        height: 40px;
        width: 155px;
      }
    }
  }
`

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 30px;
    width: 30px;
  }
`
