import React from 'react'
import * as S from './styles'

import AddCollaboratorForm from 'presentation/hospital/components/Forms/AddCollaborator'
import Container from 'presentation/hospital/components/Container'
import { AddCollaborator } from 'domain/usecases/collaborator/add-collaborator'
import { Hospital } from 'domain/entities/hospital-model'

type AddCollaboratorLayoutProps = {
  addCollaborator?: AddCollaborator
  hospitals?: Hospital[]
}

export default function AddCollaboratorLayout({
  addCollaborator,
  hospitals
}: AddCollaboratorLayoutProps) {
  return (
    <Container>
      <S.Wrapper>
        <AddCollaboratorForm
          hospitals={hospitals}
          addCollaborator={addCollaborator}
        />
      </S.Wrapper>
    </Container>
  )
}
