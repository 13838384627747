import React from 'react'

import Logo from 'presentation/shared/components/Logo'
import * as S from './styles'
import HospitalSignupForm, {
  HospitalSignupFormValues
} from 'presentation/hospital/components/Forms/Signup'
import { FinishCollaboratorSignup } from 'domain/usecases/collaborator/finish-collaborator-signup'

type HospitalSignupLayoutProps = {
  initialValues?: HospitalSignupFormValues
  finishHospitalSignup?: FinishCollaboratorSignup
  token?: string
}

export default function HospitalSignupLayout({
  initialValues,
  finishHospitalSignup,
  token
}: HospitalSignupLayoutProps) {
  return (
    <S.Wrapper>
      <S.Header>
        <Logo
          size="huge"
          style={{
            display: 'inline-block'
          }}
        />
      </S.Header>
      <HospitalSignupForm
        finishCollaboratorSignup={finishHospitalSignup}
        initialValues={initialValues}
        token={token}
      />
    </S.Wrapper>
  )
}
