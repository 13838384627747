import React from 'react'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import {
  GetPasswordCodeFormValues,
  SetPasswordFormValues
} from 'presentation/hospital/components/Forms/PasswordRecovery'
import HospitalPasswordRecoveryLayout from 'presentation/hospital/layouts/PasswordRecovery'
import { useParams } from 'react-router'

type HospitalPasswordRecoveryParams = {
  type: 'email' | 'phone'
}

type HospitalPasswordRecoveryProps = {
  getCodeUseCase: GetPasswordRecoveryCode
  changePasswordUseCase: ChangePassword
}

export default function HospitalPasswordRecovery({
  changePasswordUseCase,
  getCodeUseCase
}: HospitalPasswordRecoveryProps) {
  const { type } = useParams<HospitalPasswordRecoveryParams>()

  const setInitialValues: GetPasswordCodeFormValues = {
    type: type,
    field: '',
    role: undefined
  }

  return (
    <HospitalPasswordRecoveryLayout
      getCodeUseCase={getCodeUseCase}
      setPasswordUseCase={changePasswordUseCase}
      getPasswordCodeInitialValues={setInitialValues}
      setPasswordInitialValues={{} as SetPasswordFormValues}
      type={type}
    />
  )
}
