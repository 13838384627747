import { FinishCollaboratorSignup } from 'domain/usecases/collaborator/finish-collaborator-signup'
import { CollaboratorRepository } from 'repository/interfaces/collaborator-repository'

export class RemoteFinishCollaboratorSignup
  implements FinishCollaboratorSignup
{
  constructor(
    private readonly collaboratorRepository: CollaboratorRepository
  ) {}

  add(
    params: FinishCollaboratorSignup.Params
  ): Promise<FinishCollaboratorSignup.Model> {
    return this.collaboratorRepository.finishCollaboratorSignup(params)
  }
}

export type FinishCollaboratorSignupModel = FinishCollaboratorSignup.Model
