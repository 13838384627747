import React, { useRef, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Hospital } from 'domain/entities/hospital-model'
import { DoctorCouncilRegister } from 'domain/entities/council-register-model'
import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import * as yup from 'yup'
import 'main/config/yup'
import * as S from './styles'
import { cpfMask, phoneMask } from 'presentation/utils/masks'

import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trashIcon.svg'
import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import Modal from 'presentation/shared/components/Modal'
import MultipleSelect, {
  MultipleSelectRefProps
} from 'presentation/shared/components/MultipleSelect'
import SelectField from 'presentation/shared/components/SelectField'
import Table from 'presentation/shared/components/Table'
import { brasilStates } from 'presentation/utils/brazil-states'

type AddDoctorFormProps = {
  addDoctor?: AddDoctor
  initialValues?: AddDoctorFormFields
  hospitals?: Hospital[]
}

export default function AddDoctorForm({
  addDoctor,
  initialValues = {} as AddDoctorFormFields,
  hospitals
}: AddDoctorFormProps) {
  const [handleSuccessModal, setHandleSuccessModal] = useState(false)
  const multipleSelectRef = useRef<MultipleSelectRefProps>(null)
  const [crmList, setCrmList] = useState<DoctorCouncilRegister[]>([])

  const handleOnAddCRM = () => {
    formik.setFieldTouched('addCrm', true)
    formik.setFieldTouched('addCrmUf', true)
    if (!formik.errors.addCrm && !formik.errors.addCrmUf) {
      setCrmList((prevState) => [
        ...prevState,
        {
          crm: formik.values.addCrm,
          crmUf: formik.values.addCrmUf,
          isMain: prevState.length ? false : true
        }
      ])
      formik.setFieldValue('addCrm', '')
      formik.setFieldValue('addCrmUf', '')
    }
  }

  const handleOnRemoveCrm = (item: DoctorCouncilRegister) => {
    const updatedList = crmList.filter((i) => i.crm !== item.crm)
    if (updatedList.length) updatedList[0].isMain = true
    setCrmList(updatedList)
  }

  useEffect(() => {
    if (crmList.length) {
      formik.setFieldValue('crm', crmList[0].crm)
      formik.setFieldValue('crmUf', crmList[0].crmUf)
      formik.setFieldValue('councilRegister', crmList)
    } else {
      formik.setFieldValue('crm', '')
      formik.setFieldValue('crmUf', '')
      formik.setFieldValue('councilRegister', '')
    }
    setTimeout(() => {
      formik.setFieldTouched('crm', true)
      formik.setFieldTouched('crmUf', true)
      formik.setFieldTouched('councilRegister', true)
    }, 100)
  }, [crmList])

  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    validationSchema: AddDoctorValidation,
    onSubmit: async (values) => {
      const requestObject: AddDoctor.Params = {
        crm: values.crm,
        crmUf: values.crmUf,
        councilRegister: values.councilRegister,
        email: values.email,
        phone: values.phone.replace(/\D+/g, ''),
        name: values.name.trim() + ' ' + values.lastName.trim(),
        cpf: values.cpf.replace(/\D+/g, ''),
        hospital_id: values.hospital_id.map((val) => Number(val))
      }

      try {
        await addDoctor?.add(requestObject)
        setHandleSuccessModal(true)
        formik.resetForm()
        multipleSelectRef?.current?.resetMultipleSelect?.()
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <S.Content>
        <TextField
          bgColor="mainBg"
          label="Nome"
          name="name"
          value={formik.values.name || ''}
          onInputChange={formik.handleChange('name')}
          onBlur={formik.handleBlur('name')}
          error={formik.touched.name && formik.errors.name}
        />
        <TextField
          bgColor="mainBg"
          label="Sobrenome"
          name="lastName"
          value={formik.values.lastName || ''}
          onInputChange={formik.handleChange('lastName')}
          onBlur={formik.handleBlur('lastName')}
          error={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          bgColor="mainBg"
          label="Celular"
          name="phone"
          value={formik.values.phone || ''}
          onInputChange={formik.handleChange('phone')}
          mask={phoneMask}
          onBlur={formik.handleBlur('phone')}
          error={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          bgColor="mainBg"
          label="CPF"
          name="cpf"
          value={formik.values.cpf || ''}
          onInputChange={formik.handleChange('cpf')}
          mask={cpfMask}
          onBlur={formik.handleBlur('cpf')}
          error={formik.touched.cpf && formik.errors.cpf}
        />
        <TextField
          bgColor="mainBg"
          label="E-mail"
          name="email"
          value={formik.values.email || ''}
          onInputChange={formik.handleChange('email')}
          onBlur={formik.handleBlur('email')}
          error={formik.touched.email && formik.errors.email}
        />
        <MultipleSelect
          label="Unidades"
          name="hospital_id"
          error={formik.touched.hospital_id && formik.errors.hospital_id}
          items={hospitals?.map((hospital) => ({
            label: hospital.nickname!,
            value: hospital.hospital_id!
          }))}
          onBlur={formik.handleBlur('hospital_id')}
          onCheck={formik.handleChange('hospital_id')}
          ref={multipleSelectRef}
        />
      </S.Content>
      <S.CrmContainer>
        <S.AddCrm>
          <SelectField
            bgColor="mainBg"
            label="UF"
            name="addCrmUf"
            value={formik.values.addCrmUf || ''}
            initialValue={''}
            onInputChange={formik.handleChange('addCrmUf')}
            onBlur={formik.handleBlur('addCrmUf')}
            error={formik.touched.addCrmUf && formik.errors.addCrmUf}
            items={brasilStates}
            required
          />
          <TextField
            bgColor="mainBg"
            label="CRM"
            name="addCrm"
            value={formik.values.addCrm || ''}
            onInputChange={formik.handleChange('addCrm')}
            onBlur={formik.handleBlur('addCrm')}
            error={formik.touched.addCrm && formik.errors.addCrm}
            required
          />
          <Button
            disabled={!formik.values.addCrm || !formik.values.addCrmUf}
            onClick={handleOnAddCRM}
            style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
            type="button"
          >
            Adicionar
          </Button>
        </S.AddCrm>
        <S.CrmList>
          <Table
            columns={columns}
            data={crmList?.map((item) => ({
              crmUf: item.crmUf,
              crm: item.crm,
              delete: (
                <S.DeleteButton
                  onClick={() => handleOnRemoveCrm(item)}
                  type="button"
                >
                  <TrashIcon />
                </S.DeleteButton>
              )
            }))}
            pagination={false}
          />
        </S.CrmList>
      </S.CrmContainer>
      <Button
        disabled={!formik.isValid || formik.isSubmitting}
        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
        type="submit"
      >
        Enviar Convite
      </Button>
      <Modal
        title="Médico cadastrado com sucesso!"
        show={handleSuccessModal}
        close={() => setHandleSuccessModal(false)}
      />
    </S.Wrapper>
  )
}

const columns = [
  {
    name: 'crmUf',
    label: 'Estado',
    options: {
      sort: false
    }
  },
  {
    name: 'crm',
    label: 'CRM',
    options: {
      sort: false
    }
  },
  {
    name: 'delete',
    label: ' ',
    options: {
      sort: false
    }
  }
]

const AddDoctorValidation = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required(),
  email: yup.string().email().required(),
  cpf: yup.string().cpf().required(),
  addCrm: yup.string().nullable(),
  addCrmUf: yup.string().nullable(),
  crm: yup.string().required(),
  crmUf: yup.string().required(),
  councilRegister: yup.array().min(1, 'Adicione CRM do médico').required(),
  hospital_id: yup.array().min(1, 'Campo obrigatório').required()
})

export type AddDoctorFormFields = {
  name: string
  lastName: string
  phone: string
  email: string
  cpf: string
  addCrm: string
  addCrmUf: string
  crm: string
  crmUf: string
  councilRegister: DoctorCouncilRegister[]
  hospital_id: string[]
}
