import React from 'react'

import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import SurgicalOrderTable, {
  SurgicalOrderTableFormValues
} from 'presentation/hospital/components/SurgicalOrderTable'
import { getSurgicalOrderStatusLabelByEnum } from 'presentation/utils/surgical-order-status'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { SearchSurgicalOrdersModel } from 'service/usecases/search-surgical-orders/remote-search-surgical-orders'
import { PendencyType } from 'domain/entities/surgical-pendency'
type SecretaryAuthorizationLayoutProps = {
  data: any[]
  initialValues?: SurgicalOrderTableFormValues
  filterSubmit?: (
    fields: SurgicalOrderTableFormValues
  ) => Promise<SearchSurgicalOrdersModel | undefined>
  loadHospitals?: LoadHospitals
  loadHealthInsurances?: LoadHealthInsurancePlans
  loadSurgeryCenters?: LoadSurgeryCenter
  reorderSurgicalOrder?: ReorderSurgicalOrder
  loadSurgicalOrder?: LoadSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
}

export default function SecretaryAuthorizationLayout({
  data,
  initialValues,
  filterSubmit,
  loadHospitals,
  loadHealthInsurances,
  loadSurgeryCenters,
  reorderSurgicalOrder,
  loadSurgicalOrder,
  loadSurgicalOrderDocument
}: SecretaryAuthorizationLayoutProps) {
  function handleHeadingLabel(initialValues?: SurgicalOrderTableFormValues) {
    if (!initialValues?.surgeryStatus) return 'Total de pedidos'
    if (initialValues?.pendencyType)
      return getPendencyTypeLabel(initialValues.pendencyType)
    return getSurgicalOrderStatusLabelByEnum(initialValues.surgeryStatus)
  }
  function getPendencyTypeLabel(pendencyType: PendencyType) {
    return pendencyType === PendencyType.ASSISTANCE
      ? 'Pendência Assistencial'
      : 'Pendência de Autorização'
  }
  return (
    <Container>
      <Heading size="huge" color="primary">
        {handleHeadingLabel(initialValues)}
      </Heading>
      <SurgicalOrderTable
        data={data}
        filterInitialValues={initialValues}
        filterSubmit={filterSubmit}
        reorderSurgicalOrder={reorderSurgicalOrder}
        loadHospitals={loadHospitals}
        loadHealthInsurances={loadHealthInsurances}
        loadSurgeryCenters={loadSurgeryCenters}
        loadSurgicalOrder={loadSurgicalOrder}
        loadSurgicalOrderDocument={loadSurgicalOrderDocument}
      />
    </Container>
  )
}
