import React, { useState } from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import { Patient } from 'domain/entities/patient-model'
import { cpfMask } from 'presentation/utils/masks'
import SelectField from 'presentation/shared/components/SelectField'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Button from 'presentation/shared/components/Button'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'

import * as S from './styles'

type Props = {
  loadPatientInfo: Patient
  onlyVisualization: boolean
  surgicalOrder?: SurgicalOrderModel
  hospitals: Hospital[]
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
} & WithLoadingProps

const ReviewCanceledOrder = WithLoading(
  ({
    loadPatientInfo,
    hospitals,
    surgicalOrder,
    loadSurgicalOrderDocument,
    setIsLoading
  }: Props) => {
    const [onlyVisualization] = useState(true)
    const birthDay = moment(loadPatientInfo.birthday).format('DD/MM/YYYY')
    const age = getAgeByBirthdayDate(birthDay)

    const history = useHistory()

    const { handleSubmit } = useFormik({
      initialValues: {
        cpf: loadPatientInfo.cpf,
        name: loadPatientInfo.name,
        gender: loadPatientInfo.gender,
        phone: loadPatientInfo.phone,
        email: loadPatientInfo.email,
        birthday: birthDay
      },
      enableReinitialize: true,
      onSubmit: async () => null
    })

    const handleDownlodDocument = async (document_id: number, type: string) => {
      try {
        if (surgicalOrder?.surgical_order_id) {
          setIsLoading(true)
          const surgicalOrderDocument = await loadSurgicalOrderDocument?.load(
            surgicalOrder?.surgical_order_id,
            document_id
          )
          if (surgicalOrderDocument) {
            downloadFileFromBlob(
              surgicalOrderDocument.data,
              surgicalOrderDocument.contentType,
              getDocumentsLabelLocationByEnum(type)
            )
          }
        }
      } catch (error) {
        toast.error('Falha ao realizar o download do arquivo')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Heading as="h2">Dados do paciente</Heading>
          <S.Wrapper>
            <S.InputsContainer>
              <TextField
                key={'cpf'}
                bgColor="mainBg"
                label={'CPF'}
                name={'cpf'}
                mask={cpfMask}
                disabled={onlyVisualization}
                value={loadPatientInfo.cpf}
              />
              <TextField
                key={'name'}
                bgColor="mainBg"
                label={'Paciente'}
                name={'name'}
                disabled={onlyVisualization}
                value={loadPatientInfo.name}
              />
              <TextField
                key={'birthday'}
                bgColor="mainBg"
                label={'Data de nascimento'}
                name={'birthday'}
                disabled={onlyVisualization}
                value={birthDay}
              />
              <TextField
                key={'age'}
                bgColor="mainBg"
                label={'Idade'}
                name={'age'}
                disabled={onlyVisualization}
                value={age}
                style={{ width: 75 }}
              />
            </S.InputsContainer>
            <S.InputsContainer>
              <TextField
                key={'gender'}
                bgColor="mainBg"
                label={'Sexo'}
                name={'gender'}
                disabled={onlyVisualization}
                value={loadPatientInfo.gender}
              />
              <TextField
                key={'phone'}
                bgColor="mainBg"
                label={'Celular'}
                name={'phone'}
                disabled={onlyVisualization}
                value={loadPatientInfo.phone}
              />
              <TextField
                key={'landlinePhone'}
                bgColor="mainBg"
                label={'Telefone fixo'}
                name={'landlinePhone'}
                disabled={onlyVisualization}
                value={loadPatientInfo.landlinePhone}
              />
              <TextField
                key={'email'}
                bgColor="mainBg"
                label={'E-mail'}
                name={'email'}
                disabled={onlyVisualization}
                value={loadPatientInfo.email}
              />
            </S.InputsContainer>
          </S.Wrapper>
        </form>
        <S.Container>
          <Heading as="h2">Unidade de realização</Heading>
          <SelectField
            label="Hospital *"
            name="type"
            items={hospitals.map((hospital) => ({
              label: hospital.nickname,
              value: hospital.hospital_id
            }))}
            style={{ width: 300 }}
            disabled
          />
        </S.Container>
        <S.Container>
          <Heading as="h2">Dados do convênio</Heading>
        </S.Container>
        <S.InputsContainer>
          <TextField
            label="Convênio"
            disabled
            defaultValue={surgicalOrder?.healthInsurance?.healthInsuranceName}
          />
          <TextField
            label="Plano de saúde"
            disabled
            defaultValue={surgicalOrder?.healthInsurance?.healthPlanName}
          />
          <TextField
            label="Número da carteira"
            disabled
            defaultValue={surgicalOrder?.healthInsurance?.healthCard}
          />
          <TextField
            label="Validade da carteira"
            disabled
            defaultValue={
              surgicalOrder?.healthInsurance?.validThru
                ? moment(surgicalOrder?.healthInsurance?.validThru).format(
                    'DD/MM/YYYY'
                  )
                : ''
            }
          />
          <TextField
            label="Empresa"
            disabled
            defaultValue={surgicalOrder?.healthInsurance?.company}
          />
        </S.InputsContainer>
        <S.Container>
          <Heading as="h2">Anexos</Heading>
        </S.Container>
        <S.BgContainer>
          <S.Documents>
            {surgicalOrder?.documents?.map((document) => (
              <S.Document key={document.document_id}>
                <Heading as="h2" size="small" style={{ flex: 1 }}>
                  {document.type}
                </Heading>
                <Button
                  fullWidth
                  onClick={() =>
                    handleDownlodDocument(document.document_id, document.type)
                  }
                  disabled={!onlyVisualization}
                  type="button"
                  style={{ backgroundColor: '#5aca65', width: 155 }}
                >
                  Download
                </Button>
              </S.Document>
            ))}
          </S.Documents>
        </S.BgContainer>
        <S.Container>
          <Heading as="h2" size="small" style={{ flex: 1 }}>
            Motivo de Cancelamento
          </Heading>
          <S.BgContainer>
            <S.Responsible>
              Responsável:{' '}
              {surgicalOrder?.cancelledInfo?.cancelledBy?.name ??
                surgicalOrder?.cancelledInfo?.cancelledCollaboratorRegister}
            </S.Responsible>
            <p>
              Data e Horário:{' '}
              {surgicalOrder?.cancelledInfo?.cancelledAt
                ? moment
                    .utc(surgicalOrder?.cancelledInfo?.cancelledAt)
                    .format('DD/MM/YYYY - HH:mm')
                : '-'}
            </p>
          </S.BgContainer>
          <S.Justification>
            {surgicalOrder?.justificationToDelete}
          </S.Justification>
        </S.Container>
        <S.Container style={{ position: 'relative', marginBottom: 100 }}>
          <Button
            fullWidth
            onClick={() => history.goBack()}
            disabled={!onlyVisualization}
            type="button"
            style={{ width: 155, position: 'absolute', right: 0 }}
          >
            Fechar
          </Button>
        </S.Container>
      </>
    )
  }
)

export default ReviewCanceledOrder
