import React, { useEffect, useImperativeHandle, useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'

import * as S from './styles'
import Checkbox from 'presentation/shared/components/Checkbox'
import SupportText from 'presentation/shared/components/SupportText'
import TextArea from 'presentation/shared/components/TextArea'
import Modal from 'presentation/shared/components/Modal'
import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'
import { useAuthorizationContext } from 'presentation/hospital/pages/Authorization/context'
import TextField from 'presentation/shared/components/TextField'
import { phoneMask } from 'presentation/utils/masks'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import {
  PendencyType,
  pendencyTypeTranslator
} from 'domain/entities/surgical-pendency'
import theme from 'presentation/styles/theme'

export type Props = {
  doctor_id: number
  patient_id: number
  surgical_order_id: number
  close: () => void
  addSurgicalPendency?: AddSurgicalPendency
  setIsValid: (state: boolean) => void
  surgicalOrder: SurgicalOrderList
}

type RefProps = {
  submitForm: () => void
}

const AddPendencyForm: React.ForwardRefRenderFunction<RefProps, Props> = (
  {
    doctor_id,
    surgical_order_id,
    patient_id,
    close,
    addSurgicalPendency,
    setIsValid,
    surgicalOrder
  }: Props,
  forwardedRef
) => {
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const context = useAuthorizationContext()
  const {
    submitForm,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue
  } = useFormik<AddPendencyFormValues>({
    initialValues: {
      notify: { doctor: true, patient: true, secretary: true },
      notifyBy: { sms: true, email: true, platform: true },
      doctor_id: doctor_id,
      patient_id: patient_id,
      surgical_order_id: surgical_order_id,
      observation: '',
      phone: {
        doctor: surgicalOrder.doctorPhone || '',
        patient: surgicalOrder.patientPhone || '',
        secretary: ''
      },
      email: {
        doctor: surgicalOrder.doctorEmail || '',
        patient: surgicalOrder.patientEmail || '',
        secretary: ''
      }
    },
    validationSchema,
    validateOnMount: false,
    onSubmit: async (values) => {
      try {
        const pendency = await addSurgicalPendency?.add({
          ...values,
          phone: {
            doctor: values.phone.doctor.replace(/\D+/g, ''),
            patient: values.phone.patient.replace(/\D+/g, ''),
            secretary: values.phone.secretary.replace(/\D+/g, '')
          },
          email: {
            doctor: values.email.doctor,
            patient: values.email.patient,
            secretary: values.email.secretary
          }
        })
        if (pendency) {
          context?.addPendency(
            pendency.surgical_order_id.toString() ?? '',
            pendency
          )
        }
        setSuccessModal(true)
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  useImperativeHandle(forwardedRef, () => ({
    submitForm: submitForm
  }))

  useEffect(() => {
    setIsValid(isValid)
  }, [isValid])

  const handleTypeChange = (newValue: PendencyType) => {
    if (values.type === newValue) {
      setFieldValue('type', undefined)
    } else {
      setFieldValue('type', newValue)
    }
  }

  return (
    <>
      <S.Wrapper>
        <S.CheckboxList>
          <h2>Tipo de pendência:</h2>
          <div>
            {Object.keys(pendencyTypeTranslator).map((type) => {
              const pendencyType = type as keyof typeof pendencyTypeTranslator
              return (
                <Checkbox
                  label={pendencyTypeTranslator[pendencyType]}
                  value={pendencyType}
                  name="type"
                  id={`type.${type}`}
                  labelFor={`type.${type}`}
                  checked={values.type === pendencyType}
                  onChange={() => handleTypeChange(pendencyType)}
                  key={type}
                />
              )
            })}
          </div>
          {errors.type && <S.Error>{errors.type}</S.Error>}
        </S.CheckboxList>
        <S.CheckboxList>
          <h2>Enviar notificação para:</h2>
          <div>
            <Checkbox
              label="Médico"
              name="notify.doctor"
              id="notify.doctor"
              labelFor="notify.doctor"
              checked={values.notify.doctor}
              onChange={handleChange}
            />
            <Checkbox
              label="Paciente"
              name="notify.patient"
              id="notify.patient"
              labelFor="notify.patient"
              checked={values.notify.patient}
              onChange={handleChange}
            />
            <Checkbox
              label="Secretária"
              name="notify.secretary"
              id="notify.secretary"
              labelFor="notify.secretary"
              checked={values.notify.secretary}
              onChange={handleChange}
            />
          </div>
        </S.CheckboxList>
        <S.CheckboxList>
          <h2>Notificar via:</h2>
          <div>
            <Checkbox
              label="E-mail"
              name="notifyBy.email"
              id="notifyBy.email"
              labelFor="notifyBy.email"
              checked={values.notifyBy.email}
              onChange={handleChange}
            />
            <Checkbox
              label="SMS"
              name="notifyBy.sms"
              id="notifyBy.sms"
              labelFor="notifyBy.sms"
              checked={values.notifyBy.sms}
              onChange={handleChange}
            />
            {/* <Checkbox
              label="Plataforma"
              name="notifyBy.platform"
              id="notifyBy.platform"
              labelFor="notifyBy.platform"
              checked={values.notifyBy.platform}
              onChange={handleChange}
            /> */}
          </div>
        </S.CheckboxList>
        <S.InputsWrapper>
          <div>
            <p>Médico</p>
            <TextField
              placeholder="E-mail"
              error={touched.email?.doctor && errors.email?.doctor}
              name="email.doctor"
              onChange={handleChange}
              onBlur={handleBlur}
              defaultValue={surgicalOrder.doctorEmail}
            />
            <TextField
              placeholder="Telefone"
              error={touched.phone?.doctor && errors.phone?.doctor}
              name="phone.doctor"
              onChange={handleChange}
              onBlur={handleBlur}
              mask={phoneMask}
              defaultValue={surgicalOrder.doctorPhone || '+55'}
            />
          </div>
          <div>
            <p>Paciente</p>
            <TextField
              placeholder="E-mail"
              error={touched.email?.patient && errors.email?.patient}
              name="email.patient"
              onChange={handleChange}
              onBlur={handleBlur}
              defaultValue={surgicalOrder.patientEmail}
            />
            <TextField
              placeholder="Telefone"
              error={touched.phone?.patient && errors.phone?.patient}
              name="phone.patient"
              onBlur={handleBlur}
              onChange={handleChange}
              mask={phoneMask}
              defaultValue={surgicalOrder.patientPhone || '+55'}
            />
          </div>
          <div>
            <p>Secretária</p>
            <TextField
              placeholder="E-mail"
              error={touched.email?.secretary && errors.email?.secretary}
              name="email.secretary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              placeholder="Telefone"
              error={touched.phone?.secretary && errors.phone?.secretary}
              name="phone.secretary"
              onChange={handleChange}
              onBlur={handleBlur}
              mask={phoneMask}
              defaultValue={'+55'}
            />
          </div>
        </S.InputsWrapper>
        <SupportText
          color="primary"
          style={{
            fontSize: theme.font.sizes.medium
          }}
        >
          Detalhamento da pendência
        </SupportText>
        <TextArea
          rows={5}
          placeholder="Observação"
          bgColor="white"
          name="observation"
          id="observation"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.observation && errors.observation}
        />
      </S.Wrapper>
      <Modal
        show={successModal}
        close={close}
        title="Pendência gerada com sucesso"
      />
      <LoadingModal show={isSubmitting} />
    </>
  )
}

export default React.forwardRef(AddPendencyForm)

type AddPendencyFormValues = {
  notify: { patient: boolean; doctor: boolean; secretary: boolean }
  notifyBy: { email: boolean; sms: boolean; platform: boolean }
  observation: string
  surgical_order_id: number
  doctor_id: number
  patient_id: number
  email: {
    doctor: string
    patient: string
    secretary: string
  }
  phone: {
    doctor: string
    patient: string
    secretary: string
  }
  type?: PendencyType
}

const validationSchema = yup.object().shape({
  observation: yup
    .string()
    .required('O detalhamento da pendência é obrigatório'),
  type: yup.string().required('Tipo de pendência é obrigatório'),
  email: yup.object().shape({
    doctor: yup.string().email().optional(),
    patient: yup.string().email().optional(),
    secretary: yup.string().email().optional()
  }),
  phone: yup.object().shape({
    doctor: yup
      .string()
      .test(
        'masked-phone-validation',
        'Telefone do médico inválido',
        function (val) {
          return !!(
            val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
            val?.match(/^[0-9]{13}$/) ||
            !val
          )
        }
      )
      .optional(),
    patient: yup
      .string()
      .test(
        'masked-phone-validation',
        'Telefone do paciente inválido',
        function (val) {
          return !!(
            val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
            val?.match(/^[0-9]{13}$/) ||
            !val
          )
        }
      )
      .optional(),
    secretary: yup
      .string()
      .test(
        'masked-phone-validation',
        'Telefone da secretária inválido',
        function (val) {
          return !!(
            val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
            val?.match(/^[0-9]{13}$/) ||
            !val
          )
        }
      )
      .optional()
  })
})
