import React, { useEffect, useState } from 'react'

import moment from 'moment'

import SecretaryAuthorizationLayout from 'presentation/secretary/layouts/Authorization'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import {
  SurgicalOrderList,
  SurgicalOrderTableFormValues
} from 'presentation/hospital/components/SurgicalOrderTable'
import { useLocation } from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import { observer } from 'mobx-react'
import LoadingModal from 'presentation/shared/components/LoadingModal'
import { Hospital } from 'domain/entities/hospital-model'
import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { createAuthorizationContext } from 'presentation/hospital/pages/Authorization/context'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { FinishSurgicalPendency } from 'domain/usecases/surgical-pendency/finish-surgical-pendency'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { Pagination } from 'service/protocols/api/api-client'
import { PaginationModel } from 'domain/entities/pagination-model'
import translateRole from 'common/utils/getTranslatedRole'
import { getCrmoTableStatus } from './Functions/getCrmoTableStatus'

export type SecretaryAuthorizationProps = {
  searchSurgicalOrders: SearchSurgicalOrders
  addSurgicalPendency: AddSurgicalPendency
  loadSurgicalPendencyDocument: LoadSurgicalPendencyDocument
  finishSurgicalPendency: FinishSurgicalPendency
  loadHospitals: LoadHospitals
  loadHealthInsurances: LoadHealthInsurancePlans
  loadSurgeryCenters: LoadSurgeryCenter
  reorderSurgicalOrder: ReorderSurgicalOrder
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderDocument: LoadSurgicalOrderDocument
}

const DEFAULT_PAGINATION: Pagination = {
  pageNumber: 1,
  pageSize: 20
}

const HospitalAuthorization = observer(
  ({
    searchSurgicalOrders,
    addSurgicalPendency,
    loadSurgicalPendencyDocument,
    finishSurgicalPendency,
    loadHospitals,
    loadHealthInsurances,
    loadSurgeryCenters,
    reorderSurgicalOrder,
    loadSurgicalOrder,
    loadSurgicalOrderDocument
  }: SecretaryAuthorizationProps) => {
    const [tableData, setTableData] = useState<SurgicalOrderList[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [initialPagination, setInitialPagination] = useState<PaginationModel>(
      {
        currentPage: 1,
        itemsPerPage: 20,
        totalItems: 20,
        totalPages: 1
      }
    )
    const location = useLocation<{
      surgeryStatus: SurgicalOrderStatus
      state?: any
      startDate: string
      endDate: string
    }>()
    const currentHospital = useStores().currentHospital.actualHospital

    const addPendency = async (
      id: string,
      pendency: SurgicalPendency,
      forceRefresh?: boolean
    ) => {
      if (forceRefresh) {
        return location.state.surgeryStatus
          ? await loadSurgicalOrders({
              ...location.state.state,
              surgeryStatus: location.state.surgeryStatus
            })
          : await loadSurgicalOrders()
      }
      const data = tableData.map((data) => {
        if (Number(data.surgery_request_id) === Number(id)) {
          const equalPendency: SurgicalPendency | undefined =
            data?.pendencies?.find((data) => {
              return data.surgical_pendency_id === pendency.surgical_pendency_id
            })
          if (equalPendency) {
            data.pendencies = [
              ...(data.pendencies?.filter(
                (pendency) =>
                  pendency.surgical_pendency_id !==
                  equalPendency?.surgical_pendency_id
              ) ?? []),
              pendency
            ]
          } else {
            data.pendencies?.push(pendency)
          }
        }
        return data
      })
      setTableData(data)
    }

    const loadSurgicalOrders = async (params?: SearchSurgicalOrders.Params) => {
      try {
        setIsLoading(true)

        const fields = [
          'pageInfo{totalItems, totalPages, currentPage, itemsPerPage}',
          'data{' +
            'surgical_order_id, procedure {description}, hospital{name, hospital_id}' +
            'patient { name, gender, birthday, patient_id, email, phone}' +
            'healthInsurance {healthInsuranceName}' +
            'doctor {name, doctor_id, email, phone}' +
            'status {status}' +
            'createdAt,' +
            'isSolicitation,' +
            'isComplete,' +
            'surgicalCenter,' +
            'patientHospitalized,' +
            'createdBy {name, role},' +
            'surgicalCenter,' +
            'hospitalizationType,' +
            'expectedDate,' +
            'pendencies {documents{base64,document_id, type, name, group_id},status, answeredAt,observation, answer, surgical_pendency_id, createdAt, answeredAt, requestedBy {name}}}'
        ]

        if (!params) {
          params = { pagination: DEFAULT_PAGINATION }
        }

        const surgicalOrders = await searchSurgicalOrders.load({
          ...params,
          params: fields,
          startDate: location.state?.startDate,
          endDate: location.state?.endDate,
          surgeryStatus:
            location.state?.surgeryStatus === SurgicalOrderStatus.PENDENCY
              ? undefined
              : location.state?.surgeryStatus,
          pendency:
            location.state.surgeryStatus === SurgicalOrderStatus.PENDENCY
              ? true
              : undefined,
          pendencyType: location?.state?.state?.pendencyType || undefined,
          surgical_order_id: params.surgical_order_id || undefined
        })

        setInitialPagination(surgicalOrders.pageInfo)
        setTableData(
          surgicalOrders.data?.map((surgicalOrder) => {
            return {
              surgery_request_id: surgicalOrder.surgical_order_id?.toString(),
              patient: surgicalOrder.patient?.name,
              surgery:
                surgicalOrder?.procedure && surgicalOrder?.procedure?.length > 0
                  ? surgicalOrder?.procedure[0].description
                  : '',
              doctor: surgicalOrder.doctor?.name,
              insurance: surgicalOrder.healthInsurance?.healthInsuranceName,
              gender: surgicalOrder.patient?.gender,
              unit: new Hospital(
                surgicalOrder.hospital?.hospital_id ?? 0,
                surgicalOrder.hospital?.name ?? '',
                surgicalOrder.hospital?.name ?? ''
              ).name,
              status:
                surgicalOrder?.status &&
                getCrmoTableStatus(surgicalOrder?.status),
              createdAt: moment(surgicalOrder?.createdAt)
                .utc(true)
                .format('DD/MM/YYYY HH:mm'),
              patientHospitalized: surgicalOrder.patientHospitalized && 'Sim',
              createdBy:
                surgicalOrder.createdBy?.name +
                ' * ' +
                translateRole(surgicalOrder.createdBy?.role),
              surgicalCenter: surgicalOrder.surgicalCenter,
              pendencies: surgicalOrder.pendencies,
              doctor_id: surgicalOrder.doctor?.doctor_id,
              patient_id: surgicalOrder.patient?.patient_id,
              isSolicitation: surgicalOrder.isSolicitation,
              priorities:
                surgicalOrder.surgicalCenter +
                '---' +
                surgicalOrder.patientHospitalized,
              doctorEmail: surgicalOrder.doctor?.email,
              doctorPhone: surgicalOrder.doctor?.phone,
              patientEmail: surgicalOrder.patient?.email,
              patientPhone: surgicalOrder.patient?.phone,
              isUrgent: {
                urgent: surgicalOrder.hospitalizationType === 'urgency',
                expectedDate: surgicalOrder.expectedDate?.[0]
              },
              isComplete: surgicalOrder.isComplete
            }
          })
        )
        return surgicalOrders
      } catch (e) {
        return
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      async function loadData() {
        if (location?.state?.surgeryStatus || location?.state?.state) {
          loadSurgicalOrders({
            ...location.state.state,
            surgeryStatus: location.state.surgeryStatus
          })
        } else {
          loadSurgicalOrders()
        }
      }

      loadData()
    }, [currentHospital])

    useEffect(() => {
      createAuthorizationContext({
        addSurgicalPendency,
        addPendency,
        loadSurgicalPendencyDocument,
        finishSurgicalPendency,
        initialPaginationState: initialPagination
      })
    }, [tableData])

    return (
      <>
        <SecretaryAuthorizationLayout
          data={tableData ?? []}
          initialValues={
            {
              surgeryStatus: location?.state?.surgeryStatus,
              pendencyType: location?.state?.state?.pendencyType
            } as SurgicalOrderTableFormValues
          }
          filterSubmit={loadSurgicalOrders}
          loadSurgeryCenters={loadSurgeryCenters}
          reorderSurgicalOrder={reorderSurgicalOrder}
          loadHospitals={loadHospitals}
          loadHealthInsurances={loadHealthInsurances}
          loadSurgicalOrder={loadSurgicalOrder}
          loadSurgicalOrderDocument={loadSurgicalOrderDocument}
        />
        <LoadingModal show={isLoading} />
      </>
    )
  }
)

export default HospitalAuthorization
