export const brasilStates = [
  { label: 'AC - Acre', value: 'AC' },
  { label: 'AL - Alagoas', value: 'AL' },
  { label: 'AP - Amapá', value: 'AP' },
  { label: 'AM - Amazonas', value: 'AM' },
  { label: 'BA - Bahia', value: 'BA' },
  { label: 'CE - Ceará', value: 'CE' },
  { label: 'DF - Distrito Federal', value: 'DF' },
  { label: 'ES - Espírito Santo', value: 'ES' },
  { label: 'GO - Goiás', value: 'GO' },
  { label: 'MA - Maranhão', value: 'MA' },
  { label: 'MT - Mato Grosso', value: 'MT' },
  { label: 'MS - Mato Grosso do Sul', value: 'MS' },
  { label: 'MG - Minas Gerais', value: 'MG' },
  { label: 'PA - Pará', value: 'PA' },
  { label: 'PB - Paraíba', value: 'PB' },
  { label: 'PR - Paraná', value: 'PR' },
  { label: 'PE - Pernambuco', value: 'PE' },
  { label: 'PI - Piauí', value: 'PI' },
  { label: 'RJ - Rio de Janeiro', value: 'RJ' },
  { label: 'RN - Rio Grande do Norte', value: 'RN' },
  { label: 'RS - Rio Grande do Sul', value: 'RS' },
  { label: 'RO - Rondônia', value: 'RO' },
  { label: 'RR - Roraima', value: 'RR' },
  { label: 'SC - Santa Carina', value: 'SC' },
  { label: 'SP - São Paulo', value: 'SP' },
  { label: 'SE - Sergipe', value: 'SE' },
  { label: 'TO - Tocantins', value: 'TO' }
]

export const brasilStatesUF = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' }
]
