import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import * as S from './styles'
import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import ActualPage from 'presentation/shared/components/ActualPage'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import { dateInputFormat } from 'presentation/utils/date-format'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import { Document } from 'domain/entities/document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { useServices } from 'presentation/hooks/use-services'
import { PatientDocument } from 'common/enum/patient-document'
import {
  DocumentItem,
  DocumentsAreaModal
} from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import {
  UploadListFile,
  ListFile
} from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'

type Props = {
  surgicalOrder?: SurgicalOrderModel
  healthCardDocument?: ListFile
} & WithLoadingProps

function SolicitationLayout({
  surgicalOrder,
  setIsLoading,
  healthCardDocument
}: Props) {
  const [reports, setReports] = useState<DocumentItem[]>()
  const [order, setOrder] = useState<DocumentItem[]>()
  const [orderMedical, setOrderMedical] = useState<DocumentItem[]>()
  const [showSurgicalOrderDoc, setShowSurgicalOrderDoc] =
    useState<boolean>(false)
  const [showReportsModal, setShowReportsModal] = useState<boolean>(false)
  const [showMedicalReport, setShowMedicalReport] = useState<boolean>(false)
  const service = useServices().surgicalOrder
  const history = useHistory()

  const downloadFile = async ({ file }: ListFile) => {
    downloadFileFromBlob(file, file.type, file.name)
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        if (!reports && showReportsModal) {
          const filteredReports = surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.EXAM_REPORT
          )
          const resolvedReports = await getDoc(filteredReports ?? [])
          setReports(resolvedReports)
        }
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar carregar os laudos')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [surgicalOrder, showReportsModal])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (!order && showSurgicalOrderDoc) {
          const filteredOrder = surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.SURGICAL_ORDER
          )

          const resolvedOrders = await getDoc(filteredOrder ?? [])
          setOrder(resolvedOrders)
        }
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar carregar o pedido cirúrgico')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [surgicalOrder, showSurgicalOrderDoc])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (!orderMedical && showMedicalReport) {
          const filteredOrder = surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.MEDICAL_REPORT
          )

          const resolvedOrders = await getDoc(filteredOrder ?? [])
          setOrderMedical(resolvedOrders)
        }
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar carregar o relatório médico')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [surgicalOrder, showMedicalReport])

  const getDoc = async (documents: Document[]) => {
    return await Promise.all(
      documents.map(async (doc) => {
        const file = await service.loadSurgicalOrderDocument({
          surgical_order_id:
            doc.group_id ?? surgicalOrder?.surgical_order_id ?? 0,
          document_id: doc.document_id
        })

        return {
          document_id: doc.document_id,
          type: doc.type,
          file: new File([file.data], 'asda.jpg', {
            type: file.contentType
          }),
          label:
            doc.type === PatientDocument.EXAM_REPORT ? 'Laudos 1' : doc.type
        }
      })
    )
  }

  return (
    <Container>
      <S.Wrapper>
        <ActualPage text="Voltar" />
        <Heading color="primary" size="huge" as="h1">
          Solicitação de pedido cirúrgico
        </Heading>
        <Grid container>
          <Heading as="h2" size="large">
            Dados do paciente
          </Heading>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                label="Paciente"
                disabled
                defaultValue={surgicalOrder?.patient?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Data de nascimento"
                defaultValue={dateInputFormat(surgicalOrder?.patient?.birthday)}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Idade"
                disabled
                defaultValue={getAgeByBirthdayDate(
                  surgicalOrder?.patient?.birthday
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Sexo"
                disabled
                defaultValue={surgicalOrder?.patient?.gender}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="CPF"
                disabled
                mask={cpfMask}
                defaultValue={surgicalOrder?.patient?.cpf}
              />
            </Grid>
          </Grid>
          <Heading as="h2" size="large">
            Contatos do paciente
          </Heading>
          <Grid container spacing={5} xs={12}>
            <Grid item xs={6}>
              <TextField
                label="E-mail"
                defaultValue={surgicalOrder?.patient?.email}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Telefone"
                mask={phoneMask}
                defaultValue={surgicalOrder?.patient?.phone}
                disabled
              />
            </Grid>
          </Grid>
          <Heading as="h2" size="large">
            Dados do convênio
          </Heading>
          <Grid container spacing={5} xs={12}>
            <Grid item xs={4}>
              <TextField
                label="Convênio"
                disabled
                defaultValue={
                  surgicalOrder?.patient?.healthInsurance?.healthInsuranceName
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Plano"
                disabled
                defaultValue={
                  surgicalOrder?.patient?.healthInsurance?.healthPlanName
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Empresa (titular do plano)"
                disabled
                defaultValue={surgicalOrder?.patient?.healthInsurance?.company}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Número da carteira"
                disabled
                defaultValue={
                  surgicalOrder?.patient?.healthInsurance?.healthCard
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Validade da carteira"
                disabled
                defaultValue={dateInputFormat(
                  surgicalOrder?.patient?.healthInsurance?.validThru
                )}
              />
            </Grid>
          </Grid>
          {healthCardDocument && (
            <>
              <Heading as="h2" size="large">
                Foto da carteira
              </Heading>
              <Grid container xs={12}>
                <UploadListFile
                  allCan={{
                    canDelete: false,
                    canDownload: true,
                    canPreview: true,
                    canUpdate: false
                  }}
                  listFile={healthCardDocument}
                  onDownload={downloadFile}
                />
              </Grid>
            </>
          )}
        </Grid>
        <S.DocumentButton onClick={() => setShowSurgicalOrderDoc(true)}>
          <p>Pedido cirúrgico</p>
        </S.DocumentButton>
        <S.DocumentButton onClick={() => setShowReportsModal(true)}>
          <p>Laudo</p>
        </S.DocumentButton>
        <S.DocumentButton onClick={() => setShowMedicalReport(true)}>
          <p>Relatório Médico</p>
        </S.DocumentButton>
        <S.Buttons>
          <Button type="button" variant="outlined">
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={() =>
              history.push('/pedido/novo', {
                patient: surgicalOrder?.patient,
                old_solicitation: surgicalOrder?.surgical_order_id
              })
            }
          >
            Criar pedido cirúrgico
          </Button>
        </S.Buttons>
      </S.Wrapper>
      {showSurgicalOrderDoc && (
        <DocumentsAreaModal
          defaultDocuments={order ?? []}
          handleClose={() => setShowSurgicalOrderDoc(false)}
          isReviewOnly
          defaultDocsOnly
        />
      )}
      {showReportsModal && (
        <DocumentsAreaModal
          defaultDocuments={reports ?? []}
          handleClose={() => setShowReportsModal(false)}
          isReviewOnly
          defaultDocsOnly
        />
      )}
      {showMedicalReport && (
        <DocumentsAreaModal
          defaultDocuments={orderMedical ?? []}
          handleClose={() => setShowMedicalReport(false)}
          isReviewOnly
          defaultDocsOnly
        />
      )}
    </Container>
  )
}

export default WithLoading(SolicitationLayout)
