import React from 'react'

import PatientsLayout from 'presentation/hospital/layouts/Patients'
import { LoadPatients } from 'domain/usecases/patient/load-patients'

type Props = {
  loadPatients: LoadPatients
}

export default function Patients({ loadPatients }: Props) {
  return <PatientsLayout loadPatients={loadPatients} />
}
