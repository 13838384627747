import { PainIntensity } from 'common/enum/pain-intensity'

export const getPainIntensity = (painIntensity?: PainIntensity) => {
  switch (painIntensity) {
    case PainIntensity.NO_PAIN:
      return 0
    case PainIntensity.SOFT:
      return 1
    case PainIntensity.MODERATE:
      return 2
    case PainIntensity.INTENSE:
      return 3
    case PainIntensity.STRONG:
      return 4
    case PainIntensity.UNBEARABLE:
      return 5
  }
}
