import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router'

import * as S from './styles'
import Table from 'presentation/shared/components/Table'
import TextField from 'presentation/shared/components/TextField'
import Chip from 'presentation/shared/components/Chip'
import Button from 'presentation/shared/components/Button'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendency-icon.svg'
import { ReactComponent as ResumIcon } from 'presentation/assets/icons/paper-icon.svg'
import { ReactComponent as FinishIcon } from 'presentation/assets/icons/authorize-icon.svg'
import { ReactComponent as RemakeIcon } from 'presentation/assets/icons/invert-icon.svg'
import { ReactComponent as CancelSurgicalOrderIcon } from 'presentation/assets/icons/chat-balloon.svg'
import { ReactComponent as CommentOutlined } from 'presentation/assets/icons/comment-icon.svg'
import {
  surgicalsOrdersStatus,
  getSurgicalOrderStatusEnumByLabel
} from 'presentation/utils/surgical-order-status'
import { Menu } from '@material-ui/core'
import { AddPendencyModal } from 'presentation/hospital/components/Pendency/AddPendencyModal'
import {
  PendencyType,
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import ChangeOrderStatusModal from '../ChangeOrderStatusModal'
import { ServiceOverviewProps } from 'presentation/shared/components/ServiceOverview'
import { getStatusInfo } from 'presentation/utils/service-status'
import UpdateSurgicalOrder from 'presentation/hospital/components/UpdateSurgicalOrder'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { GuidesModal } from 'presentation/hospital/components/GuidesModal'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { Pagination } from 'service/protocols/api/api-client'
import { PaginationModel } from 'domain/entities/pagination-model'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { useAuthorizationContext } from 'presentation/hospital/pages/Authorization/context'
import normalizeText from '../../../../common/utils/getNormalizedText'
import { CancelSurgicalOrderModal } from 'presentation/hospital/components/CancelSurgicalOrderModal'
import { Patient } from 'domain/entities/patient-model'
import { Tooltip } from 'presentation/shared/components/Tooltip'
import RedAlertCircle from 'presentation/assets/icons/alert-circle.svg'
import moment from 'moment-timezone'
import { CommentsModal } from '../CommentsModal'

export type SurgicalOrderList = {
  surgery_request_id?: string
  status?: string
  unit?: string
  room?: string
  date?: string
  patient?: string
  patient_id?: number
  birthDate?: string
  gender?: string
  insurance?: string
  doctor?: string
  doctor_id?: number
  surgery?: string
  pendencies?: SurgicalPendency[]
  isSolicitation?: boolean
  patientEmail?: string
  patientPhone?: string
  doctorEmail?: string
  doctorPhone?: string
  createdAt?: string
  createdBy?: string
  patientHospitalized?: any
  priorities?: string
  surgicalCenter?: string
  isUrgent?: {
    urgent?: boolean
    expectedDate?: string
  }
}

type Props = {
  data: SurgicalOrderList[]
  filterInitialValues?: SurgicalOrderTableFormValues
  filterSubmit?: (
    fields: SurgicalOrderTableFormValues
  ) => Promise<SearchSurgicalOrders.Model | undefined>
  loadHospitals?: LoadHospitals
  loadHealthInsurances?: LoadHealthInsurancePlans
  loadSurgeryCenters?: LoadSurgeryCenter
  reorderSurgicalOrder?: ReorderSurgicalOrder
  loadSurgicalOrder?: LoadSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
}

export default function SurgicalOrderTable({
  data,
  filterInitialValues,
  filterSubmit,
  loadHospitals,
  loadHealthInsurances,
  loadSurgeryCenters,
  reorderSurgicalOrder,
  loadSurgicalOrder,
  loadSurgicalOrderDocument
}: Props) {
  const context = useAuthorizationContext()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [activeOrder, setActiveOrder] = React.useState<any>()
  const [addPendency, setAddPendency] = React.useState<boolean>(false)
  const [showFinishOrder, setShowFinishOrder] = React.useState<boolean>(false)
  const [surgery, setSurgery] = React.useState<ServiceOverviewProps>(
    {} as ServiceOverviewProps
  )
  const [showRemakeModal, setShowRemakeModal] = React.useState<boolean>(false)
  const [showGuidesModal, setShowGuidesModal] = React.useState<boolean>(false)
  const [showCancelSurgicalOrder, setShowCancelSurgicalOrder] =
    React.useState<boolean>(false)
  const [showCommentsModal, setShowCommentsModal] =
    React.useState<boolean>(false)
  const [paginationState, setPaginationState] = React.useState<
    PaginationModel | undefined
  >({
    currentPage: context?.initialPaginationState?.currentPage ?? 1,
    itemsPerPage: context?.initialPaginationState?.itemsPerPage ?? 10,
    totalItems: context?.initialPaginationState?.totalItems ?? 1,
    totalPages: context?.initialPaginationState?.totalPages ?? 1
  })
  const filterRef = useRef<FilterRefProps>(null)

  const history = useHistory()

  useEffect(() => {
    setPaginationState(context?.initialPaginationState)
  }, [context?.initialPaginationState])

  const setPendency = (
    pendencies: SurgicalPendency[],
    val: SurgicalOrderList
  ) => {
    const answeredPendencies = pendencies.filter(
      (pendency) =>
        pendency.answeredAt &&
        pendency.status === SurgicalPendencyStatus.PENDENT
    ).length
    if (!pendencies.length) {
      return undefined
    }

    let color = '#D81D1D'
    if (
      pendencies.length &&
      pendencies.every(
        (pendency) => pendency.status === SurgicalPendencyStatus.RESOLVED
      )
    ) {
      color = '#038E83'
    }
    return (
      <S.PendencyContainer
        color={color}
        onClick={() => {
          setActiveOrder(val)
          setAddPendency(true)
        }}
      >
        {answeredPendencies > 0 && answeredPendencies}
      </S.PendencyContainer>
    )
  }

  const formattedData = React.useMemo(() => {
    return data.map((val) => ({
      ...val,
      menu: (
        <S.DotsIcon
          width={30}
          height={30}
          onClick={(e) => {
            e.stopPropagation()
            handleClick(e, val)
          }}
        />
      ),
      pendencies: setPendency(val.pendencies ?? [], val)
    }))
  }, [data])

  const handleOpenActiveOrderReview = () => {
    if (activeOrder?.isSolicitation && !activeOrder?.isComplete) {
      history.push('/solicitacao/resumo', {
        id: activeOrder.surgery_request_id
      })
    } else {
      history.push('/pedido/resumo', { id: activeOrder.surgery_request_id })
    }
  }

  const handleOpenFinishOrder = () => {
    setShowFinishOrder(true)
  }

  const actions = [
    {
      icon: ResumIcon,
      label:
        activeOrder?.isSolicitation && !activeOrder?.isComplete
          ? 'Resumo da solicitação'
          : 'Resumo do pedido',
      handle: () => {
        handleClose()
        handleOpenActiveOrderReview()
      }
    },
    {
      icon: FinishIcon,
      label: 'Completar cadastro',
      handle: () => {
        handleClose()
        history.push('/pedido/novo', {
          old_solicitation: activeOrder.surgery_request_id,
          startStep: activeOrder?.isComplete ? 2 : 0
        })
      },
      visible: activeOrder?.isSolicitation
    },
    {
      icon: PendencyIcon,
      label: 'Pendência',
      handle: () => {
        handleClose()
        setAddPendency(true)
      }
    },
    // {
    //   icon: FinishIcon,
    //   label: 'Autorizar pedido',
    //   handle: () => {
    //     handleClose()
    //     handleOpenFinishOrder()
    //   },
    //   integratedOnly: true,
    //   visible: activeOrder?.status !== 'Autorizado'
    // },
    {
      icon: ResumIcon,
      label: 'Visualizar guias',
      handle: () => {
        handleClose()
        setShowGuidesModal(true)
      },
      integratedOnly: true
    },
    {
      icon: RemakeIcon,
      label: 'Refazer pedido',
      handle: () => {
        handleClose()
        setShowRemakeModal(true)
      },
      integratedOnly: true
    },
    {
      icon: CancelSurgicalOrderIcon,
      label: 'Cancelar pedido',
      handle: () => {
        handleClose()
        setShowCancelSurgicalOrder(true)
      },
      solicitationOnly: true
    },
    {
      icon: CommentOutlined,
      label: 'Comentários',
      handle: () => {
        handleClose()
        setShowCommentsModal(true)
      },
      integratedOnly: true
    }
  ]

  const handleClick = (event: any, val: any) => {
    setActiveOrder(val)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (activeOrder) {
      const statusInfo = getStatusInfo(
        getSurgicalOrderStatusEnumByLabel(activeOrder.status)
      )
      const activeSurgery = {
        orderId: activeOrder.surgery_request_id,
        title: activeOrder.surgery,
        status: activeOrder.status,
        unit: activeOrder.unit,
        date: activeOrder.createdAt,
        doctorName: activeOrder.doctor,
        patientName: activeOrder.patient,
        color: statusInfo ? statusInfo.color : undefined,
        patientEmail: activeOrder.patientEmail,
        patientPhone: activeOrder.patientPhone,
        doctorEmail: activeOrder.doctorEmail,
        doctorPhone: activeOrder.doctorPhone,
        type: 'Cirurgia'
      }
      setSurgery(activeSurgery)
    }
  }, [activeOrder])

  const filterSurgicalOrder = async (pageNumber: number, pageSize: number) => {
    if (filterRef) {
      const orders = await filterSubmit?.({
        ...filterRef.current?.values,
        pagination: {
          pageNumber: pageNumber,
          pageSize
        }
      })
      setPaginationState(orders?.pageInfo)
    }
  }

  const handleSelectRow = (
    rowData: string[],
    rowMeta: { dataIndex: number; rowIndex: number }
  ) => {
    if (data[0].status === 'Cancelada') {
      history.push('/pedido-cancelado/resumo', {
        order: data[rowMeta.rowIndex]
      })
    }
  }

  return (
    <S.Wrapper>
      <Filter
        initialValues={filterInitialValues}
        filterSubmit={filterSubmit}
        ref={filterRef}
      />
      <Table
        title="Pedidos cirúrgicos"
        columns={table.columns}
        data={formattedData}
        page={paginationState?.currentPage}
        onPaginate={(pageNumber, pageSize) => {
          filterSurgicalOrder(pageNumber, pageSize)
          return Promise.resolve(undefined)
        }}
        count={paginationState?.totalItems}
        rowsPerPage={paginationState?.itemsPerPage}
        onClick={(value, index) => {
          handleSelectRow(value, index)
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {actions &&
          actions
            .filter((action) => {
              if (action?.visible === false) {
                return false
              }
              if (activeOrder?.isSolicitation) {
                return !action.integratedOnly
              }
              if (!activeOrder?.isSolicitation) {
                return !action.solicitationOnly
              }
              return action
            })
            .map((action, index) => (
              <S.StyledItem key={index} onClick={action.handle}>
                <action.icon height={30} width={30} />
                <span>{action.label}</span>
              </S.StyledItem>
            ))}
      </Menu>
      {addPendency && (
        <AddPendencyModal
          surgicalOrder={activeOrder}
          close={() => setAddPendency(false)}
        />
      )}
      {showCancelSurgicalOrder && (
        <CancelSurgicalOrderModal
          close={() => setShowCancelSurgicalOrder(false)}
          patient={{ name: activeOrder?.patient } as Patient}
          surgicalOrder={activeOrder}
          refreshTable={() =>
            filterSurgicalOrder(
              paginationState?.currentPage || 1,
              paginationState?.itemsPerPage || 10
            )
          }
        />
      )}
      {showFinishOrder && (
        <ChangeOrderStatusModal
          surgery={surgery}
          surgicalOrderId={activeOrder.surgery_request_id}
          close={() => setShowFinishOrder(false)}
          onSuccess={() =>
            filterSurgicalOrder(
              paginationState?.currentPage || 1,
              paginationState?.itemsPerPage || 10
            )
          }
        />
      )}
      {showRemakeModal && (
        <UpdateSurgicalOrder
          loadHospitals={loadHospitals}
          surgicalOrderId={Number(activeOrder.surgery_request_id) ?? 0}
          loadHealthInsurance={loadHealthInsurances}
          loadSurgeryCenters={loadSurgeryCenters}
          close={() => setShowRemakeModal(false)}
          reorderSurgicalOrder={reorderSurgicalOrder}
          loadSurgicalOrder={loadSurgicalOrder}
        />
      )}
      {showGuidesModal && (
        <GuidesModal
          surgicalOrderId={Number(activeOrder.surgery_request_id) ?? 0}
          close={() => setShowGuidesModal(false)}
          loadSurgicalOrder={loadSurgicalOrder}
          loadSurgicalOrderDocument={loadSurgicalOrderDocument}
        />
      )}
      {showCommentsModal && (
        <CommentsModal
          surgicalOrderId={Number(activeOrder.surgery_request_id) ?? 0}
          close={() => setShowCommentsModal(false)}
          loadSurgicalOrder={loadSurgicalOrder}
        />
      )}
    </S.Wrapper>
  )
}

type FilterProps = {
  initialValues?: SurgicalOrderTableFormValues
  filterSubmit?: (fields: SurgicalOrderTableFormValues) => void
}

type FilterRefProps = {
  values: SurgicalOrderTableFormValues
}

const Filter = forwardRef(function FilterComponent(
  { filterSubmit, initialValues }: FilterProps,
  ref
) {
  const formik = useFormik({
    initialValues: initialValues ?? {},
    onSubmit: async (values) => {
      filterSubmit && filterSubmit(values)
    }
  })

  useImperativeHandle(ref, () => ({
    values: formik.values
  }))

  return (
    <S.FiltersContainer
      onSubmit={formik.handleSubmit}
      initialSurgeryStatus={initialValues?.surgeryStatus}
    >
      <S.FiltersInput>
        <TextField
          name="patientName"
          id="patientName"
          bgColor="mainBg"
          placeholder="Busca por pacientes"
          iconPosition="left"
          icon={<SearchIcon />}
          iconMargin="50px"
          iconLocale="inside"
          onChange={formik.handleChange}
          value={formik.values.patientName || ''}
        />
        <S.FiltersInputGrid>
          <TextField
            name="specialty"
            id="specialty"
            bgColor="mainBg"
            placeholder="Especialidade"
            iconPosition="left"
            iconMargin="50px"
            icon={<SearchIcon />}
            iconLocale="inside"
            onChange={formik.handleChange}
            value={formik.values.specialty || ''}
          />
          <TextField
            name="surgical_order_id"
            id="surgical_order_id"
            bgColor="mainBg"
            placeholder="Número do Aviso"
            iconPosition="left"
            iconMargin="50px"
            icon={<SearchIcon />}
            iconLocale="inside"
            onChange={formik.handleChange}
            value={formik.values.surgical_order_id || ''}
            type="number"
          />
          <TextField
            name="healthInsuranceName"
            id="healthInsuranceName"
            bgColor="mainBg"
            placeholder="Convênio"
            iconPosition="left"
            iconMargin="50px"
            icon={<SearchIcon />}
            iconLocale="inside"
            onChange={formik.handleChange}
            value={formik.values.healthInsuranceName || ''}
          />
        </S.FiltersInputGrid>
      </S.FiltersInput>
      {/* {!initialValues?.surgeryStatus && (
        <S.FiltersRadio>
          {chips.map((status) => (
            <Chip
              key={status.label}
              label={status.label}
              name="surgeryStatus"
              value={status.value}
              onCheck={formik.handleChange('surgeryStatus')}
              checked={formik.values.surgeryStatus === status.value}
            />
          ))}
        </S.FiltersRadio>
      )} */}
      <S.FiltersButton initialSurgeryStatus={initialValues?.surgeryStatus}>
        <Button
          fullWidth
          type="button"
          onClick={() =>
            formik.resetForm({
              values: {
                surgeryStatus: initialValues?.surgeryStatus
                  ? initialValues?.surgeryStatus
                  : undefined
              }
            })
          }
        >
          Limpar
        </Button>
        <Button fullWidth type="submit">
          Buscar
        </Button>
      </S.FiltersButton>
    </S.FiltersContainer>
  )
})

const chips = surgicalsOrdersStatus

const table = {
  columns: [
    {
      name: 'menu',
      label: ' ',
      options: { filter: false, sort: false }
    },
    {
      name: 'isSolicitation',
      label: 'c ',
      options: {
        display: false
      }
    },
    {
      name: 'isUrgent',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: {
          urgent: boolean
          expectedDate: string
        }) => {
          if (!value.urgent) return
          const date = moment(value.expectedDate).utc(true).format('DD/MM/YYYY')
          return (
            <Tooltip text={`Pedido com Urgência - ${date}`} type={'alert'}>
              <img src={RedAlertCircle} alt={'Icone de alerta vermelho'} />
            </Tooltip>
          )
        }
      }
    },
    {
      name: 'pendencies',
      label: ' ',
      options: {
        filter: false,
        sort: false
      }
    },

    {
      name: 'surgery_request_id',
      label: 'N aviso',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string, rowInfo: any) => {
          if (rowInfo?.rowData?.[1]) return ''
          return value
        }
      }
    },
    {
      name: 'priorities',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string) => {
          // eslint-disable-next-line prefer-const
          let [surgicalCenter, hospitalized] = value.split('---')
          surgicalCenter = normalizeText(surgicalCenter)
          return (
            <div style={{ display: 'flex' }}>
              {(surgicalCenter &&
                surgicalCenter.toLowerCase().includes('hemodinamica')) ||
              surgicalCenter.toLowerCase().includes('hibrida') ? (
                <S.TecCell type={surgicalCenter.toLowerCase()}>HEM</S.TecCell>
              ) : null}
              {
                <S.TecCell type={hospitalized === 'true' ? 'INT' : ''}>
                  {hospitalized === 'true' ? 'INT' : undefined}
                </S.TecCell>
              }
            </div>
          )
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        //eslint-disable-next-line
        customBodyRender: (value: string) => (
          <S.StatusContainer>
            <p>{value}</p>
          </S.StatusContainer>
        )
      }
    },
    {
      name: 'unit',
      label: 'Unidade',
      options: { filter: true, sort: false }
    },
    {
      name: 'createdAt',
      label: 'Dt. de criação',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'patient',
      label: 'Paciente',
      options: { filter: false, sort: false }
    },
    {
      name: 'insurance',
      label: 'Convênio',
      options: { filter: true, sort: false }
    },
    {
      name: 'surgery',
      label: 'Cirurgia',
      options: { filter: true, sort: false }
    },
    {
      name: 'doctor',
      label: 'Médico',
      options: { filter: true, sort: false }
    },
    {
      name: 'createdBy',
      label: 'Responsável',
      options: {
        filter: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string) => {
          const [name, role] = value.split('*')
          return (
            <Tooltip text={name?.trim()}>
              <p>{role?.trim()}</p>
            </Tooltip>
          )
        }
      }
    }
  ]
}

export type SurgicalOrderTableFormValues = {
  patientName?: string
  specialty?: string
  surgical_order_id?: number
  healthInsuranceName?: string
  surgeryStatus?: SurgicalOrderStatus
  startDate?: string
  endDate?: string
  pagination?: Pagination
  pendencyType?: PendencyType
}
