import styled from 'styled-components'

export const Wrapper = styled.form`
  height: 90vh;
  width: 720px;
  max-width: 80vw;
  padding: 3rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const CheckboxLayout = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;

  div + div {
    margin-top: 32px;
  }

  input {
    width: 24px;
    height: 24px;

    &:before {
      width: 6px;
      height: 13px;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
    }
  }
`

export const Buttons = styled.div`
  margin-top: 0.5rem;
  margin-left: auto;

  button {
    width: 200px;

    &:first-child {
      margin-right: 20px;
    }
  }
`
export const CommentsWrapper = styled.div`
  i {
    margin-top: 1rem;
    display: block;
    word-wrap: break-word;
  }
  margin-top: 1rem;
  overflow-y: auto;
  /* -moz-box-shadow: inset 0 -10px 10px -10px #00000055;
  -webkit-box-shadow: inset 0 -10px 10px -10px #00000055;
  box-shadow: inset 0 -10px 10px -10px #00000055; */
  height: 100%;
`
export const Comment = styled.div`
  strong {
    margin-left: 1rem;
  }
  color: #4a4a4a;
  width: 100%;
`
export const Title = styled.div`
  font-weight: 600;
  color: #595959;
`
export const InputWrapper = styled.div``
