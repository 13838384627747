import { Hospital } from 'domain/entities/hospital-model'
import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import AddDoctorLayout from 'presentation/hospital/layouts/AddDoctor'
import React, { useEffect, useState } from 'react'

type AddDoctorProps = {
  addDoctor: AddDoctor
  loadHospitals: LoadHospitals
}

export default function HospitalAddDoctor({
  addDoctor,
  loadHospitals
}: AddDoctorProps) {
  const [hospitals, setHospitals] = useState([] as Hospital[])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const data = await loadHospitals.load(['hospital_id', 'name', 'nickname'])
      setHospitals(data)
    }
    loadData()
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <></>
  } else {
    return <AddDoctorLayout hospitals={hospitals} addDoctor={addDoctor} />
  }
}
