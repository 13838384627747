import styled from 'styled-components'
import { generateMedia } from 'styled-media-query'

const customMedia = generateMedia({
  xhuge: '1600px'
})

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 100%;
`

export const CrmContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${customMedia.greaterThan('xhuge')`
    align-content: flex-start;
  `}
`

export const AddCrm = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`

export const CrmList = styled.div`
  padding: 20px 0;
`

export const DeleteButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`

export const Content = styled.div`
  flex: 1;
  display: grid;
  align-content: center;
  gap: 0px 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 0.6fr);
  padding-top: 20px;
  ${customMedia.greaterThan('xhuge')`
    align-content: flex-start;
    padding-top: 40px;
  `}
`
