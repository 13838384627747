import { AddCollaborator } from 'domain/usecases/collaborator/add-collaborator'
import { CollaboratorRepository } from 'repository/interfaces/collaborator-repository'

export class RemoteAddCollaborator implements AddCollaborator {
  constructor(
    private readonly collaboratorRepository: CollaboratorRepository
  ) {}

  add(params: AddCollaborator.Params): Promise<AddCollaborator.Model> {
    return this.collaboratorRepository.addCollaborator(params)
  }
}

export type AddCollaboratorModel = AddCollaborator.Model
