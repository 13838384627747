import React from 'react'

import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import InternationCard, {
  InternationCardProps
} from 'presentation/shared/components/InternationCard'
import * as S from './styles'
import { PainAlert } from 'domain/entities/pain-alert-model'
import PainAlertModal from 'presentation/hospital/components/PainAlertModal'
import { useState } from 'react'
import Error from 'presentation/shared/components/Error'

type Props = {
  painAlerts: InternationCardProps[]
  painAlertProp?: PainAlert
}

export default function PainAlertsLayout({ painAlerts, painAlertProp }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedPainAlert, setSelectedPainAlert] = useState<PainAlert>(
    {} as PainAlert
  )

  const selectPainAlert = (alert: PainAlert) => {
    setSelectedPainAlert(alert)
    setShowModal(true)
  }

  return (
    <Container>
      <S.Wrapper>
        <S.PainAlertWrapper>
          <Heading size="large" style={{ color: '#DA1414' }}>
            Alertas de dor dos pacientes
          </Heading>
          <span>{('0' + painAlerts.length).slice(-2)}</span>
          <div>
            {painAlerts.length > 0 ? (
              painAlerts.map((painAlert, index) => (
                <InternationCard
                  key={index}
                  {...painAlert}
                  painAlert={painAlertProp}
                  onClick={() =>
                    selectPainAlert(painAlert.painAlert ?? ({} as PainAlert))
                  }
                />
              ))
            ) : (
              <Error
                color="lightRed"
                size="large"
                style={{ margin: 'auto', display: 'block' }}
              >
                Nenhum alerta de dor cadastrado
              </Error>
            )}
          </div>
        </S.PainAlertWrapper>
      </S.Wrapper>
      {showModal && (
        <PainAlertModal
          painAlert={selectedPainAlert}
          close={() => setShowModal(false)}
        />
      )}
    </Container>
  )
}
