import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteReorderSurgicalOrder implements ReorderSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  update(
    params: ReorderSurgicalOrder.Params
  ): Promise<ReorderSurgicalOrder.Model> {
    return this.surgicalOrderRepository.reorderSurgicalOrder(params)
  }
}

export type ReorderSurgicalOrderModel = ReorderSurgicalOrder.Model
