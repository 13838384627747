import React, { useEffect } from 'react'
import makeHospitalAddCollaborator from 'main/factories/pages/hospital/add-collaborator/hospital-add-collaborator-factory'
import makeHospitalAddSurgicalOrder from 'main/factories/pages/hospital/add-surgical-order/hospital-add-surgical-order-factory'
import makeAddDoctor from 'main/factories/pages/hospital/hospital-add-doctor-factory'
import makeHospitalLogin from 'main/factories/pages/hospital/hospital-login-factory'
import makeHospitalPasswordRecovery from 'main/factories/pages/hospital/hospital-password-recovery-factory'
import { useStores } from 'presentation/hooks/use-stores'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  withRouter
} from 'react-router-dom'
import makeHospitalPainAlerts from 'main/factories/pages/hospital/pain-alerts/hospital-pain-alerts-factory'
import makeHospitalDoctors from 'main/factories/pages/hospital/doctors/hospital-doctors-factory'
import makeHospitalSignup from 'main/factories/pages/hospital/signup/hospital-signup-factory'
import makeHospitalAuthorization from 'main/factories/pages/hospital/authorization/hospital-authorization-factory'
import makeHospitalOrderDetails from 'main/factories/pages/hospital/order-details/hospital-order-details-factory'
import PatientInfo from 'presentation/secretary/pages/PatientInfo'
import ProcedureInfo from 'presentation/secretary/pages/ProcedureInfo'
import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import { Profile } from 'common/enum/profile'
import makeHospitalPatients from 'main/factories/pages/hospital/patients/hospital-patients-factory'
import makeHospitalOrderAbstract from 'main/factories/pages/hospital/order-abstract/hospital-order-abstract-factory'
import { AddPatient } from 'presentation/hospital/pages/AddPatient'
import CanceledOrderInfo from 'presentation/hospital/pages/CanceledOrderInfo'
import HospitalProfile from 'presentation/hospital/pages/Profile'
import makeHospitalHome from 'main/factories/pages/hospital/hospital-home-factory'
import Solicitation from 'presentation/hospital/pages/Solicitation'
import makeHospitalCollaboratorsFactory from 'main/factories/pages/hospital/collaborators/hospital-collaborators-factory'
import makeHospitalFavoritesFactory from 'main/factories/pages/hospital/favorites/hospital-favorites-factory'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

export default function HospitalRouter() {
  const stores = useStores()
  const currentUser = stores.currentAccount
  const currentHospital = stores.currentHospital
  const surgicalstatus = stores.surgicalstatus

  useEffect(() => {
    async function loadData() {
      if (!window.location.pathname.match('login')) {
        await surgicalstatus.load()
        await currentHospital.load()
      }
    }
    loadData()
  }, [])

  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === 'development' ? '/hospital' : ''}
    >
      <ScrollToTop />
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            return (
              <>
                <Redirect
                  to={
                    currentUser.getCurrentAccount()?.accessToken
                      ? '/home'
                      : '/login'
                  }
                />
              </>
            )
          }}
        />
        <Route path="/login" exact component={makeHospitalLogin} />
        <Route path="/cadastro/:hash" exact component={makeHospitalSignup} />
        <PrivateRoute
          path="/home"
          exact
          component={makeHospitalHome}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE,
            Profile.RECEPTIONIST
          ]}
        />
        <PrivateRoute
          path="/medicos/save"
          exact
          component={makeAddDoctor}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE,
            Profile.RECEPTIONIST
          ]}
        />
        <PrivateRoute
          path="/colaboradores"
          exact
          component={makeHospitalCollaboratorsFactory}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/colaboradores/save"
          exact
          component={makeHospitalAddCollaborator}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <Route
          path="/recuperar-senha/:type"
          exact
          component={makeHospitalPasswordRecovery}
        />
        <PrivateRoute
          path="/alertas/"
          exact
          component={makeHospitalPainAlerts}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pedido/novo"
          exact
          component={makeHospitalAddSurgicalOrder}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/medicos"
          exact
          component={makeHospitalDoctors}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pedidos/:status"
          exact
          component={makeHospitalAuthorization}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pedido"
          exact
          component={makeHospitalOrderDetails}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/paciente"
          exact
          component={PatientInfo}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/paciente/save"
          exact
          component={AddPatient}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pacientes"
          exact
          component={makeHospitalPatients}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITAL,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/procedimento"
          exact
          component={ProcedureInfo}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pedido/resumo"
          exact
          component={makeHospitalOrderAbstract}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/pedido-cancelado/resumo"
          exact
          component={CanceledOrderInfo}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/solicitacao/resumo"
          exact
          component={Solicitation}
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
        />
        <PrivateRoute
          path="/perfil/:id"
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
          exact
          component={HospitalProfile}
        />
        <PrivateRoute
          path="/favoritos"
          profile={[
            Profile.CRMO,
            Profile.RECEPTIONIST,
            Profile.HOSPITALIZATION,
            Profile.BUDGET,
            Profile.OPME,
            Profile.GUIDE_CENTER,
            Profile.PRE_SCHEDULING,
            Profile.SCHEDULING,
            Profile.TRIAGE
          ]}
          exact
          component={makeHospitalFavoritesFactory}
        />
      </Switch>
    </BrowserRouter>
  )
}
