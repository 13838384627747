import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import HospitalHomeLayout from 'presentation/hospital/layouts/Home'
import { useStores } from 'presentation/hooks/use-stores'
import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'
import { CountScheduledAndUnscheduledSurgicalOrders } from 'domain/usecases/surgical-order/scheduled-and-unscheduled-counter'

type Props = {
  countSurgicalOrders: CountSurgicalOrdersByStatus
  countScheduledAndUnscheduledSurgicalOrders: CountScheduledAndUnscheduledSurgicalOrders
}

const HospitalHome = observer(
  ({
    countSurgicalOrders,
    countScheduledAndUnscheduledSurgicalOrders
  }: Props) => {
    const [totalizers, setTotalizers] =
      useState<CountSurgicalOrdersByStatus.Model>()
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [scheduledAndUnscheduled, setScheduledAndUnscheduled] =
      useState<CountScheduledAndUnscheduledSurgicalOrders.Model>()

    const hospital = useStores().currentHospital?.actualHospital

    const currentFilters = useStores().currentFilters
    const crmoFilters = currentFilters.crmoFilter

    const count = async (
      startDate: string,
      endDate: string,
      label?: string
    ) => {
      try {
        currentFilters.setCrmoFilter({
          end: endDate,
          start: startDate,
          label
        })
        setShowLoadingModal(true)
        setTotalizers(
          await countSurgicalOrders.count({
            startDate,
            endDate,
            fields: [
              'crmo',
              'total',
              'authorized',
              'orderedByPatient',
              'cancelled',
              'healthInsurance',
              'opmeQuotation',
              'preSchedule',
              'realized',
              'review',
              'requestedCancellation',
              'fastpass',
              'checkin',
              'patientInSurgery',
              'patientRecovery',
              'patientDischarge',
              'serviceEvaluation',
              'assistancePendencies',
              'authorizationPendencies'
            ]
          })
        )
        setScheduledAndUnscheduled(
          await countScheduledAndUnscheduledSurgicalOrders.count({
            startDate,
            endDate,
            fields: [
              `scheduled {
                approved
                disapproved
                total
              }`,
              `unscheduled {
                approved
                disapproved
                total
              }`,
              'total'
            ]
          })
        )
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar carregar os totalizadores')
      } finally {
        setShowLoadingModal(false)
      }
    }

    useEffect(() => {
      if (crmoFilters?.end && crmoFilters?.start) {
        count(crmoFilters?.start, crmoFilters?.end, crmoFilters?.label)
      } else {
        count(
          moment().toISOString(),
          moment().add('10', 'minutes').toISOString()
        )
      }
    }, [])

    return (
      <>
        <HospitalHomeLayout
          hospitalName={hospital?.nickname}
          changeFilter={count}
          data={totalizers}
          scheduledAndUnscheduledCounter={scheduledAndUnscheduled}
          crmoDefaultFilters={
            crmoFilters || {
              end: moment().add('10', 'minutes').toISOString(),
              start: moment().toISOString()
            }
          }
        />
        <LoadingModal show={showLoadingModal} />
      </>
    )
  }
)

export default HospitalHome
