import React from 'react'

import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { useFormik } from 'formik'
import { Patient } from 'domain/entities/patient-model'
import { LoadPatients } from 'domain/usecases/patient/load-patients'
import { PaginationModel } from 'domain/entities/pagination-model'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import moment from 'moment-timezone'
import { formatPhone } from 'presentation/utils/masks'

type Props = {
  loadPatients: LoadPatients
  hasHeading?: boolean
} & WithLoadingProps

type PaginationState = {
  count: number
  itemsPerPage: number
  page: number
}

function PatientsTable({
  hasHeading = true,
  loadPatients,
  setIsLoading
}: Props) {
  const [patientsData, setPatientsData] = React.useState<Patient[]>(
    [] as Patient[]
  )

  const [paginationState, setPaginationState] = React.useState<PaginationState>(
    {
      count: 10,
      itemsPerPage: 10,
      page: 1
    }
  )

  async function loadPatientsPaginated(
    pageNumber: number,
    pageSize: number
  ): Promise<PaginationModel> {
    const pagination = {
      pageNumber,
      pageSize
    }

    const response = await findPatients({
      pagination,
      name: formik.values.name
    })

    setPaginationState({
      count: response?.pageInfo.totalItems || 10,
      itemsPerPage: response?.pageInfo.itemsPerPage || 10,
      page: response?.pageInfo.currentPage || 1
    })

    return response ? response.pageInfo : ({} as PaginationModel)
  }

  const findPatients = async ({ name, pagination }: LoadPatients.Params) => {
    try {
      setIsLoading(true)
      const response = await loadPatients.load({
        name: name || '',
        pagination: {
          pageNumber: pagination?.pageNumber || 1,
          pageSize: pagination?.pageSize || 10
        },
        params: [
          'cpf',
          'email',
          'phone',
          'name',
          'gender',
          'patient_id',
          'birthday',
          'healthInsurance {healthInsuranceCode, healthInsuranceName}'
        ]
      })

      const { data, pageInfo } = response

      setPatientsData(data)

      if (pageInfo) {
        setPaginationState({
          count: response.pageInfo.totalItems || 10,
          itemsPerPage: response.pageInfo.itemsPerPage || 10,
          page: response.pageInfo.currentPage || 1
        })
      }

      return response
    } catch (e: any) {
      toast.error(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const findAllPatients = (): any => findPatients({} as LoadPatients.Params)

  React.useEffect(() => {
    ;(async () => await findAllPatients())()
  }, [])

  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: ({ name }) => {
      name
        ? findPatients({ name: name } as LoadPatients.Params)
        : findAllPatients()
    }
  })

  return (
    <S.Wrapper>
      {hasHeading && (
        <>
          <Heading
            as="h1"
            size="huge"
            color="primary"
            style={{ marginBottom: '1.5rem' }}
          >
            Pacientes
          </Heading>
          <S.SearchWrapper onSubmit={formik.handleSubmit}>
            <TextField
              onResetTableValue={findAllPatients}
              iconPosition="left"
              icon={<SearchIcon />}
              iconLocale="inside"
              bgColor="mainBg"
              placeholder="Digite o nome do paciente"
              onInputChange={formik.handleChange('name')}
            />
            <Button fullWidth type="submit">
              Buscar
            </Button>
          </S.SearchWrapper>
        </>
      )}
      <Table
        pagination
        count={paginationState.count}
        onPaginate={loadPatientsPaginated}
        rowsPerPage={paginationState.itemsPerPage}
        page={paginationState.page}
        columns={columns}
        data={patientsData?.map((patient) => ({
          cpf: patient.cpf,
          cod: patient.patient_id,
          name: patient.name,
          birthday: moment(patient.birthday).utc(false).format('DD/MM/YYYY'),
          gender: patient.gender,
          insurance:
            patient.healthInsurance?.healthInsuranceName || 'Não cadastrado',
          email: patient.email,
          phone: patient?.phone ? formatPhone(patient.phone) : 'Não informado'
        }))}
      />
    </S.Wrapper>
  )
}

export default WithLoading(PatientsTable)

const columns = [
  {
    name: 'cpf',
    label: 'CPF',
    options: {
      sort: false
    }
  },
  {
    name: 'cod',
    label: 'Cod. paciente',
    options: {
      sort: false
    }
  },
  {
    name: 'name',
    label: 'Paciente',
    options: {
      sort: false
    }
  },
  {
    name: 'birthday',
    label: 'Dt. de nascimento',
    options: {
      sort: false
    }
  },
  {
    name: 'gender',
    label: 'Sexo',
    options: {
      sort: false
    }
  },
  {
    name: 'insurance',
    label: 'Convênio',
    options: {
      sort: false
    }
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      sort: false
    }
  },
  {
    name: 'phone',
    label: 'Telefone',
    options: {
      sort: false
    }
  }
]
