import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUpdateSurgicalOrderRegenerateGuides
  implements UpdateSurgicalOrderRegenerateGuides
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  update(
    params: UpdateSurgicalOrderRegenerateGuides.Params
  ): Promise<UpdateSurgicalOrderRegenerateGuides.Model> {
    return this.surgicalOrderRepository.updateSurgicalOrderRegenerateGuides(
      params
    )
  }
}
