import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import HospitalSignup from 'presentation/hospital/pages/Signup'
import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'
import { RemoteFinishCollaboratorSignup } from 'service/usecases/finish-collaborator-signup/remote-finish-collaborator-signup'
import { RemoteLoadUserByInviteToken } from 'service/usecases/load-user-by-invite-token/remote-load-user-by-invite-token'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'

export default function makeHospitalSignup() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const collaboratorRepository = new CollaboratorRepository(apiRepository)
  const authenticationRepository = new AuthenticationRepository(apiRepository)

  const finishDoctorSignup = new RemoteFinishCollaboratorSignup(
    collaboratorRepository
  )
  const loadUserByInviteToken = new RemoteLoadUserByInviteToken(
    authenticationRepository
  )
  return (
    <HospitalSignup
      finishHospitalSignup={finishDoctorSignup}
      loadUserByInviteToken={loadUserByInviteToken}
    />
  )
}
