import React from 'react'
import * as S from './styles'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'

type Props = {
  surgicalOrder: SurgicalOrderList
}

export const PendencyCard = ({ surgicalOrder }: Props) => {
  return (
    <S.SurgicalOrderCard>
      <h4 style={{ marginBottom: '10px', fontWeight: 700 }}>
        {surgicalOrder?.patient}
      </h4>
      <span style={{ fontSize: '18px', color: '#00CFAA' }}>
        {surgicalOrder?.surgery}
      </span>
      <S.SurgicalOrderPersons>
        <div>
          <h4>Médico responsável</h4>
          <span>{surgicalOrder?.doctor}</span>
        </div>
        <div style={{ overflow: 'hidden auto' }}>
          <h4>Secretária</h4>
          <span></span>
        </div>
      </S.SurgicalOrderPersons>
    </S.SurgicalOrderCard>
  )
}
