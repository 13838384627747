import React, { useState } from 'react'
import Modal from 'presentation/hospital/components/Modal'
import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import AdvancedButton from 'presentation/shared/components/AdvancedButton'
import {
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import TextArea from 'presentation/shared/components/TextArea'
import SelectField from 'presentation/shared/components/SelectField'
import { useFormik } from 'formik'
import { PendencyDocumentsModal } from 'presentation/hospital/components/Pendency/PendencyDocumentsModal'
import { useAuthorizationContext } from 'presentation/hospital/pages/Authorization/context'
import Checkbox from 'presentation/shared/components/Checkbox'
import SupportText from 'presentation/shared/components/SupportText'
import Error from 'presentation/shared/components/Error'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import SuccessModal from 'presentation/shared/components/Modal'
import { toUtc } from 'common/utils/toUtc'

export type Props = {
  pendency: SurgicalPendency
  surgicalOrder: SurgicalOrderList
  close: () => void
} & WithLoadingProps

export const FinishPendencyModal = WithLoading(
  ({ surgicalOrder, pendency, close, setIsLoading }: Props) => {
    const [mode, setMode] = useState<'form' | 'documents'>('form')
    const [document, setDocument] = useState<number[]>([])
    const [showImpedimentModal, setShowImpedimentModal] =
      useState<boolean>(false)
    const [errors, setErrors] = useState<string[]>([])
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

    const context = useAuthorizationContext()

    const formik = useFormik({
      initialValues: {
        pendencyResolved: pendency.status === SurgicalPendencyStatus.RESOLVED,
        observation: '',
        notify: {
          patient: true,
          doctor: true,
          secretary: true
        },
        notifyBy: {
          email: true,
          sms: true,
          platform: true
        }
      },
      onSubmit: async (values) => {
        let newPendency: SurgicalPendency | undefined
        try {
          setIsLoading(true)
          if (values.pendencyResolved) {
            newPendency = await context?.finishSurgicalPendency.answer({
              surgical_pendency_id: pendency.surgical_pendency_id,
              approvedDocuments: document
            })
            if (newPendency) {
              context?.addPendency(
                newPendency?.surgical_order_id?.toString() ?? '',
                newPendency
              )
            }
          } else {
            newPendency = await context?.finishSurgicalPendency.answer({
              surgical_pendency_id: pendency.surgical_pendency_id,
              approvedDocuments: document,
              newPendency: {
                notify: values.notify,
                notifyBy: values.notifyBy,
                observation: values.observation
              }
            })
            if (newPendency) {
              const refreshedPendencies = await context?.addPendency(
                newPendency?.surgical_order_id?.toString() ?? '',
                newPendency,
                true
              )
              const newOrder = refreshedPendencies?.data.find(
                (order) =>
                  order.surgical_order_id === newPendency?.surgical_order_id
              )
              if (newOrder) {
                surgicalOrder.pendencies = newOrder.pendencies
              }
            }
          }
          if (newPendency) {
            setErrors(newPendency?.errors ?? [])
            setShowSuccessModal(true)
          }
        } catch (e) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
    })
    const isValid = () => {
      if (!formik.values.pendencyResolved) {
        return true
      }

      return (
        formik.values.pendencyResolved &&
        document.length === pendency.documents?.length
      )
    }

    const handleSaveButtonClick = () => {
      if (formik.values.pendencyResolved) {
        formik.submitForm()
      } else {
        setShowImpedimentModal(true)
      }
    }

    const existErrors = () => {
      return errors.length > 0
    }

    return (
      <Modal noBackground>
        <S.Wrapper>
          <Heading as="h1" color="primary">
            {surgicalOrder.patient}
          </Heading>
          <S.PendencyOverview>
            <div>
              <h6>Responsável:</h6>
              <span>{pendency.requestedBy?.name}</span>
            </div>
            <div>
              <h6>Data de criação:</h6>
              <span>
                {dateFormatter.format(toUtc(new Date(pendency.createdAt)))}
              </span>
            </div>
          </S.PendencyOverview>
          <Heading color="primary" style={{ margin: '6px 0' }}>
            {surgicalOrder.surgery}
          </Heading>
          <S.PendencyOverview>
            <div>
              <h6>Médico:</h6>
              <span>{surgicalOrder.doctor}</span>
            </div>
          </S.PendencyOverview>
          <S.PendencyOverview>
            <div>
              <h6>Secretária:</h6>
              <span></span>
            </div>
          </S.PendencyOverview>
          <S.Content>
            <S.TextAreaWrapper>
              <TextArea
                label="Pendência"
                disabled
                rows={3}
                value={pendency.observation}
              />
              <TextArea
                label="Retorno da pendência"
                disabled
                rows={3}
                value={pendency.answer}
              />
            </S.TextAreaWrapper>
            <AdvancedButton
              onClick={() => setMode('documents')}
              showAttachmentIcon={pendency.documents?.length > 0}
            />
            <S.PendencyStatus>
              <label>Status da pendência:</label>
              <SelectField
                name="pendencyResolved"
                id="pendencyResolved"
                items={[
                  { label: 'Não resolvida', value: 0 },
                  { label: 'Resolvida', value: 1 }
                ]}
                value={formik.values.pendencyResolved ? 1 : 0}
                onChange={(e) =>
                  formik.setFieldValue(
                    'pendencyResolved',
                    e.target.value === '1'
                  )
                }
              />
            </S.PendencyStatus>
            {!isValid() && (
              <Error
                color="red"
                size="medium"
                style={{
                  textAlign: 'center',
                  display: 'block',
                  paddingTop: '5px'
                }}
              >
                Valide os documentos para continuar
              </Error>
            )}
          </S.Content>
          <S.Buttons>
            <Button
              variant="outlined"
              type="button"
              onClick={mode === 'form' ? close : () => setMode('form')}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              disabled={formik.isSubmitting || !isValid()}
              onClick={handleSaveButtonClick}
            >
              Salvar
            </Button>
          </S.Buttons>
        </S.Wrapper>
        {mode === 'documents' && (
          <PendencyDocumentsModal
            pendency={pendency}
            surgicalOrder={surgicalOrder}
            close={() => setMode('form')}
            downloadDocument={context?.loadSurgicalPendencyDocument}
            saveDocuments={setDocument}
            defaultDocuments={document}
          />
        )}
        {showImpedimentModal && (
          <Modal>
            <S.Wrapper
              style={{ height: '550px', padding: '20px 40px', width: '540px' }}
            >
              <Heading as="h1" color="primary">
                Pendência não resolvida
              </Heading>
              <SupportText color="primary">Notificar via:</SupportText>
              <S.CheckboxWrapper>
                <Checkbox
                  label="E-mail"
                  name="notifyBy.email"
                  checked={formik.values.notifyBy.email}
                  onChange={formik.handleChange}
                />
                <Checkbox
                  label="SMS"
                  name="notifyBy.sms"
                  checked={formik.values.notifyBy.sms}
                  onChange={formik.handleChange}
                />
                <Checkbox
                  label="Plataforma"
                  name="notifyBy.platform"
                  checked={formik.values.notifyBy.platform}
                  onChange={formik.handleChange}
                />
              </S.CheckboxWrapper>
              <SupportText color="primary">
                Enviar notificação para:
              </SupportText>
              <S.CheckboxWrapper>
                <Checkbox
                  label="Médico"
                  name="notify.doctor"
                  checked={formik.values.notify.doctor}
                  onChange={formik.handleChange}
                />
                <Checkbox
                  label="Paciente"
                  name="notify.patient"
                  checked={formik.values.notify.patient}
                  onChange={formik.handleChange}
                />
                <Checkbox
                  label="Secretária"
                  name="notify.secretary"
                  checked={formik.values.notify.secretary}
                  onChange={formik.handleChange}
                />
              </S.CheckboxWrapper>
              <TextArea
                label="Descrição da nova pendência:"
                name="observation"
                rows={7}
                onChange={formik.handleChange}
              />
              <S.Buttons style={{ marginTop: 'auto' }}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => setShowImpedimentModal(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="button"
                  disabled={formik.isSubmitting || !formik.values.observation}
                  onClick={formik.submitForm}
                >
                  Salvar
                </Button>
              </S.Buttons>
            </S.Wrapper>
          </Modal>
        )}
        <SuccessModal
          title="Pendência respondida com sucesso!"
          type={existErrors() ? 'alert' : 'check'}
          description={
            existErrors() ? (
              <div>
                Houve erro ao incluir alguns documentos no MV:
                <strong>
                  {errors.map((error) => (
                    <>
                      <br></br>
                      {error}
                    </>
                  ))}
                </strong>
                <br></br>
                Procure um administrador.
              </div>
            ) : (
              ''
            )
          }
          show={showSuccessModal}
          close={close}
          preventAutomateClose={existErrors()}
          action={close}
          actionLabel="Fechar"
        />
      </Modal>
    )
  }
)

const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
  timeStyle: 'short',
  dateStyle: 'short'
})
