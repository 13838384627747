import styled from 'styled-components'

export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  button {
    justify-self: flex-end;
    align-self: flex-end;
    margin-top: auto;
  }
`

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;

  input {
    max-width: 111px;
    margin: 0;
    text-align: center;
  }

  div {
    display: flex;

    & + div {
      margin-left: 10%;
    }
  }

  & + & {
    margin-top: 15px;
  }
`

export const ResponsableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 15px;
  width: 100%;

  input {
    margin: 0;
  }

  div {
    display: flex;
  }
`
