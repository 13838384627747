import React from 'react'
import HospitalPasswordRecoveryForm, {
  SetPasswordFormValues,
  GetPasswordCodeFormValues
} from 'presentation/hospital/components/Forms/PasswordRecovery'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'

type HospitalPasswordRecoveryLayoutProps = {
  getPasswordCodeInitialValues?: GetPasswordCodeFormValues
  setPasswordInitialValues?: SetPasswordFormValues
  getCodeUseCase?: GetPasswordRecoveryCode
  setPasswordUseCase?: ChangePassword
  type: 'phone' | 'email'
}

export default function HospitalPasswordRecoveryLayout({
  getCodeUseCase,
  setPasswordUseCase,
  getPasswordCodeInitialValues,
  setPasswordInitialValues,
  type
}: HospitalPasswordRecoveryLayoutProps) {
  return (
    <HospitalPasswordRecoveryForm
      getCodeUseCase={getCodeUseCase}
      changePasswordUseCase={setPasswordUseCase}
      getCodeValues={getPasswordCodeInitialValues}
      setPasswordValues={setPasswordInitialValues}
      type={type}
    />
  )
}
