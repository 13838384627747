import React, { useRef, useState } from 'react'
import Modal from 'presentation/hospital/components/Modal'
import * as S from 'presentation/hospital/components/Pendency/AddPendencyModal/styles'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import { PendencyCard } from 'presentation/hospital/components/Pendency/PendencyCard'
import AddPendencyForm from 'presentation/hospital/components/Forms/AddPendencyForm'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import { useAuthorizationContext } from 'presentation/hospital/pages/Authorization/context'
import {
  pendencyTranslator,
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import StatusChip from 'presentation/shared/components/StatusChip'
import { FinishPendencyModal } from 'presentation/hospital/components/Pendency/FinishPendencyModal'
import { toUtc } from 'common/utils/toUtc'

export type Props = {
  surgicalOrder: SurgicalOrderList
  close: () => void
}

export const AddPendencyModal = ({ surgicalOrder, close }: Props) => {
  const [mode, setMode] = useState<'view' | 'add'>('view')
  const [activePendency, setActivePendency] = useState<SurgicalPendency>()
  const [isFormValid, setIsFormValid] = useState(false)
  const formRef = useRef<{ submitForm: () => void }>(null)
  const authorizationContext = useAuthorizationContext()

  return (
    <Modal>
      <S.Wrapper>
        <Heading as="h1" color="primary">
          Pendências
        </Heading>
        <S.Content>
          <PendencyCard surgicalOrder={surgicalOrder} />
          {mode === 'view' && (
            <>
              <Button
                fullWidth
                style={{
                  margin: '24px 0',
                  boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
                  height: '60px'
                }}
                type="button"
                onClick={() => setMode('add')}
              >
                Gerar pendência
              </Button>
              {surgicalOrder?.pendencies
                ?.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                .map((pendency) => {
                  return (
                    <S.PendencyCard
                      key={pendency.surgical_pendency_id}
                      onClick={() => setActivePendency(pendency)}
                    >
                      <S.PendencyTitle>
                        <h2>{surgicalOrder.patient}</h2>
                        <StatusChip
                          color={
                            pendency.status === SurgicalPendencyStatus.RESOLVED
                              ? 'primary'
                              : 'red'
                          }
                          label={pendencyTranslator[pendency.status]}
                        />
                      </S.PendencyTitle>
                      <S.PendencyInfo>
                        {pendency.answer &&
                          pendency.answer?.length > 0 &&
                          pendency.status ===
                            SurgicalPendencyStatus.PENDENT && (
                            <S.PendencyAlert />
                          )}
                        <div>
                          <h4>Responsável CRMO:</h4>
                          <span>{pendency.requestedBy?.name}</span>
                        </div>
                        <div>
                          <h4>Pendência gerada:</h4>
                          <span>
                            {new Intl.DateTimeFormat('pt-BR', {
                              timeStyle: 'short',
                              dateStyle: 'short'
                            }).format(toUtc(new Date(pendency.createdAt)))}
                          </span>
                        </div>
                      </S.PendencyInfo>
                    </S.PendencyCard>
                  )
                })}
            </>
          )}
          {mode === 'add' && (
            <AddPendencyForm
              surgical_order_id={Number(surgicalOrder.surgery_request_id) ?? 0}
              addSurgicalPendency={authorizationContext?.addSurgicalPendency}
              patient_id={surgicalOrder.patient_id ?? 0}
              doctor_id={surgicalOrder.doctor_id ?? 0}
              close={() => setMode('view')}
              setIsValid={(state) => setIsFormValid(state)}
              ref={formRef}
              surgicalOrder={surgicalOrder}
            />
          )}
        </S.Content>
        <S.Buttons>
          <Button
            variant="outlined"
            type="button"
            onClick={mode === 'add' ? () => setMode('view') : close}
          >
            {mode === 'add' ? 'Voltar' : 'Cancelar'}
          </Button>
          {mode === 'add' && (
            <Button
              type="button"
              disabled={!isFormValid}
              onClick={() => formRef.current?.submitForm()}
            >
              Enviar
            </Button>
          )}
        </S.Buttons>
      </S.Wrapper>
      {activePendency && (
        <FinishPendencyModal
          pendency={activePendency}
          surgicalOrder={surgicalOrder}
          close={() => setActivePendency(undefined)}
        />
      )}
    </Modal>
  )
}
