import React, { useState, useEffect, useMemo } from 'react'

import * as S from './styles'

import SelectDoctorAndHospital from 'presentation/secretary/components/Forms/AddSurgicalOrder/SelectDoctorAndHospital'
import ReviewPatient from 'presentation/secretary/components/Forms/AddSurgicalOrder/ReviewPatient'
import SelectSurgeryInfo from 'presentation/secretary/components/Forms/AddSurgicalOrder/SelectSurgeryInfo'
import { Doctor } from 'domain/entities/doctor-model'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import { FormikProvider, useFormik } from 'formik'
import { Patient } from 'domain/entities/patient-model'
import Modal from 'presentation/hospital/components/Modal'
import SuccessModal from 'presentation/shared/components/Modal'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { HealthInsurance } from 'domain/entities/health-insurance'
import { Profile } from 'common/enum/profile'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  HealthInsurancePlans,
  LoadHealthInsurancePlans,
  Plans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { createAddSurgicalOrderFunctions } from 'presentation/hospital/pages/AddSurgicalOrder/load-surgical-order'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { isIsoDate } from 'common/utils/verify-iso-date'
import * as yup from 'yup'
import { useStores } from '../../../hooks/use-stores'

type Props = {
  loadHospitals?: LoadHospitals
  loadHealthInsurance?: LoadHealthInsurancePlans
  loadSurgeryCenters?: LoadSurgeryCenter
  surgicalOrderId: number
  loadSurgicalOrder?: LoadSurgicalOrder
  reorderSurgicalOrder?: ReorderSurgicalOrder
  close: () => void
} & WithLoadingProps

export const UpdateSurgicalOrder = WithLoading(
  ({
    loadHealthInsurance,
    loadHospitals,
    surgicalOrderId,
    loadSurgeryCenters,
    close,
    reorderSurgicalOrder,
    loadSurgicalOrder,
    setIsLoading
  }: Props) => {
    const [hospitals, setHospitals] = useState<Hospital[]>([])
    const [insurances, setInsurances] = useState<HealthInsurancePlans[]>([])
    const [plans, setPlans] = useState<Plans[]>([])
    const [subPlans, setSubPlans] = useState<SubPlan[]>([])
    const [surgeryCenters, setSurgeryCenters] = useState<SurgeryCenter[]>([])
    const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [selectedSurgicalOrder, setSelectedSurgicalOrder] =
      useState<SurgicalOrderModel>({ patient: {} } as SurgicalOrderModel)
    const history = useHistory()
    const profile = useStores().currentAccount.getCurrentAccount().user.role

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)
          const surgicalOrder = await loadSurgicalOrder
            ?.load(surgicalOrderId ?? 0, [
              'doctor{name, doctor_id, crm}',
              'patient{birthday, gender, company, name, cpf, email, lastName, phone, landlinePhone, maritalStatus}',
              'hospital{name, hospital_id}',
              'surgical_order_id',
              'createdAt',
              'procedure {isMain,procedure_code,code,description,quantity,doctor_name}',
              'healthInsurance {healthInsuranceCode, healthInsuranceName, healthPlanCode, healthPlanName, company, validThru, healthCard}',
              'hospitalizationMode',
              'hospitalizationType',
              'cid {code, description}',
              'clinicalHistory',
              'laterality',
              'surgicalCenter',
              'room',
              'patientHospitalized',
              'allergy',
              'opme {solicitations {description, quantity}, providers}',
              'documents {document_id, type, name, documentUri}',
              'expectedDate'
            ])
            .then(async (res) => {
              await loadSurgeryCentersByHospital(
                res?.hospital?.hospital_id ?? 0
              )
              return res
            })
          setSelectedSurgicalOrder(surgicalOrder ?? {})
          setHospitals(
            (await loadHospitals?.load(['name', 'hospital_id', 'nickname'])) ??
              []
          )
          await loadHealthInsurances(surgicalOrder?.hospital?.hospital_id ?? 0)
        } catch (e) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    useEffect(() => {
      if (selectedSurgicalOrder?.healthInsurance?.healthInsuranceCode) {
        loadHealthPlans(
          selectedSurgicalOrder.healthInsurance.healthInsuranceCode
        )
      }
    }, [insurances])

    useEffect(() => {
      if (selectedSurgicalOrder?.healthInsurance?.healthPlanCode) {
        loadHealthSubPlans(selectedSurgicalOrder.healthInsurance.healthPlanCode)
      }
    }, [plans])

    const loadSurgeryCentersByHospital = async (hospital_id: number) => {
      setIsLoading(true)
      try {
        const surgeryCentersResponse = await loadSurgeryCenters?.load({
          hospital_id,
          fields: ['codeCenter', 'description', 'hospital_id']
        })
        setSurgeryCenters(surgeryCentersResponse ?? [])
      } catch (e) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadHealthInsurances = async (hospital_id: number) => {
      try {
        setIsLoading(true)
        const insurances = await loadHealthInsurance?.load({
          hospital_id: hospital_id,
          params: [
            'code',
            'description',
            'plans {code, description, subPlan{code, description}}'
          ]
        })

        setInsurances(insurances ?? [])
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadHealthPlans = (insurance_id: number): void => {
      try {
        const healthPlans = insurances.find((insurance) => {
          return insurance.code === insurance_id
        })

        setPlans(healthPlans?.plans ?? [])
      } catch (e: any) {
        toast.error(e.message)
      }
    }

    const loadHealthSubPlans = (plan_id: number): void => {
      try {
        const healthPlans = plans.find((insurance) => {
          return insurance.code === plan_id
        })

        setSubPlans(healthPlans?.subPlan ?? [])
      } catch (e: any) {
        toast.error(e.message)
      }
    }

    const initialValues = useMemo(
      () => ({
        ...selectedSurgicalOrder,
        otherAllergies: selectedSurgicalOrder.allergy,
        hospital: new Hospital(
          selectedSurgicalOrder?.hospital?.hospital_id ?? 0,
          selectedSurgicalOrder?.hospital?.name ?? '',
          selectedSurgicalOrder?.hospital?.name ?? ''
        ),
        opmes: selectedSurgicalOrder.opme?.solicitations,
        procedures: selectedSurgicalOrder?.procedure?.map((proc) => {
          return {
            ...proc,
            tuss_id: proc.code,
            doctor: proc.doctor_name,
            principalProcedure: proc.isMain && 'Procedimento Principal'
          }
        }),
        cids: selectedSurgicalOrder?.cid?.map((cid) => ({
          ...cid,
          cid_id: cid.code
        })),
        surgery_center: surgeryCenters.find(
          (center) =>
            center.description === selectedSurgicalOrder.surgicalCenter
        ),
        patient: {
          ...selectedSurgicalOrder.patient,
          healthInsurance: selectedSurgicalOrder.healthInsurance
        },
        insuranceCardNumber: selectedSurgicalOrder?.healthInsurance?.healthCard,
        insuranceCardValidity:
          selectedSurgicalOrder?.healthInsurance?.validThru,
        observations: selectedSurgicalOrder.observation,
        providers: selectedSurgicalOrder?.opme?.providers,
        insurance: selectedSurgicalOrder.healthInsurance,
        allergy: selectedSurgicalOrder?.allergy,
        expectedDate: selectedSurgicalOrder?.expectedDate
          ? moment(selectedSurgicalOrder?.expectedDate).format('YYYY-MM-DD')
          : undefined
      }),
      [selectedSurgicalOrder]
    )

    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: profile === 'crmo' ? opmeSchema : secretarySchema,
      onSubmit: async (values) => {
        try {
          setIsSubmitted(true)
          setIsLoading(true)

          let selectedCenter

          if (values.surgery_center) {
            selectedCenter = surgeryCenters.find(
              (center) =>
                center.description === values.surgery_center?.description
            )
          } else {
            selectedCenter = surgeryCenters.find(
              (center) =>
                center.description === selectedSurgicalOrder.surgicalCenter
            )
          }
          const validThru = isIsoDate(values.insuranceCardValidity ?? '')
            ? values.insuranceCardValidity
            : moment(values.insuranceCardValidity, 'DD/MM/YYYY').toISOString()

          await reorderSurgicalOrder?.update({
            surgical_order_id: selectedSurgicalOrder?.surgical_order_id ?? 0,
            hospital_id: values.hospital.hospital_id,
            healthInsurance: {
              healthInsuranceCode: values.insurance?.healthInsuranceCode ?? 0,
              healthInsuranceName: values.insurance?.healthInsuranceName ?? '',
              healthPlanCode: values.insurance?.healthPlanCode,
              healthPlanName: values.insurance?.healthPlanName,
              healthCard: values.insuranceCardNumber,
              validThru: validThru
            },
            surgical_center_id: selectedCenter?.codeCenter || 0,
            surgicalCenter: selectedCenter?.description || ''
          })

          setHandleSuccessModal(true)
        } catch (e) {
          toast.error(e.message)
          setIsSubmitted(false)
        } finally {
          setIsLoading(false)
        }
      }
    })

    createAddSurgicalOrderFunctions({
      loadHealthInsurances: loadHealthInsurances,
      loadHealthPlans: loadHealthPlans,
      loadHealthSubPlans: loadHealthSubPlans,
      loadSurgeryCenter: loadSurgeryCentersByHospital
    })

    return (
      <Modal close={close}>
        <S.Wrapper>
          <FormikProvider value={formik}>
            <SelectDoctorAndHospital
              hospitals={hospitals}
              doctors={[{} as Doctor]}
              surgeryCenters={surgeryCenters}
              updateOrder
            />

            <ReviewPatient
              insurances={insurances}
              profile={Profile.CRMO}
              plans={plans}
              subPlans={subPlans}
              updateOrder
            />

            <SelectSurgeryInfo
              profile={Profile.CRMO}
              onlyVisualization
              updateOrder
              submit={formik.submitForm}
              defaultOpme={
                selectedSurgicalOrder?.opme?.solicitations &&
                selectedSurgicalOrder?.opme?.solicitations?.length > 0
              }
              goBack={close}
              disabled={isSubmitted}
            />
          </FormikProvider>
        </S.Wrapper>
        <SuccessModal
          title="Pedido atualizado com sucesso"
          show={handleSuccessModal}
          close={() => history.push('/home')}
        />
      </Modal>
    )
  }
)

export type UpdateSurgicalOrderFormValues = {
  room: string
  laterality: string
  freeze: string
  allergy: string
  hospitalizationMode: string
  hospitalizationType: string
  otherAllergies: string
  clinicalHistory: string
  attendanceCode: string
  providers: string
  height: string
  weight: string
  front: File
  back: File
  isHospitalized: string
  patient: Patient
  doctor: Doctor
  hospital: Hospital
  procedures: any[]
  cids: any[]
  tussList: any[]
  opmes: any[]
  insurance: HealthInsurance
  company: string
  insurancePlan: { value: number; name: string }
  insuranceCardNumber: number
  insuranceCardValidity: string
}

export default UpdateSurgicalOrder

const validationSchema = {
  surgery_center: yup.mixed().required(),
  allergy: yup.mixed().required(),
  hospitalizationMode: yup.string().required(),
  hospitalizationType: yup.string().required(),
  procedures: yup.array().min(1),
  cids: yup.array().min(1),
  clinicalHistory: yup.string().required()
}

const opmeSchema = yup.object().shape({
  ...validationSchema,
  patientHospitalized: yup.boolean().required(),
  opmes: yup.array().when('use_opme', {
    is: (value: boolean) => value,
    then: yup.array().min(1)
  }),
  providers: yup.array().when('opmes', {
    is: (value: []) => !!value?.length,
    then: yup.array().test('opme_size', (value) => {
      return !!value?.some((val) => val)
    })
  })
})

const secretarySchema = yup.object().shape({
  ...validationSchema
})
