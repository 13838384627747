export enum SurgeryStatusEnum {
  ORDER_REGISTERED = 'Pedido cadastrado',
  OPME_QUOTATION = 'OPME_QUOTATION',
  CRMO = 'CRMO',
  HEALTH_INSURANCE_APPROVE = 'HEALTH_INSURANCE_APPROVE',
  AUTHORIZED = 'AUTHORIZED',
  SCHEDULED = 'SCHEDULED',
  PRE_SCHEDULE = 'PRE_SCHEDULE',
  CONCLUDED = 'CONCLUDED',
  CHECK_IN = 'CHECK_IN',
  FASTPASS = 'FASTPASS',
  PATIENT_IN_SURGERY = 'PATIENT_IN_SURGERY',
  PATIENT_RECOVERY = 'PATIENT_RECOVERY',
  PATIENT_DISCHARGE = 'PATIENT_DISCHARGE',
  SERVICE_EVALUATION = 'SERVICE_EVALUATION',
  REVIEW = 'Revisão',
  CANCELLED = 'CANCELLED',
  PENDENCY = 'PENDENCY',
  CANCELLATION_REQUEST = 'CANCELLATION_REQUEST'
}
