import React, { useEffect } from 'react'

import { useStores } from 'presentation/hooks/use-stores'
import makeHospitalSurgicalHome from './hospital-surgical-home-factory'
import { Profile } from 'common/enum/profile'

export default function makeHospitalHome() {
  const stores = useStores()
  const currentHospital = stores.currentHospital
  const surgicalstatus = stores.surgicalstatus

  useEffect(() => {
    async function loadData() {
      await surgicalstatus.load()
      await currentHospital.load()
    }
    loadData()
  }, [])

  const currentUser = stores.currentAccount
  const role = currentUser.getCurrentAccount()?.user?.role
  const hospitalAllowedRoles = [
    Profile.BUDGET,
    Profile.CRMO,
    Profile.HOSPITAL,
    Profile.GUIDE_CENTER,
    Profile.HOSPITALIZATION,
    Profile.OPME,
    Profile.PRE_SCHEDULING,
    Profile.SCHEDULING,
    Profile.TRIAGE,
    Profile.RECEPTIONIST
  ]
  return (
    <>
      {hospitalAllowedRoles.includes(role) ? makeHospitalSurgicalHome() : null}
    </>
  )
}
