import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 90vh;
    width: 80vw;
    color: ${theme.colors.lightGray};
    padding: 10px 80px;
    overflow: auto;

    h1 {
      color: ${theme.colors.red};
      display: inline-block;
      line-height: 100%;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    h2 {
      color: ${theme.colors.primary};
      display: inline;
      font-size: ${theme.font.sizes.large};
      margin-bottom: 20px;
    }

    span {
      font-weight: ${theme.font.black};
      font-size: ${theme.font.sizes.medium};
      display: inline;
    }

    h6 {
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      display: inline;
    }

    p {
      font-weight: ${theme.font.semiBold};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding-right: 5%;
`

export const PainInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    background-color: white;
    justify-content: space-between;
    padding-right: 5%;

    div {
      width: 100%;

      & + div {
        display: flex;

        svg {
          width: 100px;
          height: 280px;
        }
      }
    }

    span {
      color: ${theme.colors.red};
    }

    textarea {
      max-width: 277px;
    }
  `}
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1.8fr 2fr;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #cdcdcd 21.87%,
    rgba(205, 205, 205, 0) 100%
  );
  width: 100%;
  grid-auto-flow: column;
  gap: 1px;
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }

  div + div {
    margin-left: 20%;
  }
`
