import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 3em;
    border-radius: ${theme.border.radius.small};
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    height: 100%;
  `}
`
export const TableTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 36px;
  margin: 20px 0;
`
