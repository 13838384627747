type Fields = {
  name: string
  placeholder: string
  label: string
  mask?: any
  type?: string
}

export const fields: Fields[] = [
  {
    name: 'name',
    placeholder: 'Primeiro nome',
    label: 'Nome'
  },

  {
    name: 'nickname',
    placeholder: 'Nome de preferência',
    label: 'Como gostaria de ser chamado?'
  },
  {
    name: 'register',
    placeholder: 'Registro integração (Identificador unico)',
    label: 'Registro integração (Identificador unico)',
    type: 'number'
  },
  {
    name: 'sector',
    placeholder: 'Setor',
    label: 'Setor'
  },
  {
    name: 'email',
    placeholder: 'email@gmail.com',
    label: 'E-mail'
  },
  {
    name: 'phoneExtension',
    placeholder: 'xxxx',
    label: 'Ramal'
  }
]
