import React from 'react'

import Container from 'presentation/hospital/components/Container'
import CollaboratorsTable from 'presentation/hospital/components/CollaboratorsTable'

import * as S from './styles'

export default function CollaboratorsLayout() {
  return (
    <Container>
      <S.Wrapper>
        <CollaboratorsTable />
      </S.Wrapper>
    </Container>
  )
}
