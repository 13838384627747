import { CountScheduledAndUnscheduledSurgicalOrders } from 'domain/usecases/surgical-order/scheduled-and-unscheduled-counter'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteCountScheduledAndUnscheduledSurgicalOrders
  implements CountScheduledAndUnscheduledSurgicalOrders
{
  constructor(private surgicalOrderRepository: SurgicalOrderRepository) {}

  count(
    params: CountScheduledAndUnscheduledSurgicalOrders.Params
  ): Promise<CountScheduledAndUnscheduledSurgicalOrders.Model> {
    return this.surgicalOrderRepository.countScheduledAndUnscheduledSurgicalOrders(
      params
    )
  }
}
