import React from 'react'
import * as S from './styles'

import Container from 'presentation/hospital/components/Container'
import AddPatientForm from 'presentation/hospital/components/Forms/AddPatient'

export default function AddPatientLayout() {
  return (
    <Container>
      <S.Wrapper>
        <AddPatientForm />
      </S.Wrapper>
    </Container>
  )
}
