import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as S from './styles'

import { cpfMask, phoneMask } from 'presentation/utils/masks'
import PasswordField from 'presentation/shared/components/PasswordField'
import TextField from 'presentation/shared/components/TextField'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import Button from 'presentation/shared/components/Button'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import Checkbox from 'presentation/shared/components/Checkbox'
import 'main/config/yup'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import { useHistory } from 'react-router'
import { FinishCollaboratorSignup } from 'domain/usecases/collaborator/finish-collaborator-signup'

type HospitalSignupFormProps = {
  initialValues?: HospitalSignupFormValues
  finishCollaboratorSignup?: FinishCollaboratorSignup
  token?: string
}

export default function HospitalSignupForm({
  initialValues = {} as HospitalSignupFormValues,
  finishCollaboratorSignup,
  token
}: HospitalSignupFormProps) {
  const history = useHistory()
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [handleSuccessModal, setHandleSuccessModal] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await finishCollaboratorSignup?.add({
          cpf: values.cpf.replace(/\D+/g, ''),
          inviteToken: token ?? '',
          password: values.password ?? '',
          phone: values.phone.replace(/\D+/g, '')
        })
        setHandleSuccessModal(true)
      } catch (error) {
        return toast.error(error.message)
      }
    }
  })

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <Carousel
        touch={false}
        slidesPerView={1}
        state={carousel}
        setState={setCarousel}
      >
        <>
          <Heading as="h1" size="large">
            Bem-vindo(a) à <strong>Jornada Cirúrgica</strong>
          </Heading>
          <SupportText>
            Acesse a plataforma para acompanhar seus processos junto aos nossos
            hospitais.
          </SupportText>
          <S.Inputs>
            <TextField
              label="Nome"
              placeholder="Seu Nome"
              name="name"
              defaultValue={formik.values.name}
              onInputChange={formik.handleChange('name')}
              onBlur={formik.handleBlur('name')}
            />
            <TextField
              label="CPF"
              name="cpf"
              placeholder="000.000.000-00"
              mask={cpfMask}
              value={formik.values.cpf ?? ''}
              onInputChange={formik.handleChange('cpf')}
              onBlur={formik.handleBlur('cpf')}
            />
            <TextField
              label="Celular"
              name="phone"
              placeholder="+55 11 000000000"
              mask={phoneMask}
              defaultValue={formik.values.phone}
              onInputChange={formik.handleChange('phone')}
              onBlur={formik.handleBlur('phone')}
            />
            <TextField
              label="E-mail"
              placeholder="seu@email.com"
              name="phone"
              value={formik.values.email}
              onInputChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
            />
            <PasswordField
              label="Senha"
              placeholder="••••••••"
              name="password"
              initialValue={formik.values.password}
              onInputChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
            />
            <PasswordField
              label="Confirmar senha"
              placeholder="••••••••"
              name="passwordConfirmation"
              initialValue={formik.values.passwordConfirmation}
              onInputChange={formik.handleChange('passwordConfirmation')}
              onBlur={formik.handleBlur('passwordConfirmation')}
            />
          </S.Inputs>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            type="button"
            onClick={carousel.slideNext}
          >
            Confirmar
          </Button>
        </>
        <>
          <Heading as="h1" size="large" style={{ paddingBottom: '20px' }}>
            Termo de confidencialidade
          </Heading>
          <iframe
            src="https://storage.googleapis.com/jc-public/termo-de-uso.pdf"
            style={{
              width: '100%',
              height: '600px',
              border: 'none',
              paddingBottom: '24px'
            }}
          />
          <Checkbox
            label="Aceito o termo de confidencialidade"
            name="terms"
            labelFor="aceito o termo de confidencialidade"
            isChecked={formik.values.terms}
            onCheck={() => formik.setFieldValue('terms', !formik.values.terms)}
          />
          <Button
            disabled={
              !formik.isValid || !formik.values.terms || formik.isSubmitting
            }
            type="submit"
            style={{ marginTop: '24px' }}
          >
            Continuar
          </Button>
        </>
      </Carousel>
      <Modal
        title="Senha definida com sucesso!"
        show={handleSuccessModal}
        close={() => history.push('/login')}
      />
    </S.Wrapper>
  )
}

export type HospitalSignupFormValues = {
  name: string
  cpf: string
  phone: string
  email: string
  password?: string
  passwordConfirmation?: string
  terms?: boolean
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  cpf: yup.string().cpf().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required()
})
