import React from 'react'
import Chip from 'presentation/shared/components/Chip'
import Heading from 'presentation/shared/components/Heading'

import * as S from './styles'
import TextField from 'presentation/shared/components/TextField'
import TextArea from 'presentation/shared/components/TextArea'
import Button from 'presentation/shared/components/Button'

export default function AttendanceStatusForm() {
  return (
    <S.Wrapper>
      <Heading>Status dos atendimentos</Heading>
      <S.InputWrapper>
        <label>Atendimento realizado</label>
        <div>
          <Chip label="Sim" />
          <Chip label="Não" />
        </div>
      </S.InputWrapper>
      <S.InputWrapper>
        <label>Paciente medicado</label>
        <div>
          <Chip label="Sim" />
          <Chip label="Não" />
        </div>
      </S.InputWrapper>
      <S.InputWrapper>
        <label>Horário da medicação</label>
        <TextField placeholder="00:00" bgColor="lightestGray" />
      </S.InputWrapper>
      <S.InputWrapper
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <label>Evolução do paciente</label>
        <TextArea bgColor="lightestGray" rows={5} style={{ width: '100%' }} />
      </S.InputWrapper>
      <S.ResponsableWrapper>
        <label>Responsável: </label>
        <TextField bgColor="lightestGray" />
      </S.ResponsableWrapper>
      <Button>Salvar</Button>
    </S.Wrapper>
  )
}
