import React, { useState, Dispatch, useEffect } from 'react'

import { startCase } from 'lodash'

import Container from 'presentation/hospital/components/Container'
import List, { ListItem } from 'presentation/shared/components/List'
import UserLogo from 'presentation/assets/icons/user.svg'
import UpdatePasswordForm from 'presentation/secretary/components/Forms/UpdatePassword'
import UpdateProfilePic from 'presentation/utils/update-profile-pic'
import UpdateHospitalProfileForm from 'presentation/hospital/components/UpdateProfile'
import { Collaborator } from 'domain/entities/collaborator-model'
import translateRole from 'common/utils/getTranslatedRole'
import { useServices } from 'presentation/hooks/use-services'

import * as S from './styles'

export type HospitalProfileProps = {
  setUpdatePasswordSuccess: Dispatch<React.SetStateAction<boolean>>
  setOpenSuccessModal: Dispatch<React.SetStateAction<boolean>>
}

export default function HospitalProfileLayout({
  setUpdatePasswordSuccess,
  setOpenSuccessModal
}: HospitalProfileProps) {
  const [data, setData] = useState<Collaborator>()
  const [section, setSection] = useState<0 | 1>(0)

  const selectSection = (sectionNumber: 0 | 1): void =>
    setSection(sectionNumber)

  const LIST_ITEMS: ListItem[] = [
    {
      title: 'Dados Pessoais',
      icon: UserLogo,
      click: () => selectSection(0)
    },
    {
      title: 'Alterar senha',
      icon: UserLogo,
      click: () => selectSection(1)
    }
  ]

  const collaboratorService = useServices().collaborator

  const getCollaboratorInfo = async () => {
    const res = await collaboratorService.loadCollaborator([
      'cpf',
      'name',
      'lastName',
      'role',
      'landlinePhone',
      'email',
      'register',
      'sector',
      'phoneExtension'
    ])
    setData(res)
    return res
  }

  useEffect(() => {
    getCollaboratorInfo()
  }, [])

  return (
    <Container>
      <S.Wrapper>
        <>
          <S.ContainerOptions>
            <div>
              <div className="profile-info-div">
                <UpdateProfilePic />
                <S.UserInfo>
                  <strong>{startCase(data?.name?.toLocaleLowerCase())}</strong>
                  <label>{translateRole(data?.role)}</label>
                </S.UserInfo>
              </div>

              <List
                items={LIST_ITEMS}
                size="medium"
                preventIconStyle
                arrow
                spacingIcon
              />
            </div>
          </S.ContainerOptions>

          <S.DivForm>
            {section === 0 && data ? (
              <UpdateHospitalProfileForm
                data={data}
                setOpenSuccessModal={setOpenSuccessModal}
              />
            ) : (
              <UpdatePasswordForm
                setUpdatePasswordSuccess={setUpdatePasswordSuccess}
              />
            )}
          </S.DivForm>
        </>
      </S.Wrapper>
    </Container>
  )
}
