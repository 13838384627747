import React from 'react'

import moment from 'moment-timezone'

import { ReactComponent as BedIcon } from 'presentation/assets/icons/bed.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar.svg'
import * as S from './styles'
import { PainAlert } from 'domain/entities/pain-alert-model'

export type InternationCardProps = {
  patient?: string
  createdAt?: Date
  painLocal?: string
  painIntensity?: string
  painAlert?: PainAlert
  onClick?: (params?: PainAlert) => void
}

const InternationCard = ({
  patient,
  painIntensity,
  painLocal,
  createdAt,
  onClick,
  painAlert
}: InternationCardProps) => {
  return (
    <S.Wrapper onClick={() => onClick && onClick(painAlert ?? undefined)}>
      <S.ColorLabel />
      <S.MainInfo>
        <h5>Alerta de dor</h5>
        <div>
          <h5>Paciente:</h5> <span>{patient}</span>
        </div>
        <S.Icons>
          <S.IconContainer>
            <BedIcon /> <span>684</span>
          </S.IconContainer>
          <S.IconContainer>
            <CalendarIcon /> <span>{moment(createdAt).format('HH[h]MM')}</span>
          </S.IconContainer>
        </S.Icons>
      </S.MainInfo>
      <S.AdditionalInfo>
        <div>
          <h5>Dor</h5>
          <div>
            <span>Local: </span>
            <h5>{painLocal}</h5>
          </div>
          <div>
            <span>Intensidade: </span>
            <h5>{painIntensity}</h5>
          </div>
        </div>
      </S.AdditionalInfo>
    </S.Wrapper>
  )
}

export default InternationCard
