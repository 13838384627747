import React, { useEffect, useState } from 'react'

import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import * as S from './styles'
import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import SelectField from 'presentation/shared/components/SelectField'
import { dateMask } from 'presentation/utils/masks'
import { dateInputFormat } from 'presentation/utils/date-format'
import { SurgeryStatusEnum } from 'common/enum/surgery-status'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import getSlugByText from 'common/utils/getSlugByText'
import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import { CrmoFilter } from 'main/adapters/current-filters'
import { PendencyType } from 'domain/entities/surgical-pendency'
import { ReactComponent as AuthorizationIcon } from 'presentation/assets/icons/authorization-pendencies.svg'
import { ReactComponent as TotalIcon } from 'presentation/assets/icons/total-surgical-orders.svg'
import AssitanceIcon from 'presentation/assets/icons/assistance-pendencies.png'
import DateRange from '@material-ui/icons/DateRange'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

export type SurgicalOrderList = {
  surgery_request_id?: string
  status?: string
  unit?: string
  room?: string
  date?: string
  patient?: string
  birthDate?: string
  gender?: string
  insurance?: string
  doctor?: string
  surgery?: string
}

type Props = {
  scheduledAndUnscheduledCounter?: ScheduledAndUnscheduledCounter
  data?: CountSurgicalOrdersByStatus.Model
  changeFilter?: (
    start: string,
    end: string,
    label?: string,
    params?: SearchSurgicalOrders.Params
  ) => void
  hospitalName?: string
  crmoDefaultFilters: CrmoFilter
}

export default function HospitalHomeLayout({
  scheduledAndUnscheduledCounter,
  changeFilter,
  hospitalName,
  data,
  crmoDefaultFilters
}: Props) {
  const userStore = useStores().currentAccount
  const [showCalendar, setShowCalendar] = useState<'start' | 'end' | ''>()
  const [dates, setDates] = useState<{
    start?: string
    end?: string
    label?: string
  }>(crmoDefaultFilters)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  useEffect(() => {
    if (isLoaded) {
      if (moment(dates.start).isSameOrBefore(moment(dates.end))) {
        changeFilter &&
          changeFilter(
            dates.start ?? new Date().toISOString(),
            dates.end ?? new Date().toISOString(),
            dates.label
          )
      }
    } else {
      setIsLoaded(true)
    }
  }, [dates])

  useEffect(() => {
    isLoaded &&
      changeFilter &&
      changeFilter(
        dates.start ?? new Date().toISOString(),
        dates.end ?? new Date().toISOString()
      )
  }, [hospitalName])

  useEffect(() => {
    const role = userStore.getCurrentAccount().user.role
    if (role === Profile.HOSPITAL) {
      document.getElementById('surgical-orders-counters').style.pointerEvents =
        'none'
      document.getElementById('head-counters').style.pointerEvents = 'none'
    }
  }, [])

  const toggleCalendar = (order: 'start' | 'end') => {
    if (showCalendar === order) {
      setShowCalendar('')
    } else {
      setShowCalendar(order)
    }
  }

  const changeDate = (date: Date) => {
    if (showCalendar === 'start' && !moment(date).isSameOrBefore(dates.end)) {
      return setShowCalendar('')
    } else if (
      showCalendar === 'end' &&
      moment(dates[showCalendar]) &&
      !moment(date).isSameOrAfter(dates.start)
    ) {
      return setShowCalendar('')
    }
    if (showCalendar) {
      setDates({
        ...dates,
        [showCalendar]: date.toISOString(),
        label: 'personalized'
      })
    }
    return setShowCalendar('')
  }

  const changeInterval = (interval: string) => {
    const today = moment().tz('America/Sao_Paulo').utc()
    const dates: {
      start?: string
      end?: string
      label?: string
    } = {
      end: today.endOf('day').toISOString(),
      label: interval
    }

    switch (interval) {
      case 'today':
        dates.start = today.startOf('day').toISOString()
        break
      case 'yesterday':
        dates.start = today.subtract('1', 'days').startOf('day').toISOString()
        dates.end = today.endOf('day').toISOString()
        break
      case 'week':
        dates.start = today.subtract('1', 'week').startOf('day').toISOString()
        break
      case 'month':
        dates.start = today.subtract('1', 'month').startOf('day').toISOString()
        break
      case 'trimester':
        dates.start = today.subtract('3', 'month').startOf('day').toISOString()
        break
      case 'semester':
        dates.start = today.subtract('6', 'month').startOf('day').toISOString()
        break
      default:
        dates.start = today.toISOString()
        break
    }
    setDates(dates)
  }

  const history = useHistory()

  const headCounters = [
    {
      label: 'Total de pedidos',
      avg: '',
      total: data?.total,
      status: undefined,
      icon: <TotalIcon />
    },
    {
      label: 'Pendências  de Autorização',
      avg: '',
      total: data?.authorizationPendencies,
      status: SurgeryStatusEnum.PENDENCY,
      state: { pendency: true, pendencyType: PendencyType.AUTHORIZATION },
      icon: <AuthorizationIcon />
    },
    {
      label: 'Pendências Assitenciais',
      avg: '',
      total: data?.assistancePendencies,
      status: SurgeryStatusEnum.PENDENCY,
      state: { pendency: true, pendencyType: PendencyType.ASSISTANCE },
      icon: <img src={AssitanceIcon} />
    }
  ]

  const counters = [
    {
      label: 'Pedidos solicitados por pacientes',
      avg: '',
      total: data?.orderedByPatient,
      status: String(SurgeryStatusEnum.ORDER_REGISTERED)
    },
    {
      label: 'Revisar',
      avg: '',
      total: data?.review,
      status: String(SurgeryStatusEnum.REVIEW)
    }
  ]

  const surgicalStatus = useStores().surgicalstatus.getStatus() || []

  surgicalStatus.forEach((status) => {
    counters.push({
      label: status.internalStatus,
      avg: '',
      total: data?.[status.internalStatus],
      status: status.internalStatus
    })
  })

  const getFilterValue = () => {
    if (dates.label === 'personalized') {
      return ''
    }
    return dates.label
  }

  return (
    <Container>
      <S.Wrapper>
        <S.OrdersHeader>
          <Heading size="huge" color="primary">
            Pedidos {hospitalName}
          </Heading>
          <S.InputContainer>
            <S.InputCalendar>
              <TextField
                mask={dateMask}
                icon={<DateRange />}
                iconPosition="right"
                iconLocale="inside"
                iconMargin="44px"
                onIconClick={() => toggleCalendar('start')}
                value={dateInputFormat(moment(dates.start ?? '').toISOString())}
              />
              <S.StyledCalendar
                show={showCalendar === 'start'}
                onDateChange={(date) => changeDate(date as Date)}
              />
            </S.InputCalendar>
            <S.InputCalendar>
              <TextField
                mask={dateMask}
                icon={<DateRange />}
                iconPosition="right"
                iconLocale="inside"
                iconMargin="44px"
                onIconClick={() => toggleCalendar('end')}
                value={dateInputFormat(
                  moment(dates.end ?? '')
                    .subtract(4, 'hours')
                    .toISOString()
                )}
              />
              <S.StyledCalendar
                show={showCalendar === 'end'}
                onDateChange={(date) => changeDate(date as Date)}
              />
            </S.InputCalendar>
            <SelectField
              initialValue="today"
              items={filterValues}
              onInputChange={changeInterval}
              placeholder={'Personalizado'}
              value={getFilterValue()}
            />
          </S.InputContainer>
        </S.OrdersHeader>
        <S.HeadCountersWrapper id="head-counters">
          {headCounters.map((count) => (
            <S.HeadStatusCard
              isPendency={count.status === SurgeryStatusEnum.PENDENCY}
              key={count.label}
              onClick={() =>
                history.push(`/pedidos/${getSlugByText(count.label)}`, {
                  surgeryStatus: count.status,
                  state: count.state,
                  startDate: dates.start,
                  endDate: dates.end
                })
              }
            >
              {count.icon}
              <h5>{count.label}</h5>
              <div>
                <span>{count.total}</span>
              </div>
            </S.HeadStatusCard>
          ))}
        </S.HeadCountersWrapper>
        <S.CountersWrapper id="surgical-orders-counters">
          {counters.map((count) => (
            <S.StatusCard
              key={count.label}
              onClick={() =>
                history.push(`/pedidos/${getSlugByText(count.label)}`, {
                  surgeryStatus: count.status,
                  startDate: dates.start,
                  endDate: dates.end,
                  label: dates.label
                })
              }
            >
              <h5>{count.label}</h5>
              <div>
                <span>{count.total}</span>
              </div>
            </S.StatusCard>
          ))}
        </S.CountersWrapper>
      </S.Wrapper>
    </Container>
  )
}

type SchedulesCount = {
  total?: number
  approved?: number
  disapproved?: number
}

type ScheduledAndUnscheduledCounter = {
  scheduled: SchedulesCount
  unscheduled: SchedulesCount
  total: number
}

const filterValues = [
  {
    label: 'Hoje',
    value: 'today'
  },
  {
    label: 'Ontem',
    value: 'yesterday'
  },
  {
    label: 'Semana',
    value: 'week'
  },
  {
    label: 'Mês',
    value: 'month'
  },
  {
    label: 'Trimestre',
    value: 'trimester'
  },
  {
    label: 'Semestre',
    value: 'semester'
  }
]
