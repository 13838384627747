import React, { useState } from 'react'

import moment from 'moment-timezone'

import * as S from './styles'
import Modal from 'presentation/hospital/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import TextArea from 'presentation/shared/components/TextArea'
import SupportText from 'presentation/shared/components/SupportText'
import { Patient } from 'domain/entities/patient-model'
import { useStores } from 'presentation/hooks/use-stores'
import { useServices } from 'presentation/hooks/use-services'
import Button from 'presentation/shared/components/Button'
import { useTheme } from 'styled-components'
import { toast } from 'react-toastify'

type CancelSurgicalOrderModalProps = {
  close: () => void
  patient: Patient
  surgicalOrder: any
  refreshTable?: () => void
}

export function CancelSurgicalOrderModal({
  close,
  patient,
  surgicalOrder,
  refreshTable
}: CancelSurgicalOrderModalProps) {
  const [justification, setJustification] = useState('')
  const user = useStores().currentAccount.getCurrentAccount().user
  const surgicalOrderService = useServices().surgicalOrder
  const theme = useTheme()

  const deleteSurgicalOrder = async () => {
    try {
      await surgicalOrderService.deleteSurgicalOrderSolicitation({
        surgical_order_id: Number(surgicalOrder?.surgery_request_id),
        justification
      })
      toast.success('Pedido excluido com sucesso')
      refreshTable?.()
      close()
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar excluir esse pedido cirúrgico')
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    deleteSurgicalOrder()
  }

  return (
    <Modal bgColor="mainBg" close={close}>
      <S.Wrapper onSubmit={handleSubmit}>
        <Heading size="huge" color="primary">
          Cancelar Pedido
        </Heading>
        <S.Content>
          <S.Card>
            <div>
              <Heading size="small" color="primary">
                {patient.name}
              </Heading>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                <Heading
                  size="small"
                  style={{ fontSize: '14px', fontWeight: 500 }}
                  color="primary"
                >
                  Convênio:{' '}
                  <span style={{ color: theme.colors.gray }}>
                    {surgicalOrder.insurance}
                  </span>
                </Heading>
                <SupportText color="primary">
                  {surgicalOrder?.healthInsurance?.healthInsuranceName}
                </SupportText>
              </div>
            </div>
            <div>
              <SupportText style={{ fontSize: '14px' }} color="primary">
                Data da Solicitação: {surgicalOrder?.createdAt}
              </SupportText>
            </div>
          </S.Card>
          <S.Justify>
            <Heading color="primary" style={{ marginBottom: '10px' }}>
              Justificativa de Cancelamento
            </Heading>
            <TextArea
              placeholder="Justificativa"
              onChange={(e) => setJustification(e.currentTarget.value)}
            />
          </S.Justify>
          <S.Card>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Heading
                  color="primary"
                  style={{ fontSize: '14px', fontWeight: 500 }}
                >
                  Responsável:{' '}
                </Heading>
                <SupportText style={{ fontSize: '14px', fontWeight: 500 }}>
                  {user.name}
                </SupportText>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Heading
                  color="primary"
                  style={{ fontSize: '14px', fontWeight: 500 }}
                >
                  Data de Cancelamento:{' '}
                </Heading>
                <SupportText style={{ fontSize: '14px', fontWeight: 500 }}>
                  {moment(new Date()).format('DD/MM/YYYY')}
                </SupportText>
              </div>
            </div>
          </S.Card>
        </S.Content>
        <S.Buttons>
          <Button variant="outlined" type="button" onClick={close}>
            Voltar
          </Button>
          <Button type="submit">Enviar</Button>
        </S.Buttons>
      </S.Wrapper>
    </Modal>
  )
}
