import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 47px 84px;
    margin: 30px;
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.large};

    h2 {
      line-height: 48px;
    }
  `}
`
