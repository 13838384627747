import React, { useEffect, useState } from 'react'

import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import Modal from 'presentation/hospital/components/Modal'
import Button from 'presentation/shared/components/Button'
import { useFormik } from 'formik'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import {
  WithLoading,
  WithLoadingProps
} from '../../../shared/components/HOCs/WithLoading'
import SupportText from 'presentation/shared/components/SupportText'
import { SurgicalCommentModel } from 'domain/entities/surgical-comment-model'
import moment from 'moment'
import Divider from 'presentation/shared/components/Divider'
import TextArea from 'presentation/shared/components/TextArea'
import { useServices } from 'presentation/hooks/use-services'

export type Props = {
  close: () => void
  surgicalOrderId: number
  loadSurgicalOrder?: LoadSurgicalOrder
} & WithLoadingProps

export const CommentsModal = WithLoading(
  ({ close, surgicalOrderId, loadSurgicalOrder, setIsLoading }: Props) => {
    const [comments, setComments] = useState<SurgicalCommentModel[]>([])
    const surgicalOrderService = useServices().surgicalOrder

    const formik = useFormik({
      initialValues: {
        message: '',
        surgical_order_id: surgicalOrderId
      },
      validationSchema: yup.object().shape({
        message: yup.string().required()
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const comment = await surgicalOrderService.createComment(values)
          setComments((prev) => [...prev, comment])
          resetForm()
        } catch (e) {
          toast.error(e.message)
        }
      }
    })

    useEffect(() => {
      async function loadData() {
        try {
          const surgicalOrder = await loadSurgicalOrder?.load(surgicalOrderId, [
            'comments {sentAt, sentBy, message}'
          ])
          setComments(surgicalOrder?.comments || [])
          setIsLoading(true)
        } catch (e) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    return (
      <Modal close={close}>
        <S.Wrapper onSubmit={formik.handleSubmit}>
          <Heading size="large" as="h1" color="primary">
            Comentários gerais do pedido
          </Heading>
          <SupportText style={{ marginBottom: '.5rem' }}>
            Acompanhe as considerações geradas ao longo da evolução do
            procedimento
          </SupportText>
          <S.Title id="title">Histórico de comentários</S.Title>
          <S.CommentsWrapper>
            {comments.length ? (
              comments.map((comment, index) => (
                <>
                  <S.Comment key={index}>
                    <div>
                      {moment(comment.sentAt)
                        .utc(true)
                        .format('DD/MM/YYYY - HH:mm')}
                      <strong>{comment.sentBy}</strong> comentou:
                    </div>
                    <i>{comment.message}</i>
                  </S.Comment>
                  <Divider />
                </>
              ))
            ) : (
              <div>Nenhum comentário adicionado</div>
            )}
          </S.CommentsWrapper>
          <S.InputWrapper>
            <S.Title id="title">Novo comentário </S.Title>
            <TextArea
              name="message"
              id="message"
              placeholder="Adicione uma observação"
              rows={3}
              outline
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message || ''}
            />
          </S.InputWrapper>
          <S.Buttons>
            <Button variant="outlined" type="button" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" disabled={!formik.isValid || !formik.dirty}>
              Adicionar
            </Button>
          </S.Buttons>
        </S.Wrapper>
      </Modal>
    )
  }
)
