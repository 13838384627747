import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteAddSurgicalPendency implements AddSurgicalPendency {
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  add(params: AddSurgicalPendency.Params): Promise<AddSurgicalPendency.Model> {
    return this.surgicalPendencyRepository.addSurgicalPendency(params)
  }
}
