import styled from 'styled-components'

export const Wrapper = styled.div``

export const SearchWrapper = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 0px 30px;
  padding-top: 33px;
  padding-bottom: 45px;
  border-radius: 4px;
  margin-bottom: 1.5rem;

  & > div {
    width: 100%;
  }

  button {
    max-width: 172px;
  }
`
