import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SurgeryRepository } from 'repository/repositories/surgery/surgery-repository'
import { RemoteLoadPainAlerts } from 'service/usecases/load-pain-alerts/remote-load-pain-alerts'
import PainAlerts from 'presentation/hospital/pages/PainAlerts'

export default function makeHospitalPainAlerts() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgeryRepository = new SurgeryRepository(apiRepository)

  const loadPainAlerts = new RemoteLoadPainAlerts(surgeryRepository)

  return <PainAlerts loadPainAlert={loadPainAlerts} />
}
