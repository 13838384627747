import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 90vh;
  max-height: 90vh;
  width: 720px;
  max-width: 80vw;
  background-color: ${({ theme }) => theme.colors.mainBg};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
  }
`

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`

export const PendencyCard = styled.div`
  background-color: white;
  width: 100%;
  height: 120px;
  display: grid;
  border-radius: 20px;
  grid-template-columns: 1fr 2fr;
  cursor: pointer;
  & + & {
    margin-top: 13px;
  }
`

export const PendencyTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: space-around;
  align-items: flex-start;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
    font-weight: 700;
    line-height: 19.8px;
  }
`

export const PendencyInfo = styled.div`
  margin: auto 0;
  padding: 22px;
  position: relative;
  h4 {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
  }

  div {
    h4,
    span {
      display: inline;
    }

    span {
      margin-left: 6px;
    }
  }
`

export const PendencyAlert = styled.div`
  position: absolute;
  top: 5px;
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};

  &:before {
    content: '!';
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
