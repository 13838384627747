import styled from 'styled-components'
import { generateMedia } from 'styled-media-query'

const customMedia = generateMedia({
  xhuge: '1600px'
})

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1280px;
  ${customMedia.greaterThan('xhuge')`
    max-width: 90%;
  `}
`

export const Content = styled.div`
  flex: 1;
  display: grid;
  align-content: center;
  gap: 0px 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 120px);
  max-height: 700px;
  padding-top: 20px;
  margin-bottom: 80px;
  ${customMedia.greaterThan('xhuge')`
    align-content: flex-start;
    padding-top: 40px;
    max-width: 80%;
  `}
`
