import React from 'react'

import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { FinishSurgicalPendency } from 'domain/usecases/surgical-pendency/finish-surgical-pendency'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { PaginationModel } from 'domain/entities/pagination-model'

type Props = {
  initialPaginationState: PaginationModel
  addSurgicalPendency: AddSurgicalPendency
  loadSurgicalPendencyDocument: LoadSurgicalPendencyDocument
  finishSurgicalPendency: FinishSurgicalPendency
  addPendency: (
    id: string,
    pendency: SurgicalPendency,
    forceRefresh?: true
  ) => Promise<SearchSurgicalOrders.Model | undefined>
}

let context: React.Context<Props>

export const createAuthorizationContext = ({
  addSurgicalPendency,
  addPendency,
  loadSurgicalPendencyDocument,
  finishSurgicalPendency,
  initialPaginationState
}: Props) => {
  context = React.createContext({
    addSurgicalPendency,
    addPendency,
    loadSurgicalPendencyDocument,
    finishSurgicalPendency,
    initialPaginationState
  })
  return context
}

export const useAuthorizationContext = () => {
  if (context) {
    return React.useContext(context)
  }
}
