import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'

import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { HospitalSignupFormValues } from 'presentation/hospital/components/Forms/Signup'
import HospitalSignupLayout from 'presentation/hospital/layouts/Signup'
import { FinishCollaboratorSignup } from 'domain/usecases/collaborator/finish-collaborator-signup'

type HospitalSignupProps = {
  loadUserByInviteToken: LoadUserByInviteToken
  finishHospitalSignup: FinishCollaboratorSignup
}

export default function HospitalSignup({
  loadUserByInviteToken,
  finishHospitalSignup
}: HospitalSignupProps) {
  const { hash } = useParams<RouteParams>()
  const history = useHistory()

  const [initialValues, setInitialValues] = useState(
    {} as HospitalSignupFormValues
  )
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function loadData() {
      try {
        const userData = await loadUserByInviteToken.load(hash, [
          'email',
          'phone',
          'name'
        ])
        setInitialValues({
          cpf: userData.cpf,
          name: userData.name,
          email: userData.email,
          phone: userData.phone ?? '',
          password: '',
          passwordConfirmation: '',
          terms: false
        })
      } catch (error) {
        toast.error('Token de acesso inválido')
        return history.push('/login')
      }
      setIsLoading(false)
    }
    loadData()
  }, [hash])

  if (isLoading) {
    return <></>
  }

  return (
    <HospitalSignupLayout
      initialValues={initialValues}
      finishHospitalSignup={finishHospitalSignup}
      token={hash}
    />
  )
}

type RouteParams = {
  hash: string
}
