import React from 'react'

import Container from 'presentation/hospital/components/Container'
import DoctorsTable from 'presentation/hospital/components/DoctorsTable'

export default function DoctorsLayout() {
  return (
    <Container>
      <DoctorsTable />
    </Container>
  )
}
