import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 74px;
  width: 100%;
  position: relative;
  padding: 10px 20px;
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 10px;
  cursor: pointer;
  h5 {
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    display: inline;
  }
  span {
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    display: inline;
  }
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.gray};
  padding-right: 8px;
  width: 50%;
`

export const AdditionalInfo = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  h5 {
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    display: inline;
  }
  span {
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    display: inline;
  }
  border-left: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding-left: 8px;
`

export const ColorLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  width: 20px;
  background-color: red;
  border-radius: 10px 0 0 10px;
`

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IconContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;

    path {
      fill: #888888;
    }
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 50px;
  margin: 0 8px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  justify-self: flex-end;
  align-self: flex-end;
`
