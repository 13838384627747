import React from 'react'

import * as S from './styles'
import Modal from 'presentation/hospital/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import { useServices } from 'presentation/hooks/use-services'
import { useFormik } from 'formik'
import { Doctor } from 'domain/entities/doctor-model'
import { toast } from 'react-toastify'
import * as yup from 'yup'

type Props = {
  close: () => void
  doctor: Doctor
}

export function ResendDoctorInvite({ close, doctor }: Props) {
  const doctorService = useServices().doctor

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    isValid,
    isSubmitting,
    errors,
    touched
  } = useFormik<ResendDoctorInviteFormValues>({
    initialValues: {
      cpf: doctor.cpf,
      crm: doctor.crm,
      name: doctor.name,
      uf: doctor.crmUf,
      email: doctor.email,
      phone: doctor.phone?.replace(/^(\d\d)(\d{2})(\d{9}).*/, '+$1 $2 $3')
    } as ResendDoctorInviteFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await doctorService.resendDoctorInvite({
          doctor_id: doctor.doctor_id,
          phone: values.phone?.replace(/\D+/g, ''),
          email: values.email
        })
        toast.success('Convite reenviado com sucesso')
        close()
      } catch (e) {
        toast.error('Ocorreu um erro ao tentar reenviar o convite do médico')
      }
    }
  })

  return (
    <Modal close={close}>
      <S.Wrapper onSubmit={handleSubmit}>
        <Heading size="huge">Reenvio de convite</Heading>
        <S.InputsWrapper>
          <TextField
            label="Nome"
            bgColor="mainBg"
            disabled
            value={values.name}
          />
          <TextField
            label="CPF"
            bgColor="mainBg"
            disabled
            value={values.cpf}
            mask={cpfMask}
          />
          <S.CRMWrapper>
            <TextField
              label="CRM"
              bgColor="mainBg"
              disabled
              value={values.crm}
            />
            <TextField label="UF" bgColor="mainBg" disabled value={values.uf} />
          </S.CRMWrapper>
          <div />
          <TextField
            label="E-mail"
            bgColor="mainBg"
            value={values.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.email && errors.email}
          />
          <TextField
            label="Celular"
            bgColor="mainBg"
            mask={phoneMask}
            value={values.phone}
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && errors.phone}
          />
        </S.InputsWrapper>
        <S.ButtonsWrapper>
          <Button variant="outlined" type="button" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Enviar
          </Button>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </Modal>
  )
}

type ResendDoctorInviteFormValues = {
  name: string
  cpf: string
  crm: string
  uf: string
  email: string
  phone: string
}

const validationSchema = yup.object().shape({
  email: yup.string().email(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required()
})
