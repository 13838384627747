import React, { useState, useMemo, useEffect } from 'react'

import moment from 'moment'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { Collaborator } from 'domain/entities/collaborator-model'
import { Hospital } from 'domain/entities/hospital-model'
import { Pagination } from 'service/protocols/api/api-client'
import { PaginationModel } from 'domain/entities/pagination-model'
import { useServices } from 'presentation/hooks/use-services'

import * as S from './styles'
import {
  WithLoading,
  WithLoadingProps
} from '../../../shared/components/HOCs/WithLoading'
import { validateCpf } from '../../../utils/validators/cpf-validator'

function CollaboratorsTable({ setIsLoading }: WithLoadingProps) {
  const [pagination, setPagination] = useState<PaginationModel>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 10,
    totalPages: 1
  })
  const [collaborators, setCollaboratos] = useState<Collaborator[]>([])
  const service = useServices().collaborator

  const formik = useFormik<SearchCollaboratorsFilterProps>({
    initialValues: { name: '' },
    onSubmit: (values) => {
      findCollaboratos(values.name ? values.name : ' ')
    }
  })

  const handleFilterCollaborators = async () => {
    findCollaboratos(formik.values.name)
  }

  const cleanFilters = async () => {
    findCollaboratos('')
  }

  const findCollaboratos = async (name: string, pagination?: Pagination) => {
    try {
      setIsLoading(true)
      const collaborators = await service.searchCollaborators({
        name: name,
        params: [
          'data{',
          'cpf',
          'register',
          'email',
          'phone',
          'phoneExtension',
          'name',
          'createdAt',
          'hospital {name}}',
          'pageInfo {totalItems, itemsPerPage, currentPage, totalItems}'
        ],
        pagination: {
          pageNumber: pagination?.pageNumber ?? 1,
          pageSize: pagination?.pageSize ?? 10
        }
      })
      setCollaboratos(collaborators.data)
      setPagination(collaborators.pageInfo)
      return collaborators.pageInfo
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const renderTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={collaborators.map((collaborator) => ({
          cpf: validateCpf(collaborator.cpf)
            ? collaborator.cpf
            : 'Não inserido',
          register: collaborator.register,
          name: collaborator.name,
          email: collaborator.email || 'Não inserido',
          phone: collaborator.phoneExtension,
          unit: collaborator.hospital
            ?.map(
              (hospital) =>
                new Hospital(0, hospital.nickname, hospital.name).name
            )
            .join(', '),
          startDate: moment(collaborator.createdAt)
            .tz('America/Sao_Paulo')
            .format('DD/MM/YYYY')
        }))}
        count={pagination.totalItems}
        rowsPerPage={pagination.itemsPerPage}
        page={pagination.currentPage}
        onPaginate={async (currentPage, itemsPerPage) => {
          findCollaboratos(formik.values.name || '', {
            pageNumber: currentPage,
            pageSize: itemsPerPage
          })
          return undefined
        }}
      />
    )
  }, [collaborators, pagination])

  useEffect(() => {
    findCollaboratos('')
  }, [])

  return (
    <S.Wrapper>
      <Heading
        as="h1"
        size="huge"
        color="primary"
        style={{ marginBottom: '45px' }}
      >
        Colaboradores
      </Heading>
      <S.FiltersContainer>
        <S.FiltersInput data-testid="filterInput">
          <TextField
            name="name"
            id="name"
            bgColor="mainBg"
            placeholder="Busca por colaborador"
            iconPosition="left"
            icon={<SearchIcon />}
            iconMargin="50px"
            iconLocale="inside"
            onInputChange={formik.handleChange('name')}
          />
        </S.FiltersInput>
        <S.FiltersButton data-testid="filterButtons">
          <Button type="button" onClick={() => handleFilterCollaborators()}>
            Buscar
          </Button>
          <Button
            type="button"
            onClick={() => cleanFilters()}
            variant="outlined"
          >
            Limpar
          </Button>
        </S.FiltersButton>
      </S.FiltersContainer>
      <S.CollaboratorsTable>{renderTable}</S.CollaboratorsTable>
    </S.Wrapper>
  )
}

type SearchCollaboratorsFilterProps = {
  name: string
}

export default WithLoading(CollaboratorsTable)

const columns = [
  {
    name: 'cpf',
    label: 'CPF',
    options: {
      sort: false
    }
  },
  {
    name: 'register',
    label: 'Matrícula',
    options: {
      sort: false
    }
  },
  {
    name: 'name',
    label: 'Nome',
    options: {
      sort: false
    }
  },
  {
    name: 'startDate',
    label: 'Data de início',
    options: {
      sort: false
    }
  },
  {
    name: 'unit',
    label: 'Unidade',
    options: {
      sort: false
    }
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      sort: false
    }
  },
  {
    name: 'phone',
    label: 'Ramal',
    options: {
      sort: false
    }
  }
]
