import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import { RemoteAuthentication } from 'service/usecases/authentication/remote-authentication'
import LoginLayout from 'presentation/shared/layouts/Login'

export default function makeHospitalLogin() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const authentication = new RemoteAuthentication(authenticationRepository)
  return <LoginLayout useCase={authentication} />
}
