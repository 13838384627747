export enum Roles {
  ADMIN = 'ADMIN',
  DOCTOR = 'DOCTOR',
  PATIENT = 'PATIENT',
  NURSE = 'NURSE',
  SECRETARY = 'SECRETARY',
  CRMO = 'CRMO',
  HOSPITALIZATION = 'HOSPITALIZATION',
  BUDGET = 'BUDGET',
  GUIDE_CENTER = 'GUIDE_CENTER',
  OPME = 'OPME',
  PRE_SCHEDULING = 'PRE_SCHEDULING',
  SCHEDULING = 'SCHEDULING',
  TRIAGE = 'TRIAGE',
  RECEPTIONIST = 'RECEPTIONIST'
}
