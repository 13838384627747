import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 90vw;
  max-height: 90vh;
  max-width: 920px;
`

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  overflow-y: auto;
  gap: 12px;
  padding: 40px 0;
`

export const CRMWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 12px;
  align-items: flex-start;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-top: auto;

  button {
    width: 35%;
    margin-top: 0 !important;

    & + button {
      margin-left: 15px;
    }
  }
`
