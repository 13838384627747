import React from 'react'

import * as S from './styles'
import Modal from 'presentation/hospital/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import TextArea from 'presentation/shared/components/TextArea'
import PainMapper from 'presentation/shared/components/PainMapper'
import AttendanceStatusForm from 'presentation/hospital/components/Forms/AttendanceStatus'
import { ReactComponent as BedIcon } from 'presentation/assets/icons/bed.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar.svg'
import { PainAlert } from 'domain/entities/pain-alert-model'
import moment from 'moment-timezone'
import { dateInputFormat } from 'presentation/utils/date-format'
import { getPainIntensity } from 'presentation/utils/get-pain-intensity'

type Props = {
  painAlert?: PainAlert
  close?: () => void
}

const PainAlertModal = ({ painAlert, close }: Props) => {
  return (
    <Modal>
      <S.Wrapper>
        <S.Header>
          <Heading as="h1" size="huge">
            Alerta de dor
          </Heading>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={close}
          >
            <path
              d="M23.082 5.35135C24.306 4.12731 24.306 2.14274 23.082 0.918697C21.8579 -0.305346 19.8734 -0.305346 18.6493 0.918697L0.918848 18.6493C-0.305188 19.8734 -0.305187 21.8579 0.918848 23.082C2.14288 24.306 4.12744 24.306 5.35147 23.082L23.082 5.35135Z"
              fill="#D0D0D1"
            />
            <path
              d="M5.35065 0.918033C4.12662 -0.306011 2.14206 -0.306011 0.918027 0.918033C-0.306009 2.14208 -0.306009 4.12664 0.918027 5.35069L18.6485 23.0813C19.8726 24.3053 21.8571 24.3053 23.0812 23.0813C24.3052 21.8573 24.3052 19.8727 23.0812 18.6486L5.35065 0.918033Z"
              fill="#D0D0D1"
            />
          </svg>
        </S.Header>
        <S.InfoContainer>
          <S.PatientInfo>
            <Heading as="h2">Paciente</Heading>
            <div>
              <h6>Nome: </h6>
              <span>{painAlert?.surgicalOrder?.patient?.name}</span>
            </div>
            <div>
              {painAlert?.surgicalOrder?.patient?.birthday && (
                <>
                  <h6>Data de nascimento: </h6>
                  <span>
                    {dateInputFormat(
                      painAlert?.surgicalOrder?.patient?.birthday
                    )}
                  </span>
                </>
              )}
            </div>
            <div style={{ marginTop: '20px', marginBottom: '16px' }}>
              <p>{painAlert?.surgicalOrder?.hospital?.name} | Internação</p>
            </div>
            <S.IconsContainer>
              <div>
                <BedIcon />
                <span>Leito: 684</span>
              </div>
              <div>
                <CalendarIcon />
                <h6>
                  Abertura do chamado:{' '}
                  {moment(painAlert?.createdAt).format('HH[h]MM')}
                </h6>
              </div>
            </S.IconsContainer>
          </S.PatientInfo>
          <S.PainInfo>
            <div>
              <Heading as="h2">Dor</Heading>
              <div>
                <h6>Local: </h6>
                {painAlert?.frontLocals &&
                  painAlert?.frontLocals?.length > 0 && (
                    <span>FRONTAL: {painAlert.frontLocals.join('-')}</span>
                  )}

                <br />
                {painAlert?.backLocals && painAlert?.backLocals?.length > 0 && (
                  <span style={{ marginLeft: '55px' }}>
                    COSTAS: {painAlert.backLocals.join('-')}
                  </span>
                )}
              </div>
              <div>
                <h6>Intensidade: </h6>
                <span>{getPainIntensity(painAlert?.intensity)}</span>
              </div>
              <TextArea
                bgColor="lightestGray"
                rows={8}
                disabled
                initialValue={painAlert?.description}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PainMapper
                side="front"
                initialValue={painAlert?.frontLocals}
                visualizationOnly
              />
              <PainMapper
                side="back"
                initialValue={painAlert?.backLocals}
                visualizationOnly
              />
            </div>
          </S.PainInfo>
          <div
            style={{
              gridRow: 'span 2',
              background: 'white',
              paddingLeft: '5%'
            }}
          >
            <AttendanceStatusForm />
          </div>
        </S.InfoContainer>
      </S.Wrapper>
    </Modal>
  )
}

export default PainAlertModal
