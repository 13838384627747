import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteAddDoctor } from 'service/usecases/add-doctor/remote-add-doctor'
import HospitalAddDoctor from 'presentation/hospital/pages/AddDoctor'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'

export default function makeAddDoctor() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const doctorRepository = new DoctorRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)

  const addDoctor = new RemoteAddDoctor(doctorRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  return (
    <HospitalAddDoctor addDoctor={addDoctor} loadHospitals={loadHospitals} />
  )
}
