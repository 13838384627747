import React from 'react'
import HospitalHome from 'presentation/hospital/pages/Home'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteCountSurgicalOrdersByStatus } from 'service/usecases/count-surgical-orders-by-status/remote-count-surgical-orders-by-status'
import { RemoteCountScheduledAndUnscheduledSurgicalOrders } from 'service/usecases/count-scheduled-and-unscheduled-surgical-orders/remote-count-scheduled-and-unscheduled-surgical-orders'

export default function makeHospitalSurgicalHome() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const countSurgicalOrders = new RemoteCountSurgicalOrdersByStatus(
    surgicalOrderRepository
  )

  const countScheduledAndUnscheduledSurgicalOrders =
    new RemoteCountScheduledAndUnscheduledSurgicalOrders(
      surgicalOrderRepository
    )

  return (
    <HospitalHome
      countSurgicalOrders={countSurgicalOrders}
      countScheduledAndUnscheduledSurgicalOrders={
        countScheduledAndUnscheduledSurgicalOrders
      }
    />
  )
}
