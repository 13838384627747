import normalizeText from './getNormalizedText'

const getSlugByText = (text: string) => {
  try {
    if (!text || text === '') {
      return ''
    }
    return normalizeText(text).replaceAll(' ', '-').toLowerCase()
  } catch (err) {
    return ''
  }
}

export default getSlugByText
