import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { AddCollaborator } from 'domain/usecases/collaborator/add-collaborator'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import SelectField from 'presentation/shared/components/SelectField'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import { Hospital } from 'domain/entities/hospital-model'
import Modal from 'presentation/shared/components/Modal'
import { toast } from 'react-toastify'
import MultipleSelect from 'presentation/shared/components/MultipleSelect'
import { useLocation, useHistory } from 'react-router-dom'
import { Roles } from 'common/enum/roles'

type AddCollaboratorFormProps = {
  addCollaborator?: AddCollaborator
  hospitals?: Hospital[]
}

type LocationType = {
  isAdmin: boolean
}

export default function AddCollaboratorForm({
  addCollaborator,
  hospitals
}: AddCollaboratorFormProps) {
  const [handleSuccessModal, setHandleSuccessModal] = useState(false)
  const history = useHistory()
  const formik = useFormik({
    initialValues: {} as AddCollaboratorFormFields,
    validationSchema: AddCollaboratorValidation,
    validateOnMount: true,
    onSubmit: async (values) => {
      const requestObject: AddCollaborator.Params = {
        name: values.name.trim(),
        lastName: values.lastName.trim(),
        role: values.role,
        sector: values.sector,
        phoneExtension: Number(values.phoneExtension),
        register: values.register,
        email: values.email,
        hospital_id: values.hospital_id.map((id) => Number(id))
      }
      try {
        await addCollaborator?.add(requestObject)
        setHandleSuccessModal(true)
        formik.resetForm()
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  const { state } = useLocation<LocationType>()

  const profile = state && state.isAdmin ? 'administrador' : 'colaborador'

  const checkProfileRoleValue = () => {
    if (state && state.isAdmin) {
      formik.setFieldValue('role', Roles.ADMIN)
    }
  }

  useEffect(() => {
    checkProfileRoleValue()
  }, [state])


  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <Heading size="huge" color="primary">
        Novo {profile}
      </Heading>
      <SupportText style={{ marginTop: '10px' }} color="gray">
        Envie um novo convite para o {profile}
      </SupportText>
      <S.Content>
        <TextField
          label="Nome"
          name="name"
          bgColor="mainBg"
          value={formik.values.name || ''}
          onInputChange={formik.handleChange('name')}
          onBlur={formik.handleBlur('name')}
          error={formik.touched.name && formik.errors.name}
          required
        />
        <TextField
          label="Sobrenome"
          name="lastName"
          bgColor="mainBg"
          value={formik.values.lastName || ''}
          onInputChange={formik.handleChange('lastName')}
          onBlur={formik.handleBlur('lastName')}
          error={formik.touched.lastName && formik.errors.lastName}
          required
        />
        <TextField
          label="Cargo"
          name="sector"
          bgColor="mainBg"
          value={formik.values.sector || ''}
          onInputChange={formik.handleChange('sector')}
          onBlur={formik.handleBlur('sector')}
          error={formik.touched.sector && formik.errors.sector}
          required
        />
        <TextField
          label="E-mail"
          name="email"
          bgColor="mainBg"
          value={formik.values.email || ''}
          onInputChange={formik.handleChange('email')}
          onBlur={formik.handleBlur('email')}
          error={formik.touched.email && formik.errors.email}
          required
        />
        <TextField
          label="Ramal"
          name="phoneExtension"
          bgColor="mainBg"
          value={formik.values.phoneExtension || ''}
          onInputChange={formik.handleChange('phoneExtension')}
          onBlur={formik.handleBlur('phoneExtension')}
          error={formik.touched.phoneExtension && formik.errors.phoneExtension}
          required
        />
        <TextField
          label="Registro integração (Identificador unico)"
          name="register"
          bgColor="mainBg"
          value={formik.values.register || ''}
          onInputChange={formik.handleChange('register')}
          onBlur={formik.handleBlur('register')}
          error={formik.touched.register && formik.errors.register}
          required
        />
        <SelectField
          disabled={state && state.isAdmin}
          label="Setor"
          name="role"
          bgColor="mainBg"
          items={[
            {
              label: 'Triagem',
              value: Roles.TRIAGE
            },
            {
              label: 'Pré-agendamento',
              value: Roles.PRE_SCHEDULING
            },
            {
              label: 'OPME',
              value: Roles.OPME
            },
            {
              label: 'Central de Guias',
              value: Roles.CRMO
            },
            {
              label: 'Agendamento',
              value: Roles.SCHEDULING
            },
            {
              label: 'Orçamento',
              value: Roles.BUDGET
            },
            {
              label: 'Recepcionista',
              value: Roles.RECEPTIONIST
            }
          ]}
          value={formik.values.role || ''}
          onInputChange={formik.handleChange('role')}
          onBlur={formik.handleBlur('role')}
          error={formik.touched.role && formik.errors.role}
          required
        />
        <MultipleSelect
          label="Unidades"
          name="hospital_id"
          error={formik.touched.hospital_id && formik.errors.hospital_id}
          items={hospitals?.map((hospital) => ({
            label: hospital.nickname!,
            value: hospital.hospital_id!
          }))}
          onBlur={formik.handleBlur('hospital_id')}
          onCheck={formik.handleChange('hospital_id')}
          required
        />
      </S.Content>
      <Button
        disabled={!formik.isValid || formik.isSubmitting}
        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
        type="submit"
      >
        Enviar Convite
      </Button>
      <Modal
        title="Colaborador cadastrado com sucesso!"
        show={handleSuccessModal}
        close={() => history.push('/colaboradores')}
      />
    </S.Wrapper>
  )
}

const AddCollaboratorValidation = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  role: yup.string().required(),
  email: yup.string().email().required(),
  phoneExtension: yup
    .string()
    .matches(/^\d+$/, 'Insira apenas números')
    .required(),
  register: yup.string().required(),
  sector: yup.string().required(),
  hospital_id: yup.array().min(1, 'Campo obrigatório').required()
})

export type AddCollaboratorFormFields = {
  name: string
  lastName: string
  role: Roles
  email: string
  phoneExtension: string
  register: string
  sector: string
  hospital_id: string[]
}
