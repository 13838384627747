import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import HospitalAuthorization from 'presentation/hospital/pages/Authorization'
import { RemoteSearchSurgicalOrders } from 'service/usecases/search-surgical-orders/remote-search-surgical-orders'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteAddSurgicalPendency } from 'service/usecases/add-surgical-pendency/remote-add-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/repositories/surgical-pendency/surgical-pendency-repository'
import { RemoteLoadSurgicalPendencyDocument } from 'service/usecases/load-surgical-pendency-document/remote-load-surgical-pendency-document'
import { RemoteFinishSurgicalPendency } from 'service/usecases/finish-surgical-pendency/remote-finish-surgical-pendency'
import { RemoteReorderSurgicalOrder } from 'service/usecases/reorder-surgery/remote-reorder-surgery'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteLoadHealthInsurancePlans } from 'service/usecases/load-health-insurance-plans/remote-load-health-insurance-plans'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'

export default function makeHospitalAuthorization() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)
  const surgicalPendencyRepository = new SurgicalPendencyRepository(
    apiRepository
  )
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)

  const searchSurgicalOrders = new RemoteSearchSurgicalOrders(
    surgicalOrderRepository
  )
  const addSurgicalPendency = new RemoteAddSurgicalPendency(
    surgicalPendencyRepository
  )
  const loadSurgicalPendencyDocument = new RemoteLoadSurgicalPendencyDocument(
    surgicalPendencyRepository
  )
  const finishSurgicalPendency = new RemoteFinishSurgicalPendency(
    surgicalPendencyRepository
  )
  const reorderSurgicalOrder = new RemoteReorderSurgicalOrder(
    surgicalOrderRepository
  )
  const loadSurgeryCenters = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )
  const loadHealthInsurances = new RemoteLoadHealthInsurancePlans(
    healthInsuranceRepository
  )
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )

  return (
    <HospitalAuthorization
      searchSurgicalOrders={searchSurgicalOrders}
      addSurgicalPendency={addSurgicalPendency}
      loadSurgicalPendencyDocument={loadSurgicalPendencyDocument}
      finishSurgicalPendency={finishSurgicalPendency}
      reorderSurgicalOrder={reorderSurgicalOrder}
      loadSurgeryCenters={loadSurgeryCenters}
      loadHospitals={loadHospitals}
      loadHealthInsurances={loadHealthInsurances}
      loadSurgicalOrder={loadSurgicalOrder}
      loadSurgicalOrderDocument={loadSurgicalOrderDocument}
    />
  )
}
