import React from 'react'
import CanceledOrderLayout from 'presentation/hospital/layouts/CanceledOrderResume'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export default function CanceledOrderInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const patientRepository = new PatientRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )

  return (
    <CanceledOrderLayout
      loadPatientInfo={loadPatientInfo}
      loadHospitals={loadHospitals}
      loadSurgicalOrderDocument={loadSurgicalOrderDocument}
    />
  )
}
