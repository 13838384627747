import { LoadPainAlerts } from 'domain/usecases/surgery/load-pain-alerts'
import { SurgeryRepository } from 'repository/interfaces/surgery-repository'

export class RemoteLoadPainAlerts implements LoadPainAlerts {
  constructor(private readonly surgeryRepository: SurgeryRepository) {}

  load(params?: LoadPainAlerts.Params): Promise<LoadPainAlerts.Model> {
    return this.surgeryRepository.loadPainAlerts(params)
  }
}

export type LoadPainAlertsModel = LoadPainAlerts.Model
