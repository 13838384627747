import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata/Reflect.js'

import HospitalRouter from 'main/routes/hospital-routes'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'presentation/styles/global'
import { ToastContainer } from 'react-toastify'
import theme from 'presentation/styles/theme'
import 'react-toastify/dist/ReactToastify.min.css'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'main/config/react-query'
import moment from 'moment-timezone'
import 'moment-timezone'

// const userTimezone = moment.tz.guess()

// moment.tz.setDefault(userTimezone)

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <HospitalRouter />
        <ToastContainer />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
