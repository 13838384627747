import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteAddDoctor implements AddDoctor {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(params: AddDoctor.Params): Promise<AddDoctor.Model> {
    return this.doctorRepository.addDoctor(params)
  }
}

export type AddDoctorModel = AddDoctor.Model
