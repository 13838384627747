import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 90vh;
  max-height: 90vh;
  width: 720px;
  max-width: 80vw;
  background-color: ${({ theme }) => theme.colors.mainBg};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
  }
`

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`

export const PendencyOverview = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;

  div {
    display: flex;
    align-items: flex-end;
    h6,
    span {
      display: inline;
      font-size: 12px;
      font-weight: 400;
    }

    h6 {
      margin-right: 6px;
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    }
  }

  & + & {
    margin-top: 4px;
  }
`

export const TextAreaWrapper = styled.div`
  & > div {
    margin-top: 20px;
  }
`

export const PendencyStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-weight: 600;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 70%;
  margin-top: 10px;
  margin-bottom: 20px;
`
