import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import Container from 'presentation/hospital/components/Container'
import ReviewCanceledOrder from 'presentation/hospital/components/Forms/CanceledOrderForm'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'

import * as S from './styles'

type CanceledOrderLocation = {
  order: SurgicalOrderList
}

type Props = {
  loadPatientInfo: LoadPatientInfo
  loadHospitals?: LoadHospitals
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
} & WithLoadingProps

const CanceledOrderLayout = WithLoading(
  ({
    loadPatientInfo,
    loadHospitals,
    loadSurgicalOrderDocument,
    setIsLoading
  }: Props) => {
    const [patientInfo, setPatientInfo] = useState({} as LoadPatientInfo.Model)
    const [hospitals, setHospitals] = useState<Hospital[]>([])
    const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>()
    const location = useLocation<CanceledOrderLocation>()

    const service = useServices().surgicalOrder

    async function loadData() {
      try {
        setIsLoading(true)
        await loadPatient()
        await loadHospitalsData()
        await loadSurgicalOrder()
      } catch (error: any) {
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    async function loadPatient() {
      const patient = await loadPatientInfo.load(
        ['user_id', 'name', 'birthday', 'phone', 'cpf', 'email', 'gender'],
        location.state?.order?.patient_id
      )
      setPatientInfo(patient)
    }

    async function loadHospitalsData() {
      const hospitals =
        (await loadHospitals?.load(['hospital_id', 'name', 'nickname'])) ?? []
      setHospitals(hospitals)
    }

    async function loadSurgicalOrder() {
      const surgicalOrder = await service.loadSurgicalOrder(
        Number(location.state?.order.surgery_request_id),
        [
          'surgical_order_id',
          'updatedAt',
          'justificationToDelete',
          'patient{gender',
          'name',
          'cpf',
          'phone',
          'landlinePhone',
          'email',
          'birthday',
          'patient_id',
          'company',
          'maritalStatus}',
          'documents {document_id, type, name}',
          'cancelledInfo {cancelledCollaboratorRegister, justification, cancelledAt, cancelledBy {name, cpf}}',
          'healthInsurance {healthCard, healthInsuranceName, healthPlanName, company, validThru}'
        ]
      )
      setSurgicalOrder(surgicalOrder)
    }

    useEffect(() => {
      loadData()
    }, [])

    return (
      <Container>
        <S.TableTitle>Pedido Cancelado</S.TableTitle>
        <S.Wrapper>
          <ReviewCanceledOrder
            onlyVisualization
            surgicalOrder={surgicalOrder}
            loadPatientInfo={patientInfo}
            hospitals={hospitals}
            loadSurgicalOrderDocument={loadSurgicalOrderDocument}
          />
        </S.Wrapper>
      </Container>
    )
  }
)

export default CanceledOrderLayout
