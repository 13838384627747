import React, { useState } from 'react'
import Modal from 'presentation/hospital/components/Modal'
import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/download.svg'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import { toast } from 'react-toastify'

export type Props = {
  pendency: SurgicalPendency
  surgicalOrder: SurgicalOrderList
  close: () => void
  downloadDocument?: LoadSurgicalPendencyDocument
  saveDocuments: (documents: number[]) => void
  defaultDocuments: number[]
}

export const PendencyDocumentsModal = ({
  pendency,
  close,
  downloadDocument,
  saveDocuments,
  defaultDocuments
}: Props) => {
  const [validatedDocuments, setValidatedDocuments] = useState<number[]>(
    defaultDocuments.length ? defaultDocuments : []
  )
  const [documentsLoading, setDocumentsLoading] = useState<
    Record<number, boolean>
  >({})

  const toggleDocument = (id: number) => {
    if (validatedDocuments.includes(id)) {
      setValidatedDocuments(validatedDocuments.filter((doc) => doc !== id))
    } else {
      setValidatedDocuments([...validatedDocuments, id])
    }
  }

  const downloadFile = async (id: number, name: string, group_id?: string) => {
    try {
      setDocumentsLoading((state) => ({ ...state, [id]: true }))
      const content = await downloadDocument?.load(
        pendency.surgical_pendency_id,
        id,
        group_id
      )
      if (content) {
        downloadFileFromBlob(content.data, content.contentType, name)
      }
    } catch (error) {
      toast.error('Erro ao baixar arquivo')
    } finally {
      setDocumentsLoading((state) => ({ ...state, [id]: false }))
    }
  }

  return (
    <Modal noBackground close={close}>
      <S.Wrapper>
        <Heading as="h1" color="primary">
          Documentos, exames, laudos e relatórios
        </Heading>
        <S.Content>
          <S.DocumentList>
            {pendency.documents?.map((document) => (
              <li key={document.name}>
                <p>{document.name}</p>
                <div>
                  {documentsLoading?.[document.document_id] ? (
                    <S.Loading>
                      <img src={LoadingGif} />
                    </S.Loading>
                  ) : (
                    <DownloadIcon
                      height="30"
                      width="30"
                      onClick={() =>
                        downloadFile(
                          document.document_id,
                          document.name,
                          document.group_id
                        )
                      }
                    />
                  )}
                  <Button
                    variant="white"
                    onClick={() => toggleDocument(document.document_id)}
                    outlined
                  >
                    {validatedDocuments.includes(document.document_id)
                      ? 'Validado'
                      : 'Validar'}
                  </Button>
                </div>
              </li>
            ))}
          </S.DocumentList>
        </S.Content>
        <S.Buttons>
          <Button variant="outlined" type="button" onClick={close}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={() => {
              saveDocuments(validatedDocuments)
              close()
            }}
          >
            Salvar
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Modal>
  )
}
