import React from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { RemoteAddCollaborator } from 'service/usecases/add-collaborator/remote-add-collaborator'
import AddCollaborator from 'presentation/hospital/pages/AddCollaborator'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'

export default function makeHospitalAddCollaborator() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const collaboratorRepository = new CollaboratorRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)

  const addCollaborator = new RemoteAddCollaborator(collaboratorRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)

  return (
    <AddCollaborator
      loadHospitals={loadHospitals}
      addCollaborator={addCollaborator}
    />
  )
}
