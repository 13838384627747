import React, { useState } from 'react'
import * as S from './styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import SelectField from 'presentation/shared/components/SelectField'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import Modal from 'presentation/shared/components/Modal'
import { toast } from 'react-toastify'
import { Gender } from 'common/enum/gender'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import {
  cpfMask,
  dateMask,
  landlinePhoneMask,
  phoneMask
} from 'presentation/utils/masks'
import { useServices } from 'presentation/hooks/use-services'
import moment from 'moment-timezone'
import { User } from 'domain/entities/user-model'
import { useHistory } from 'react-router-dom'
import { UserStatusEnum } from 'common/enum/user-status'

export default function AddPatientForm() {
  const [handleSuccessModal, setHandleSuccessModal] = useState(false)
  const history = useHistory()
  const patientService = useServices().patient

  const formik = useFormik({
    initialValues: {
      phone: '+55'
    } as AddPatientFormValues,
    validationSchema: AddPatientValidation,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await patientService.invitePatient({
          maritalStatus: values.maritalStatus,
          gender: values.gender,
          birthday: moment(values.birthDate, 'DD/MM/YYYY').toISOString(),
          user: {
            name: values.name,
            cpf: values.cpf.replace(/\D+/g, ''),
            email: values.email,
            status: UserStatusEnum.PENDING,
            phone: values.phone
          } as User
        })
        setHandleSuccessModal(true)
        formik.resetForm()
      } catch (error) {
        toast.error(error.message)
      }
    }
  })

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <Heading size="huge" color="primary">
        Novo Paciente
      </Heading>
      <SupportText style={{ marginTop: '10px' }} color="gray">
        Envie um novo convite para o paciente
      </SupportText>
      <S.Content>
        <TextField
          label="Nome"
          name="name"
          bgColor="mainBg"
          required
          value={formik.values.name || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors.name}
        />
        <TextField
          label="CPF"
          name="cpf"
          bgColor="mainBg"
          required
          value={formik.values.cpf || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.cpf && formik.errors.cpf}
          mask={cpfMask}
        />
        <TextField
          label="Data nascimento"
          name="birthDate"
          bgColor="mainBg"
          required
          value={formik.values.birthDate || ''}
          mask={dateMask}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.birthDate &&
            formik.errors.birthDate &&
            'Data de nascimento inválida'
          }
        />
        <TextField
          label="E-mail"
          name="email"
          bgColor="mainBg"
          required
          value={formik.values.email || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />
        <SelectField
          label="Sexo"
          name="gender"
          bgColor="mainBg"
          required
          items={[
            {
              label: 'Masculino',
              value: 'Masculino'
            },
            {
              label: 'Feminino',
              value: 'Feminino'
            }
          ]}
          value={formik.values.gender || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && formik.errors.gender}
        />
        <SelectField
          label="Estado civil"
          name="maritalStatus"
          bgColor="mainBg"
          items={maritalStatus}
          required
          value={formik.values.maritalStatus || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.maritalStatus && formik.errors.maritalStatus}
        />
        <TextField
          label="Celular"
          name="phone"
          bgColor="mainBg"
          required
          mask={phoneMask}
          value={formik.values.phone || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          label="Telefone"
          name="landlinePhone"
          bgColor="mainBg"
          mask={landlinePhoneMask}
          value={formik.values.landlinePhone || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.landlinePhone && formik.errors.landlinePhone}
        />
      </S.Content>
      <Button
        disabled={!formik.isValid || formik.isSubmitting}
        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
        type="submit"
      >
        Enviar Convite
      </Button>
      <Modal
        title="Paciente cadastrado com sucesso!"
        show={handleSuccessModal}
        close={() => history.push('/pacientes')}
      />
    </S.Wrapper>
  )
}

const AddPatientValidation = yup.object().shape({
  name: yup.string().required(),
  cpf: yup.string().cpf().required(),
  email: yup.string().email().required(),
  gender: yup.string().required(),
  maritalStatus: yup.string().required(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required(),
  landlinePhone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{8}$/, 'Telefone inválido')
    .optional(),
  birthDate: yup
    .string()
    .test((val) => {
      const date = moment(val, 'DD/MM/YYYY')
      return date.isBefore(moment())
    })
    .required()
})

export type AddPatientFormValues = {
  name: string
  cpf: string
  birthDate: string
  email: string
  gender: Gender
  maritalStatus: string
  phone: string
  landlinePhone: string
}
